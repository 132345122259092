import React, { useState, useEffect } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import CircleIcon from '@mui/icons-material/Circle';
import axios from 'axios';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import FeedbackSubmission from '../ChartsFeedAndSurvey/FeedbackSubmission';
import FeedbackResponse from '../ChartsFeedAndSurvey/FeedbackResponse';
import NewPlatUsers from '../ChartsFeedAndSurvey/NewPlatUsers';
import { AddIcon, EditIcon } from '../../../SvgIconsData';
import ProPopup from "../../../layouts/ProPopup";
import CreateFeedback from './CreateFeedback';

const Analytics = ({ analyticsData }) => {
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const [show, setShow] = useState(false);
  const [openPro, setOpenPro] = useState(false);
  const [userPlan, setUserPlan] = useState(null);
  const [feedBackList, setFeedBacks] = useState([]);
  const [invitesList, setInvitesList] = useState([]);
  const [skeletonLoading, setSkeletonLoading] = useState(true);
  const [latestFeedbacks, setLatestFeedbacks] = useState([]);
  const skeletonNumbers = [1, 2, 3, 4];
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  useEffect(()=>{
    if(userData.plans.data) setUserPlan(userData.plans.data);
  },[userData]);

  const feedbackByChannel = async (req, res) => {
    try {
      const { data } = await axios.get(`${configData.config.UNO_URL}/api/feedback/getFeedbackByChannel`, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      });
      setSkeletonLoading(false);
      setFeedBacks(data.data || []);
      setInvitesList(data.data.invites || []);
    } catch (error) {
      setSkeletonLoading(true);
    }
  };

  const LatestFeedbackByChannel = async () => {
    try {
      const { data } = await axios.get(`${configData.config.UNO_URL}/api/feedback/getLatestFeedbackBychannel`, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      });
      setLatestFeedbacks(data.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    feedbackByChannel();
    LatestFeedbackByChannel();
  }, []);

  const handleClickEditFeedback = (uuid) =>{
    if(!userPlan?.feedbackAndSurvey?.manage){
      setOpenPro(true);
      return
    }
    navigate(`/dashboard/feedback/create/${uuid}`, {
      state: { key: 'analytics' },
    })
  }
  const handleClickViewFeedback = (uuid) =>{
    if(!userPlan?.feedbackAndSurvey?.viewResponse){
      setOpenPro(true);
      return
    }
    navigate(`/dashboard/feedback/feedback/detail/${uuid}`, {
      state: { key: 'analytics' },
    })
  }



  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <div className="card feedbackcard">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-lg-3 col-md-6 ">
                  <div className="d-flex gap-3 align-items-center border-end ">
                    { skeletonLoading ? 
                    <>
                    <p className="m-0 w-100">
                     <Skeleton width="100%" highlightColor='#ffffff' baseColor='#f3f3f3'/>
                    </p>
                    <h5 className="m-0 w-100">
                    <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/>
                    </h5>
                    </>
                    :
                    <>
                    <p className="m-0 font-xs">Total Feedback </p>
                    <h5 className="m-0">
                      <strong>{analyticsData?.totalFeedbacksCount?.totalFeedbacksCount || ''}</strong>{' '}
                    </h5>
                    </>
                    }
                    
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 ">
                  <div className="d-flex gap-3 align-items-center border-end ">
                  { skeletonLoading ? 
                    <>
                    <p className="m-0 w-100">
                     <Skeleton width="100%" highlightColor='#ffffff' baseColor='#f3f3f3'/>
                    </p>
                    <h5 className="m-0 w-100">
                    <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/>
                    </h5>
                    </>
                    :
                    <>
                    <p className="m-0 font-xs">Total Survey </p>
                    <h5 className="m-0">
                      <strong>{analyticsData?.totalFeedbacksCount?.totalSurveysCount || ''}</strong>
                    </h5>
                    </>
                    }
                  
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 ">
                  <div className="d-flex gap-3 align-items-center border-end">
                  { skeletonLoading ? 
                    <>
                    <p className="m-0 w-100">
                     <Skeleton width="100%" highlightColor='#ffffff' baseColor='#f3f3f3'/>
                    </p>
                    <h5 className="m-0 w-100">
                    <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/>
                    </h5>
                    </>
                    :
                    <>
                    <p className="m-0 font-xs">Total FeedBack Responses</p>
                    <h5 className="m-0">
                      <strong>{analyticsData?.totalResponses?.feedbackResponses || ''}</strong>
                    </h5>
                    </>
                    }
                
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="d-flex gap-3 align-items-center ">
                  { skeletonLoading ? 
                    <>
                    <p className="m-0 w-100">
                     <Skeleton width="100%" highlightColor='#ffffff' baseColor='#f3f3f3'/>
                    </p>
                    <h5 className="m-0 w-100">
                    <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/>
                    </h5>
                    </>
                    :
                    <>
                    <p className="m-0 font-xs"> Total Survey Responses</p>
                    <h5 className="m-0">
                      <strong>{analyticsData?.totalResponses?.Surveyresponses || '0'}</strong>
                    </h5>
                    </>
                    }
                
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-6 mt-3">
              <div className="card custom-chart">
                <div className="card-body pb-2">
                  <FeedbackSubmission analyticsData={analyticsData} />
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="card mb-3 mb-md-0 custom-chart">
                <div className="card-body pb-2">
                  <FeedbackResponse analyticsData={analyticsData} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card h-100 custom-chart">
            <div className="card-body">
              <h6>Last event </h6>
              <NewPlatUsers latestFeedbacks={latestFeedbacks} />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4 recentfeedback">
        <div className="col-md-12">
          <div className="graphbox overflow-hidden ">
            <div className="row justify-content-between">
              <div className="col-md-6 d-flex align-items-center justify-content-start">
                <h6 className='m-0'>Recent feedback & survey </h6>
              </div>

              { userData?.user?.permissions?.includes("GENERAL.FEEDBACK.CREATE") && 
                 <div className="col-md-6 text-end">
                 <button type='button' className="createbtn my-2" onClick={handleShow}>
                          Create Feedback & Survey
                  </button>
               </div>
              }      

            </div>
            <div className="row mt-2">
              {skeletonLoading ? (
                <>
                  {skeletonNumbers.map((index) => (
                    <div className="col-md-3 pb-2">
                      <div className="bg-light p-3 border rounded-2">
                        <h6 className="m-0">
                          <Skeleton />
                        </h6>
                        <p>
                          <Skeleton width={100} />
                        </p>
                        <div className="invites mt-4">
                          <div className="peeps" aria-label="Organised By : avish pratap">
                            <Skeleton circle width={25} height={25} />
                            <Skeleton circle width={25} height={25} />
                          </div>
                          <div className="d-flex gap-2">
                            <Skeleton width={100} height={25} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                feedBackList
                  .sort()
                  .slice(0, 4)
                  .map((feedback, index) => (
                    <div className="col-md-3 pb-2">
                      <div className="bg-light p-3 border rounded-2">
                        <h6 className="m-0 text-capitalize d-flex align-items-center justify-content-between typesder">
                          <strong>{feedback.name}</strong>
                          {feedback.publishTime === null ? (
                            <OverlayTrigger placement="top" overlay={<Tooltip>Draft</Tooltip>}>
                              <p>
                                <span style={{ color: '#ff0000' }}>
                                  <strong>
                                    <CircleIcon className="font-md" />
                                  </strong>
                                </span>
                              </p>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  {feedback.status.charAt(0).toUpperCase() + feedback.status.slice(1).toLowerCase()}{' '}
                                </Tooltip>
                              }
                            >
                              <p>
                                <span className={`text-${feedback.status === 'LIVE' ? 'success' : 'danger'}`}>
                                  <CircleIcon className="font-md" />
                                </span>
                              </p>
                            </OverlayTrigger>
                          )}
                        </h6>

                        <p className={`text-${feedback.type === 'FEEDBACK' ? 'success' : 'danger'}`}>
                          {feedback.type.charAt(0).toUpperCase() + feedback.type.slice(1).toLowerCase()}
                        </p>

                        <div className="invites mt-4">
                          <div className="peeps" aria-label="Organised By : avish pratap">
                            {feedback.invites
                              .slice(0, 2)
                              .map(
                                (invite, index) =>
                                  invite.avatar && (
                                    <img key={index} className="peepimg" src={invite.avatar} alt={`Avatar ${index}`} />
                                  )
                              )}
                            {feedback.invites.length > 2 && <>+{feedback.invites.length - 2} more</>}
                          </div>
                          <div className="d-flex gap-2">
                            
                            {userData?.user?.permissions?.includes("GENERAL.FEEDBACK.MANAGE") && 
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  Edit{' '}
                                  <span className="text-capitalize">
                                    {feedback.type.charAt(0).toUpperCase() + feedback.type.slice(1).toLowerCase()}
                                  </span>
                                </Tooltip>
                              }
                            >
                              <span
                                onClick={() =>handleClickEditFeedback(feedback.uuid) }
                                className="cursor-pointer actionbtnf"
                              >
                                <EditIcon />
                              </span>
                            </OverlayTrigger>
                            }

                            {feedback?.responses?.length > 0 ? (
                              <OverlayTrigger placement="top" overlay={<Tooltip>View Detail</Tooltip>}>
                                <div
                                  className="cursor-pointer"
                                  onClick={() => handleClickViewFeedback(feedback.uuid) }
                                >
                                  <VisibilityOutlinedIcon />
                                </div>
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger placement="top" overlay={<Tooltip>No Detail</Tooltip>}>
                                <div className="cursor-pointer">
                                  <VisibilityOutlinedIcon />
                                </div>
                              </OverlayTrigger>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>
      </div>

      <CreateFeedback show={show} handleClose={handleClose} />
      <ProPopup openPro={openPro} setOpenPro={setOpenPro} />  
    </>
  );
};

export default Analytics;

import * as Yup from 'yup';


export const loginValidationSchema = Yup.object({
    password: Yup.string().trim().min(8, 'Password must be at least 8 characters').required('Password is required').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      ),
    confirmPassword: Yup.string().trim().oneOf([Yup.ref('password'), null], 'Passwords must match').min(8, 'Password must be at least 8 characters').required('Confirm Password is required').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      )
});

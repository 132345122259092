import React, { useEffect, useState } from 'react';
import { Navigate, useRoutes, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import AlertBox from '../AlertBox';
import logo from '../../assets/img/logo.png';
import NoDataFound from '../NoDataFound';

const AssignedList = () => {

  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  const [myapps, setMyapps] = useState([]);

  const [selectedResources, setSelectedResources] = useState([]);
  

  const configData = useSelector((state) => state.configSlice);

  const userData = useSelector((state) => state.userSlice);

  const myAppData = useSelector((state) => state.myAppSlice);


  

  const applicationsUuids = myapps?.map((obj) => obj.uuid);

  const resourcesUuids = selectedResources?.map((obj) => obj.uuid);

  const { type, uuid } = useParams();

  const showVisible = () => {
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
      navigate(type === "group"? `/dashboard/team-management/${uuid}` : `/dashboard/team-management/user/${uuid}` );
    }, 1000);
  };

  const handleAssign = async () => {
    try {
      const payload = {
        applications: applicationsUuids,
        resources: resourcesUuids,
      };

      const response = await axios.post(
        `${configData.config.UNO_URL}/api/allocation/create/${type}/${uuid}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );

      if (response.status === 200) {
        const responseData = response.data;
        showVisible();
      } else {
        console.error('API request failed');
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const location = useLocation();

  const typeIdMap = new Map();

  selectedResources.forEach((item) => {
    if (!typeIdMap.has(item.typeId)) {
      typeIdMap.set(item.typeId, [item]);
    } else {
      typeIdMap.get(item.typeId).push(item);
    }
  });

  const combinedArray =myAppData?.appsList.map((item1) => {
    const typeId = item1.resourceTypeId;
    const typeItems = typeIdMap.get(typeId) || [];
    return { ...item1, typeItems };
  });

  const filteredData = combinedArray?.filter((item) => item.resourceTypeId);
  

  useEffect(() => {
    if (location.state) {
      setSelectedResources(location.state?.selectedResources);
      setMyapps(location.state?.selectApp);
    }
  }, [myAppData]);

  return (
    <>
      <div className="pagetitletext">
        <h6 className="mb-0 mt-2"><button className="back" onClick={() => navigate(-1)}> {' '} <KeyboardBackspaceOutlinedIcon />{' '} </button>{' '} Simulanis Solutions</h6>
        {/* <p className="mb-0 mt-2 text-muted">
          {
            <span>
              {' '}
              <button className="back" onClick={() => navigate(-1)}>
                {' '}
                <KeyboardBackspaceOutlinedIcon />{' '}
              </button>{' '}
            </span>
          }
        </p> */}
      </div>
      
      {myapps.length === 0  && selectedResources.length === 0 ? <NoDataFound heading="No Applications and Resources Selected"/>:<></> }
      
      { myapps.length > 0 ? (
        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="col-md-3">
              <div className="validhead">
                <p className="pagetitletext">Applications</p>
                <div className="line">{''}</div>
              </div>
            </div>
            <div className="row validity">
              {myapps?.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div className="Appbox mt-3">
                    <div className="appthumb">
                      <img
                        src={item.icon || ''}
                        alt=""
                        onError={(e) => {
                          e.target.src = logo;
                          e.target.onerror = null;
                        }}
                      />
                    </div>
                    <div className="appdata">
                      <div className="apptitle">
                        <p>{item.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {selectedResources.length > 0 && filteredData.length > 0 ? (
        filteredData.map((item, index) => (
          <div className="row" key={index}>
            <div className="col-md-12 mt-4">
              <div className="col-md-3">
                <div className="validhead">
                  <p className="pagetitletext">{item?.resourceType[0]?.name}</p>
                  <div className="line">{''}</div>
                </div>
              </div>
              <div className="row validity">
                {item?.typeItems.map((e, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="Appbox mt-3">
                      <div className="appthumb">
                        <img
                          src={e?.versions[0]?.data?.thumbnail || ''}
                          alt=""
                          onError={(e) => {
                            e.target.src = logo;
                            e.target.onerror = null;
                          }}
                        />
                      </div>
                      <div className="appdata">
                        <div className="apptitle">
                          <p>{e?.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))
      ) : <></>}
      <div className="row">
        <div className="col">
          <div className="col-12 mt-5 text-end">
            <button className="createbtn cancelbtn me-2" onClick={() => navigate(-1)}>
              Cancel
            </button>
            <button className="createbtn" onClick={handleAssign}>
              Assign
            </button>
          </div>
        </div>
      </div>
      <AlertBox mainmsg={'Assigned Successfully!'} show={visible} onHide={() => setVisible(false)} />
    </>
  );
};

export default AssignedList;

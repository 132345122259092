import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Stack, TextField } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import axios from 'axios';
import { loginValidationSchema } from './validation';

const ForgotForm = ({ setConfirm }) => {
  const configData = useSelector((state) => state.configSlice);
  const recaptchaRef = useRef(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      if (!isOnline) {
        setError('Please check your connection and try again.');
        return
      }
      
      // if(!verified){
      //   setError('Please check the reCAPTCHA.');
      //   return
      // }

        setError(null);
        const data = {
          email: values.email.trim(),
        };
        setLoading(true);
        try {
          const response = await axios.post( `${configData.config.UNO_URL}/api/auth/forgotPassword/${configData.config.applicationId}`, data );
          setLoading(false);
          setError(null);

          if (response.status === 200) {
            setConfirm(true);
          } else if (response.status === 422) {
            setError(response?.data?.message);
          } else {
            setError(response?.data?.message);
          }
        } catch (error) {
          setError(error?.response?.data?.message);
          setLoading(false);
        } 
        // finally{
        //   setVerified(false);
        //   recaptchaRef.current.reset();
        // }

    },
  });

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        formik.handleSubmit();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // const handleRecaptchaClick = () => {
  //   setVerified(true);
  // };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <div className="form-group">
            <p className="mb-1">Email*</p>
            <TextField
              name="email"
              fullWidth
              placeholder="Enter Email address"
              size="sm"
              defaultValue={formik.values.email}
              onChange={(e) => {
                formik.setFieldValue('email', e.target.value);
                setError(null);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              autoComplete="off"
            />
          </div>
        </Stack>

        {loading ? (
        <>
          <button className="reset-page-btn mt-2">
            <div className="loader-btn">
              <span className="bar" />
              <span className="bar" />
              <span className="bar" />
            </div>
          </button>
        </>
         ) : ( 
        <button className="reset-page-btn mt-2">
          <>Send password reset email</>
        </button>
         )}

      {/* <div className='mt-3' >
        <ReCAPTCHA ref={recaptchaRef} sitekey={configData.config.captcha} onChange={handleRecaptchaClick} />
      </div> */}

        <span className="login-error">{!(formik.touched.email && formik.errors.email) ? error : ''}</span>
        <p className="mt-3">
          Wait, I remember it now! <Link to="/login">Sign In</Link>{' '}
        </p>
      </form>
    </>
  );
};

export default ForgotForm;

import React, { useState, useCallback } from 'react';
import Select from 'react-select';
import AddIcon from '@mui/icons-material/Add';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useDropzone } from 'react-dropzone';
import BackBtn from '../BackBtn';
import MultipleChoice from './CreateQuestions/MultipleChoice';
import YesNoQue from './CreateQuestions/YesNoQue';
import TrueFalseQue from './CreateQuestions/TrueFalseQue';
import EmotionsQue from './CreateQuestions/EmotionsQue';
import SelectDateTime from './SelectDateTime';

const EditCreateQuestions = () => {
  const [show, setShow] = useState(false);
  const QueOptions = [
    { value: 'mc', label: 'Multiple Choice' },
    { value: 'yesno', label: 'Yes/No' },
    { value: 'truefalse', label: 'True/False' },
    { value: 'emotions', label: 'Emotions' },
  ];
  const [selectedOption, setSelectedOption] = useState(QueOptions[0]);
  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
    }),
  };

  const openDateTime = () => {
    setShow(true);
  };
  const hideDateTime = () => {
    setShow(false);
  };

  const onDrop = useCallback((acceptedFiles) => {

  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div className="bg-grey-main createstions">
        <div className="row align-items-center">
          <div className="col-6 col-md-6">
            <BackBtn text="Back" />
          </div>
          <div className="col-6 col-md-6">
            <div className="text-end">
              <button className="btn btn-sm btn-danger">Preview</button>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="beedbackbanner" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <>
                      <div className="text-danger">
                        <FileUploadOutlinedIcon />
                        <h6 className="m-0">Upload banner</h6>
                      </div>
                      <p>Drop the files here ...</p>
                    </>
                  ) : (
                    <>
                      <div className="text-danger">
                        <FileUploadOutlinedIcon />
                        <h6 className="m-0">Upload banner</h6>
                      </div>
                      <p>Recommended size 1534*355</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h5>Title</h5>
                <div className="bg-light p-4">
                  <div className="row align-items-center">
                    <div className="col-1">
                      <span>
                        <strong>Q1</strong>
                      </span>
                    </div>
                    <div className="col-md-11">
                      <div className="d-flex">
                        <input type="text" className="form-control creatq" placeholder="Enter your questions" />
                        <Select
                          className="form-control coptff"
                          styles={style}
                          placeholder="Select type of questions"
                          options={QueOptions}
                          value={selectedOption}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    {selectedOption?.value === 'mc' && <MultipleChoice />}
                    {selectedOption?.value === 'yesno' && <YesNoQue />}
                    {selectedOption?.value === 'truefalse' && <TrueFalseQue />}
                    {selectedOption?.value === 'emotions' && <EmotionsQue />}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <button className="btn btn-sm btn-danger">
                      <AddIcon /> Next Questions
                    </button>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex gap-3 justify-content-end">
                      <button className="btn btn-sm btn-light">Cancel</button>
                      <button className="btn btn-sm btn-danger">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="d-flex justify-content-center">
              <button onClick={openDateTime} className="btn btn-danger p-2 px-4">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <SelectDateTime show={show} handleClose={hideDateTime} />
    </>
  );
};

export default EditCreateQuestions;

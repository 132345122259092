import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button, Stack } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import Iconify from '../../../components/iconify';

const Google = ({showError, handleSuccess, loading, setLoading }) => {
    const configData = useSelector((state) => state.configSlice);

    const getUserCredential = async (accessToken) => {
        try {
          setLoading(true);
          const bodyData = {
            access_token: accessToken,
          };
          const {data} = await axios.post(`${configData.config.UNO_URL}/api/auth/login/google/${configData.config.applicationId}`, bodyData )
          const userData = {
            token: data.data.token,
            user: data.data.user,
          };
          handleSuccess(userData);
             
        } catch (error) {
          console.log(error);
          showError(error.response.data.message);
        } finally{
          setLoading(false)
        }
      };


    const SignWithGoogle = useGoogleLogin({
        onSuccess: (tokenResponse) => {
          getUserCredential(tokenResponse?.access_token);
        },
        onError: (err) => {
          console.log(err);
          showError(err.message)
        },
      });

  return (
    <Stack direction="row" spacing={2}>

            { loading ?
              <Button className='googlebtn' fullWidth size="large" color="inherit" variant="outlined">            
              <div className="wrapper">
                <div className="blue ball"/>
                <div className="red ball"/>
                <div className="yellow ball"/>
                <div className="green ball"/>
              </div>
              </Button>
              :
              <Button className='googlebtn' fullWidth size="large" color="inherit" variant="outlined" onClick={SignWithGoogle}>
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Sign In With Google
                </Typography>
              </Button>
              }

            </Stack>
  )
}

export default Google;

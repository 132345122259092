import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { SetNewPasswordForm } from '../sections/auth/setnewpassword';
import slogo from '../assets/img/logo.png';
import forgotimg from '../assets/img/forgotimg.png';

const SetNewPassword = () => {
  const configData = useSelector((state) => state.configSlice);
  const  location  = useLocation();
  const name = new URLSearchParams(location.search).get('name');


  return (
    <>
      <Helmet>
        <title> Set Password | Simulanis Apps </title>
      </Helmet>
      <div className="loginPage">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 ">
              <div className="card" style={{ position: 'relative' }}>
                <div className="card-body">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                      <div className=" d-flex">
                        <img src={forgotimg} alt="" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="p-4">
                        <img className="slogo" src={slogo} alt="" />
                        <h5 className="mt-2">Set password for {name}  </h5>
                        {configData.config.emailLogin && <SetNewPasswordForm />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetNewPassword;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import Filter from '../../filter/Filter';
import SearchAll from '../../SearchAll';
import ResourceList from './ResourceList';
import FilteredList from '../FilteredList';
import AppSearch from './AppSearch';
import Applications from './Applications';
import { filterResourcesByTags } from '../../../utils/constant';

const TeamSubscriptionList = () => {
  const { uuid, type } = useParams();
  const navigate = useNavigate();
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const myAppData = useSelector((state) => state.myAppSlice);

  const [selectApp, setSelectApp] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const [applications, setApplications] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [resourceList, setResourceList] = useState([]);
  const [activeApp, setActiveApp] = useState('Applications');
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [search, setSearch] = useState('');

  const resource = async (id) => {
    if (!id) return;
    try {
      const getResourceList = await axios.get(`${configData.config.UNO_URL}/api/resource/list/${id}`, {
        headers: { Authorization: `Bearer ${userData.token}` },
      });
      setResourceList(getResourceList.data.data);
    } catch (error) {
      console.error(error);
    }
  };


  const toggleApplication = (item) => {
    setSelectApp(prevState => prevState.includes(item) ? prevState.filter((e) => e.id !== item.id) : [...prevState, item]) 
  };

  const handleSelectResource = (item) => {
    setSelectedResources(preItem => preItem.includes(item) ? preItem.filter((e) => e.id !== item.id) : [...preItem, item] );
  };

  const handleClick = (e) => {
    navigate(`/dashboard/team-management/confirm/resources/${type}/${uuid}`, { state: e });
  };

  useEffect(() => {
    setApplications(myAppData?.appsList || []);
  }, [myAppData]);

  const getAllTags = async (filterId) => {
    try {
      setFilterList([]);
        const {data} = await axios.get(`${configData.config.UNO_URL}/api/tag/list/${filterId}`,  {
          headers: { Authorization: `Bearer ${userData.token}` },
        });  
      setFilterList(data?.data?.children || []);
    } catch (error) {

      console.error(error);
    }
  };
  

  const handleChangeApplication = (id, type) =>{
      setSelectedCheckboxes([]);
      setResourceList([]);
      resource(id);
      switch (type) {
        case "MODULE":
          getAllTags(configData.config.moduleFilterId);
          break;
        case "ENVIRONMENT":
          getAllTags(configData.config.environmentFilterId);
          break;
        case "2D":
          getAllTags(configData.config.skillStream2DFilterId);
          break;
        default:
          console.error("Invalid type: ", type);
      }
  } 

      
  let filteredList = filterResourcesByTags(resourceList, selectedCheckboxes);
        filteredList = filteredList?.filter((e) => {
          if (search && !e.name.toLowerCase().includes(search.toLowerCase())) return false;
          return true;
        });

  const handleSelectAllResource = (status) =>{
    if(status){
      setSelectedResources((prevItems) => {
        return [...prevItems.filter((item) => !filteredList.some((i) => i.uuid === item.uuid))];
      });
      return;
    }
    setSelectedResources((prevItems) => {
      return [...prevItems, ...filteredList.filter((item) => !prevItems.some((i) => i.uuid === item.uuid  ))];
    });
  }
  
  return (
    <>
      <div className="teamsubscription">
        <div className="row my-2">
          <div className="col pagetitletext">
            <span onClick={() => navigate(-1)}>
              <button className="back"> <KeyboardBackspaceOutlinedIcon /> </button>
            </span>
            <span>Team Management</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <AppSearch
              activeApp={activeApp}
              setActiveApp={setActiveApp}
              applications={applications}
              handleChangeApplication={handleChangeApplication}
              toggle
            />
          </div>

          <div className="col-md-9">
            <div className="col-md-12">
              <div className="d-flex justify-content-end">
                <SearchAll search={search} setSearch={setSearch} placeholder={'Search...'} />

                <div className="ms-2">
                  {activeApp !== 'Applications' ? (
                    <Filter
                      selectedCheckboxes={selectedCheckboxes}
                      setSelectedCheckboxes={setSelectedCheckboxes}
                      filterOptions={filterList}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="row mt-3">
                {activeApp === 'Applications' ? (
                  <Applications
                    applications={applications}
                    toggleApplication={toggleApplication}
                    selectApp={selectApp}
                    search={search}
                  />
                ) : (
                  <ResourceList
                    activeApp={activeApp}
                    handleSelectResource={handleSelectResource}
                    selectedResources={selectedResources}
                    search={search}
                    list={filteredList}
                    handleSelectAllResource={(value)=>handleSelectAllResource(value)}
                  />
                )}
              </div>
            </div>
          </div>
          
        </div>
      </div>

      <div className="row mt-0">
        <div className="col-12 mt-5 text-end">
          <button className="createbtn cancelbtn me-3" onClick={() => navigate(-1)}>
            Previous
          </button>

          <button className="createbtn" onClick={() => handleClick({ selectApp, activeApp, selectedResources })}>
            Next
          </button>
        </div>
      </div>

    </>
  );
};

export default TeamSubscriptionList;

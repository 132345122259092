import React from 'react';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeUserData } from '../../../store/slice/userSlice';

const Logout = () => {
  const dispatch = useDispatch();
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);

  const handleLogout = async () => {
    try {
        await axios.post(`${configData.config.UNO_URL}/api/auth/logout`,{},{
              headers: {
                Authorization: `Bearer ${userData?.token}`,
              },
            });
    } catch (error) {
        console.log(error);
    } finally{
      localStorage.removeItem("token");
      dispatch(removeUserData());
      window.location.href = "/login"
    }

  };

  return (
    <>
      <span className="logout" onClick={handleLogout}>
        Logout
      </span>
    </>
  );
};

export default Logout;

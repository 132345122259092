import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import axios from 'axios';
import Filter from '../filter/Filter';
import ResourceList from './resourcelist';
import SearchAll from '../SearchAll';

const Resources = ({ combinedData, setSelectedResources, selectedResources, toggleResources, setStep, step }) => {
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const [filterList, setFilterList] = useState([]);
  const [tabsList, setTabsList] = useState([]);

  const [search, setSearch] = useState();

  const [activeTab, setActiveTab] = useState("");


  useEffect(() => {
    const filteredTabs = combinedData.map(app => app.resourceType).flat();
    setTabsList(filteredTabs);
    if (filteredTabs.length > 0) setActiveTab("0");
  }, [combinedData]);


  const getAllTags = async (filterId) => {
    try {
      setFilterList([]);
        const {data} = await axios.get(`${configData.config.UNO_URL}/api/tag/list/${filterId}`,  {
          headers: { Authorization: `Bearer ${userData.token}` },
        });  
      setFilterList(data?.data?.children || []);
    } catch (error) {

      console.error(error);
    }
  };


  useEffect(() => {
    setSelectedCheckboxes([]);
    if(tabsList.length > 0){
      const type = tabsList[activeTab].type;
    
      switch (type) {
        case "MODULE":
          getAllTags(configData.config.moduleFilterId);
          break;
        case "ENVIRONMENT":
          getAllTags(configData.config.environmentFilterId);
          break;
        case "2D":
          getAllTags(configData.config.skillStream2DFilterId);
          break;
        default:
          console.error("Invalid type: ", type);
      }

    }
  }, [activeTab]);

  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-8 pagetitletext">Simulanis Solutions</div>
        <div className="col-md-4">
          <div className="d-flex justify-content-end">
            <SearchAll search={search} setSearch={setSearch} />
            <div className="ms-2">
              <Filter
                selectedCheckboxes={selectedCheckboxes}
                setSelectedCheckboxes={setSelectedCheckboxes}
                filterOptions={filterList}
              />
            </div>
          </div>
        </div>
        <div className="col-12 pagetitletext pt-2">Resources</div>
      </div>

      <div className="row mt-1">
        <div className="col-12 filterhere">

          <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} >
            {tabsList.map((item, index) => {
              return(
                <Tab key={index} eventKey={`${index}`} title={` ${item.name}`}>
                  
                  <ResourceList
                    resourceTypeId={item.id}
                    setSelectedResources={setSelectedResources}
                    selectedResources={selectedResources}
                    toggleResources={toggleResources}
                    filterTags={selectedCheckboxes}
                    search={search}
                  />

                </Tab>
              )}
              )}
          </Tabs>

          {combinedData && combinedData.filter((item) => item.name).length === 0 && setStep(step + 1)}
        </div>
      </div>
    </>
  );
};

export default Resources;

import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import LottiePlayer from "lottie-react";
import Skeleton from 'react-loading-skeleton';
import NoDataFoundAnim from '../../animations/NoDataFound.json';
import { CloseIcon } from '../../SvgIconsData'
import { RightDragIcon } from '../../assets/icons/iconshere'

const MoveProfile = ({moveProfile,setMoveProfile}) => {
  const [noGroups, setNoGroup] = useState(false)  
  return (
    <Modal size='lg' className='moving-box'  centered show={moveProfile} onHide={()=>setMoveProfile(false)}>
        <Modal.Header className="modalheader">
            Move "Simulanis India Simulanis India Simulanis India Simulanis India"
            <span onClick={()=>setMoveProfile(false)}>
                <CloseIcon/>
            </span>
        </Modal.Header>
        <Modal.Body className='pt-3'>
            <div className='move-chain'>
                <div className='move-chain-item'>
                    <p>Current Group :</p>
                    <div className='nameemail'>
                    <div className="avatar-placeholder">TU</div>
                    <span>
                        <p>Group Name</p>
                    </span>
                    </div>
                    <p>To</p>
                    <div className='nameemail'>
                    <div className="avatar-placeholder">
                        <img src='https://cdn.simulanis.io/sso/uno/development/teams/a980ec6b-959e-4d68-83cc-4ca888f7f936/avatar/ca505c9a-18bc-4e9e-a691-fd62b8251832.jpg' alt='' referrerPolicy='no-referrer'/>
                    </div>
                    <span>
                        <p>Group Name</p>
                    </span>
                    </div>
                </div>
            </div>
            <div className='group-breadcrumbs pt-2'>
                <div className='group-breadcrumbs-item'>
                    Simulanis UNO
                </div>
                <div className='separate'> &gt; </div>
                <div className='group-breadcrumbs-item active' onClick={()=>setNoGroup(true)}>
                    Simulanis India
                </div>
            </div>

            <div className={ noGroups ? 'empty movable-group-list' : 'movable-group-list'}>
                { noGroups ? 
                <>
                   <div className='no-group-found'>
                   <LottiePlayer
                    animationData={NoDataFoundAnim}
                    style={{ height: 100, marginBottom: 0 }}
                    />
                    <p>No Group Found</p>
                   </div>
                </>
                :
                <>
                <div className='movable-group'>
                    <div className='nameemail'>
                    <div className="avatar-placeholder">TU</div>
                    <span>
                        <p>Group Name</p>
                    </span>
                    </div>
                    <div className="appside">
                    <RightDragIcon/>
                    <RightDragIcon/>
                    </div>
                </div>
                <div className='movable-group'>
                    <div className='nameemail'>
                    <div className="avatar-placeholder">
                        <img src='https://cdn.simulanis.io/sso/uno/development/teams/a980ec6b-959e-4d68-83cc-4ca888f7f936/avatar/ca505c9a-18bc-4e9e-a691-fd62b8251832.jpg' alt='' referrerPolicy='no-referrer'/>
                    </div>
                    <span>
                        <p>Group Name</p>
                    </span>
                    </div>
                    <div className="appside">
                    <RightDragIcon/>
                    <RightDragIcon/>
                    </div>
                </div>
                <div className='movable-loading-group my-1'>
                <Skeleton width="20rem" height="23px" className='p-0'/>
                </div>
                <div className='movable-loading-group my-1'>
                <Skeleton width="20rem" height="23px" className='p-0'/>
                </div>
                </>
                }
            </div>
            <div className='modal-footer-btn mt-3 justify-content-between mb-0'>
            <span className='text-success text-bold' style={{ fontSize:"0.85rem" }}> Moved Successfully! </span>
            <div className='d-flex align-items-center gap-2 justify-content-end'>
            <button className='createbtn cancelbtn'>
                Cancel
            </button>
            <button className='createbtn ' >
                Move
            </button>
            <button className="loading save">
                <svg viewBox="25 25 50 50">
                    <circle r="20" cy="50" cx="50">{""}</circle>
                </svg>
            </button>
            </div>
           </div>
        </Modal.Body>
    </Modal>
  )
}

export default MoveProfile

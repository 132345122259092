import React from 'react';
import LottiePlayer from "lottie-react";
import NoDataFoundAnim from '../animations/NoDataFound.json';





const NoDataFound = ({ heading, nodatatext }) => {
    return <div className="no-data-found">

        <div className="textdata">
            <LottiePlayer
                animationData={NoDataFoundAnim}
                style={{ height: 150, marginBottom: 0 }}
            // autoplay = {false}
            // loop = {false}
            />
            <h5 style={{ marginTop: '-10px' }}>{heading}</h5>
            <p>{nodatatext}</p>
        </div>
    </div>
}

// export pr

export default NoDataFound;

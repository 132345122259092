const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  config: {},
  loading: true,
  error: true,
  groupTypes: [],
  roles: []
}

const configSlice = createSlice({
  name: "configSlice",
  initialState,
  reducers: {
    saveConfig: (state, action) => {
      state.config = action.payload.data; 
      state.loading = false;
      state.error = false;
    },
    groupTypes: (state, action) => {
      state.groupTypes = action.payload
    },
    roles: (state, action) => {
      state.roles = action.payload
    }
  },

});

export const { saveConfig, groupTypes, roles } = configSlice.actions;
export default configSlice.reducer;
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import axios from 'axios';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from "moment";
import Analytics from './feedbacksurveycomp/Analytics';
import FeedBackList from './feedbacksurveycomp/FeedBackList';
import CreateFeedback from './feedbacksurveycomp/CreateFeedback';
import FilterDateRange from './feedbacksurveycomp/FilterDates/FilterDateRange';
import Alldatafilter from './feedbacksurveycomp/FilterDates/DateRangeSelector';
import { exportMultipleChartsToPdf } from '../../utils/pdf';
import ProPopup from '../../layouts/ProPopup';
import { CrownIcon } from '../../assets/icons/iconshere';

const Feedback = () => {
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const location = useLocation();
  const { state } = location;
  const [activeTabAnalytics, setActiveTabAnalytics] = useState('analytics');
  const [filter, setFilter] = useState('all');
  const [showFilter, setShowFilter] = useState(false);
  const [show, setShow] = useState(false);
  const [analyticsData, setAnalyticsData] = useState({});
  const [dateRange, setDateRange] = useState('all');
  const [loading, setLoading] = useState(true)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [openPro, setOpenPro] = useState(false);
  const [userPlan, setUserPlan] = useState(null);

  useEffect(()=>{
       if(userData.plans.data) setUserPlan(userData.plans.data);
   },[userData]);

  const getAnalytics = async (req, res) => {
    try {
      const { data } = await axios.get(
        `${configData.config.UNO_URL}/api/feedback/feedbackAnalytics/${filter}`,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );

      setAnalyticsData(data.data || {});
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAnalytics();
  }, [filter]);

  useEffect(() => {
    if (state?.key) {
      setActiveTabAnalytics(state.key);
    }
  }, []);

  const handleTabChange = (tab) => {
    setActiveTabAnalytics(tab);
  };

  const DateRangeOpen = () => {
    setShowFilter(!showFilter);
  };
  
  const tableData = [
    ["Feedback and Management Survey Report", ""],
    ["Name", userData?.user?.name],
    ["Report Created at ", moment().format('ll')],
  ];


  return (
    <>
  
      <Tab.Container id="nested-tabs" activeKey={activeTabAnalytics} onSelect={handleTabChange}>
        <div className="row justify-content-between py-3 pt-0">
          <div className="col-md-4">
            <Nav className="tabsproduct" variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="analytics">Analytics</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="feedbacklist">Feedback & Survey List</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          {!loading &&  <div className="col-md-8">

            
                <div className="d-flex justify-content-md-end gap-3 mt-3 mt-md-0 csvuploaddownload">
                  
                  {activeTabAnalytics === 'analytics' && (
                    <>
                      <Alldatafilter selected={filter} setSelected={setFilter} />
                        <div>
                        <button type='button' onClick={userPlan.feedbackAndSurvey.download ? () => exportMultipleChartsToPdf(tableData) : ()=>setOpenPro(true) } className="uloadoanload">
                          <FileDownloadOutlinedIcon />
                          <span>Report</span>
                        </button>
                        </div>
                    </>
                   )}

                  { userData?.user?.permissions?.includes("GENERAL.FEEDBACK.CREATE") &&
                     userPlan.feedbackAndSurvey.create ?
                        <div className="mt-3 mt-md-0">
                          <div className="mt-3 mt-md-0">
                          <button type='button' className="createbtn" onClick={handleShow}>
                          Create Feedback & Survey
                          </button>
                        </div>
                        </div>
                      :
                        <div className="mt-3 mt-md-0">
                          <button className="DefaultRedBtn pro " onClick={() => setOpenPro(true)}>
                            <AddOutlinedIcon /> <p> Feedback & Survey </p>
                          </button>
                        </div>
                    }

                </div>
        
          </div>}

        </div>
        <Tab.Content>

          <Tab.Pane eventKey="analytics">
            <Analytics loading={loading} analyticsData={analyticsData} />
          </Tab.Pane>

          <Tab.Pane eventKey="feedbacklist">
            <FeedBackList />
          </Tab.Pane>

        </Tab.Content>
      </Tab.Container>

      <CreateFeedback show={show} handleClose={handleClose} />

      {showFilter && (
        <>
          <div onClick={DateRangeOpen} className="overlaydf" />
          <FilterDateRange />
        </>
      )}

      <ProPopup openPro={openPro} setOpenPro={setOpenPro} />                
    </>
  );
};

export default Feedback;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import jwtEncode from 'jwt-encode';
import Skeleton from 'react-loading-skeleton';
import { LockIcon } from '../../SvgIconsData';
import { AppArrowIcon, LeftDragIcon, RightDragIcon } from '../../assets/icons/iconshere';
import logo from '../../assets/img/sicon.png';

const HomeAppList = ({ id, name, search, setAppLength, tabKey }) => {
  const dispatch = useDispatch();
  const [selectApp, setSelectApp] = useState([]);
  const [list, setList] = useState([]);
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const params = useParams();
  const UUID = params?.uuid;
  const [loading, setLoading] = useState(true)
  const fetchMyAppData = async () => {
    try {
      const resData = await axios.get(`${configData.config.UNO_URL}/api/application/all/${id}`, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      const _appList = resData?.data?.data || [];
      setList(_appList);
      setLoading(false)
      setAppLength({[tabKey]: _appList.filter((e) => e.uuid !== configData.config.applicationId).length})
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchMyAppData();
    }
  }, [id]);

  const redirectApplication = (link) => {
    if (userData?.token && link) {
      const payload = { token: userData?.token };
      const token = jwtEncode(payload, '');
      window.open(`${link}?referrer=${token}`);
      // window.location.href = `${link}?referrer=${token}`;
    }
  };

  return (
    <div className='list-here'>
       { loading ? 
            <>
            {[...Array(5)].map((_, index) => (
              <div className={`application-card`} key={index} >
                <div className='application-logo-bg loading'>
                 
                  <Skeleton width="100%" height="100%" baseColor='#2f3a7900' highlightColor='#fff'  />
                  <span className='app-logo' style={{ backgroundColor:"#ffffff00" }}>
                   <Skeleton width="3rem" height="3rem" baseColor='#fff' highlightColor='#4d4d4d20'  />
                  </span>
                </div>
                <div className='application-details'>
              <p className='application-name'>
              <Skeleton width="10vw" height="1rem" borderRadius={5} style={{ lineHeight:"normal" }} baseColor='#4d4d4d05' highlightColor='#4d4d4d15'  />
              </p>
              <p className='application-des'>
              <Skeleton width="15vw" height="1rem" count={2} borderRadius={5} style={{ lineHeight:"normal" }} baseColor='#4d4d4d05' highlightColor='#4d4d4d15'  />
              </p>
            </div>
            </div>
            ))}
        </>
        :
        <>
      {list.length > 0 &&
        list.map((e) => {
          if (e.uuid === configData.config.applicationId) return null;
          if (!e.name.toLowerCase().includes(search.toLowerCase())) {
            return null;
          }
          let linkExists = false;
          const ids = [11,12]
          if(!ids.includes(e.id)){
              linkExists = true;
          }

          return (
            <div className={`application-card`}  key={e.id} onClick={() => e?.channel?.origins?.length > 0 ? redirectApplication(e?.channel?.origins[0]?.url) : {}}>
            <div className={`application-logo-bg ${!e.banner ? 'default-bg' : ''} ${e?.channel?.origins?.length > 0 ? '' : 'locked-content'}`}>
              <img className='default-img-centered' src={e.banner || logo } 
                onError={(e) =>{
                  e.target.src = logo;
                  e.onerror = null;
                } }
                alt=''/>

              <span className='app-logo'>
                <img src={e?.icon || ""}
                  alt=""
                  onError={(e) => {
                    e.target.src = logo;
                    e.onerror = null;
                  }} />

              {e.channel?.origins?.length > 0 && !linkExists && <span className='in-beta'>
                    Beta
                  </span>}

              {e.channel?.origins?.length === 0 && <span className='Locked-app'>
                    <LockIcon/>
                  </span>}
                
              </span>

              {e.channel?.origins?.length > 0 ?
               <span className='arrow-logo'>
                  <AppArrowIcon/>
              </span> 
              :
              null
              // <span className='Locked-app-data'>
              //    Subscribe <RightDragIcon/>
              //   </span>
              }


            </div>
            <div className='application-details'>
              <p className='application-name'>
                {e.name}
              </p>
              <p className='application-des'>
                {e.description}
              </p>
            </div>
            </div>
          );
        })}
        </>
      }
    </div>
  );
};

export default HomeAppList;

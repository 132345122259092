import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from 'react-select';
import { TextField, Button } from '@mui/material';
import { CloseIcon, DeleteIcon } from '../../SvgIconsData';
import signature from '../../assets/img/signature.png';
import DropZone from '../dropzone/DropZone';
import { optionsType, roleTypes } from "../../utils/constant";
import { editUserValidationSchema } from './validation';
import { Thumbnail } from '../thumbnail';

const EditUsers = ({ refresh, showUser, CreateUserModalClose, editUserData }) => {
    const dispatch = useDispatch();
    const [userUuid, setUserUuid] = useState(null);
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [error, setError] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const formik = useFormik({
        initialValues: {
            name: "",
            phone: "",
            designation: "",
            avatar: "",
            roles: [],
            role: null,
            loginType: "1",
            active:true
        },
        validationSchema: editUserValidationSchema,
        onSubmit: async (values) => {
            const data = {
                name: values?.name?.trim() || null,
                avatar: values?.avatar?.trim() || null,
                designation: values?.designation?.trim() || null,
                phone: values?.phone?.trim() || null,
                role : values.role.value,
                active: values.active
            }

            try {
                setFormLoading(true)
                await axios.post(`${configData.config.UNO_URL}/api/user/edit/${userUuid}`, data, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                        "Content-Type ": "application/json"
                    }
                });

                CreateUserModalClose();
                setFormLoading(false)

            } catch (error) {
                console.error(error);
                setError(error?.response?.data?.message);
                setTimeout(()=> setError("") , 2000)
                setFormLoading(false)
            }
            refresh();
        }
    });

    useEffect(() => {
        if (roleTypes.length) formik.setFieldValue('role', roleTypes[2].role);
    }, [optionsType]);

    const setUserDetail = () => {
        if (JSON.stringify(editUserData) === '{}' ) return;
        formik.setFieldValue("name", editUserData?.name || null)
        formik.setFieldValue("designation", editUserData?.designation || null)
        formik.setFieldValue("avatar", editUserData?.avatar || null);
        formik.setFieldValue("active", editUserData.active);
        const roleallocation = editUserData?.roleAllocations.length > 1 ? editUserData.roleAllocations.filter(e => e.role.type !== 'DEFAULT')[0] : editUserData.roleAllocations[0];
        const { role } = roleallocation;
        formik.setFieldValue("role" , {label : role.name , value : role.id})
        setUserUuid(editUserData?.uuid);

        const isDisable = editUserData?.uuid === userData.user.uuid;
        setIsDisabled(isDisable);

    }

    useEffect(() => {
        setUserDetail();
    }, [showUser])

    const handleFileUpload = async (data) => {
        formik.setFieldValue('avatar', data?.file)
    };
    const options = configData.roles.map((e) => ({ value: e.id, label: e.name }))


    function debounce(func, wait) {
        let timeout;
        return function(...args) {
          const context = this;
          clearTimeout(timeout);
          timeout = setTimeout(() => func.apply(context, args), wait);
        };
      }

    const handleFormSubmit = debounce(formik.handleSubmit, 700);

    const handleChangeField = (e) => {
        if (e.target.name === "phone") {
            const numericValue = e.target.value.replace(/\D/g, '');
            formik.setFieldValue("phone", numericValue);
        } else {
            formik.setFieldValue(e.target.name, e.target.value.replace(/\s+/g, ' '))
        }
    }

    const handleStatusChange = async(e) =>{
        formik.setFieldValue(e.target.name, !formik.values.active)
    }

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 38,
        height: 22,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 18,
          height: 18,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

    return (
        <>
            <Modal size='md' centered show={showUser} onHide={CreateUserModalClose}>
                <Modal.Header className='modalheader text-center'>
                        Edit User
                        <span onClick={CreateUserModalClose}>
                            <CloseIcon/>
                        </span>
                    </Modal.Header>
                <Modal.Body>
                        <div className="modalcont">
                            <div className="row">
                                <div className="col d-flex justify-content-center">
                                    <div className='profile-Logo'>
                                        {formik.values.avatar?.length > 0 ?
                                            <div className='groupEditLogo position-relative '>
                                                <Thumbnail path={formik.values.avatar || ""} name={""} />
                                                <span className='closeicon position-absolute'  onClick={() => formik.setFieldValue('avatar', null)} >
                                                    <DeleteIcon/>
                                                </span>
                                            </div>
                                            :
                                            <DropZone text="Avatar" name="avatar" img={signature} onFileUpload={handleFileUpload} />
                                        }
                                        <p>Profile</p>
                                    </div>
                                </div>
                            </div>
                            <div className='createform'>
                                <div className="form-group">
                                    <p>Name  <span> * </span></p>
                                    <TextField
                                        name="name"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Enter Name"
                                        size="small"
                                        value={formik.values.name}
                                        onChange={handleChangeField}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </div>

                                <div className="form-group with-select">
                                    <p>User Role  <span> * </span></p>
                                    <Select 
                                        className="single-select"
                                        classNamePrefix="react-select"
                                        menuPlacement="auto"
                                        maxMenuHeight={200}
                                        menuPosition={'fixed'}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                            ...theme.colors,
                                            primary50 : '#ca373c50',
                                            primary25 : '#ca373c50',
                                            primary: '#ca373c70',
                                            danger :'#000000',
                                            neutral30: '#00000050'
                                            },
                                        })}
                                        styles={{
                                            control: (styles) => ((formik.touched.role && Boolean(formik.errors.role)) ? {
                                                ...styles,
                                                borderColor: 'red'
                                            } : { ...styles })
                                        }} 
                                        options={options}
                                        isDisabled={isDisabled}
                                        placeholder="Select Role" 
                                        value={formik.values.role} 
                                        onChange={(e) => formik.setFieldValue('role', e)} 
                                    />

                                    {formik.touched.role ? <p className='text-danger ps-3 mt-1' style={{ fontSize: "0.75rem" }} >
                                    {formik.errors.role}</p> : null}

                                </div>

                                {error && <p className='errormessage' >{error}</p>}

                                <div className="form-group">
                                    <p>Phone</p>
                                    <TextField
                                        name="phone"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Enter Phone"
                                        size="small"
                                        type='tel'
                                        inputProps={{ maxLength: 15 }}
                                        value={formik.values.phone}
                                        onChange={handleChangeField}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                                        helperText={formik.touched.phone && formik.errors.phone}
                                    />
                                </div>

                                <div className="form-group">
                                    <p>Designation</p>
                                    <TextField
                                        name="designation"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Enter Designation"
                                        size="small"
                                        value={formik.values.designation}
                                        onChange={handleChangeField}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.designation && Boolean(formik.errors.designation)}
                                        helperText={formik.touched.designation && formik.errors.designation}
                                    />
                                </div>

                                <div className="form-group selectall-option">
                                    <p>Status</p>
                                    <div className='d-flex align-items-center justify-content-start'>
                                    <FormControlLabel
                                        className='me-0'
                                        name="active"
                                        disabled={isDisabled}
                                        control={<IOSSwitch sx={{ m: 1 }} 
                                        checked={formik.values.active} 
                                        onChange={handleStatusChange}
                                        />
                                    }
                                    />
                                    <span className={formik.values.active ? "active-label" : "inactive-label"}>{formik.values.active ? "Active" : "Inactive"}</span>
                                    </div>
                                    {/* <div className="d-flex align-items-center justify-content-start gap-2">
                                    <div className="checkbox-apple">
                                    <input className="yep" id="check-apple" name="active" type="checkbox" disabled={isDisabled} checked={formik.values.active} onChange={handleStatusChange} />
                                    <label htmlFor="check-apple">{""}</label>
                                    </div>
                                    <p className='active'>{formik.values.active ?  "Active" : "Inactive"}</p>

                                    </div> */}
                                </div>

                            </div>
                            <div className='modal-footer-btn mt-3 mb-1'>
                            <button className='createbtn cancelbtn' onClick={CreateUserModalClose}>
                                Cancel
                            </button>
                            { !formLoading ? 
                            <button className='createbtn ' onClick={handleFormSubmit}>
                                Update
                            </button>
                            :
                            <button className="loading save">
                                <svg viewBox="25 25 50 50">
                                    <circle r="20" cy="50" cx="50">{""}</circle>
                                </svg>
                            </button>
                            }       
                            </div>
                        </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditUsers

import { LocalConvenienceStoreOutlined } from '@mui/icons-material';
import React, { useState,useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const NewPlatUsers = ({latestFeedbacks}) => {
  

  const [totalInvites, setTotalInvites] = useState(0);
  const [totalResponses, setTotalResponses] = useState(0);
  const [pending, setPending] = useState(0);

  useEffect(() => {
    if (latestFeedbacks) {
      const invitesLength = latestFeedbacks.invites ? latestFeedbacks.invites.length : 0;
      const responsesLength = latestFeedbacks.responses ? latestFeedbacks.responses.length : 0;
      setTotalInvites(invitesLength);
      setTotalResponses(responsesLength);
      setPending(invitesLength - responsesLength);
    }
  }, [latestFeedbacks]);
 

  const series = [totalResponses, pending];

  const options = {
    chart: {
      type: 'donut',
    },
    dataLabels: {
      enabled: false
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          show: false
        }
      }
    }],
    legend: {
      position: 'bottom',
      offsetY: 0,
    },
    labels: [`Completed`, `Pending`],
    colors: ['#444', '#ca373c'],
  };

 

  return (
    <div id="chart" className='position-relative mt-4'>
      <ReactApexChart options={options} series={series} type="donut" width="100%" height="250px" />
      <div className="completeddd">
        <h2>{totalInvites}</h2>
        <p>Total users</p>
      </div>
    </div>
  );
};

export default NewPlatUsers;

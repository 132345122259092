import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ForgotForm } from '../sections/auth/forgot';
import slogo from '../assets/img/logo.png';
import forgotimg from '../assets/img/forgotimg.png';

const ForgotPassword = () => {
  const configData = useSelector((state) => state.configSlice);

  const [confirm, setConfirm] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title> Forgot Password | Simulanis Apps</title>
      </Helmet>

      <div className="loginPage">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 ">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                      <div className=" d-flex">
                        <img src={forgotimg} alt="" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="p-4">
                        <img className="slogo" src={configData.config.logo} alt="" />
                        {confirm ? <></> : <h3>Forgot Password?</h3>}
                        {confirm ? (
                          <></>
                        ) : (
                          <h6 className="mb-1">Enter the email address associated with you account </h6>
                        )}

                        {confirm ? (
                          <div className="reset-page">
                            <h6 className="mb-1 text-center py-4">
                              Check your email for a link to reset your password. If it doesn’t appear within a few
                              minutes, check your spam folder.
                            </h6>
                            <button className="reset-page-btn" onClick={() => navigate('/login')}>
                              Return to login
                            </button>
                          </div>
                        ) : (
                          <> {configData.config.emailLogin && <ForgotForm setConfirm={setConfirm} />} </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CSVUploadDropZone from '../dropzone/CSVUploadDropZone';
import avatar from '../../assets/img/avatar.png';
import UserSampleCSV from '../../assets/UserSampleCSV.csv';
import GroupSampleCSV from '../../assets/GroupSampleCSV.csv';
import successIcon from '../../assets/img/success.png'
import { CloseIcon } from '../../SvgIconsData';

export const UserCSVUpload = ({ showUserCSV, csvUserModalClose }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userCsvList, setUserCsvList] = useState([]);
  const [csvErrorList, setCsvErrorList] = useState({});
  const [error, setError] = useState(null);
  const [modalSize, setModalSize] = useState("modal-md");
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const params = useParams();
  const UUID = params?.uuid;

  const validateCSV = (data) => {
    const isValid = data.every((record, index) => {
      const name = record.name;
      const email = record.email;
      const phone = record.phone;
      const uid = record.uid;
      const password = record.password;

      if (!name) {
        return false;
      }

      if (!email) {
        if (!(uid && password)) {
          return false;
        }
      } else if (!isValidEmail(email)) {
        return false;
      }

      if (uid) {
        const isDuplicateUid = data.slice(0, index).some((prevRecord) => prevRecord.Uid === uid);
        if (isDuplicateUid) {
          return false;
        }

      }
      if (email) {
        const isDuplicateEmail = data.slice(0, index).some((prevRecord) => prevRecord.Email === email);
        if (isDuplicateEmail) {
          return false;
        }
      }

      if (phone) {
        const phonePattern = /^[0-9+\s]+$/;
        const validPhone = phonePattern.test(phone);
        if (!validPhone) {
          return false;
        }
      }
      return true;
    });

    if (!isValid) {
      setError("CSV data validation failed.")
    }

    return isValid;

  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleUserFileUpload = (data) => {
    const csvObject = []
    const headers = ["Name", "Email", "Designation", "Avatar", "Phone", "Uid", "Password"];
    const headersIncluded = headers.every(header => data?.fields.includes(header));
    if (headersIncluded) {

      data?.data.forEach((e, i) => {
        const _avatar = e.Avatar ? String(e.Avatar).trim().replace(/\s+/g, ' ') || null : null;
        const _designation = e.Designation ? String(e.Designation).trim().replace(/\s+/g, ' ') || null : null;
        const _email = e.Email ? String(e.Email).trim().replace(/\s+/g, ' ') || null : null;
        const _name = e.Name ? String(e.Name).trim().replace(/\s+/g, ' ') || null : null;
        const _password = e.Password ? String(e.Password).trim().replace(/\s+/g, ' ') || null : null;
        const _phone = e.Phone ? String(e.Phone).trim().replace(/\s+/g, ' ') || null : null;
        const _uid = e.Uid ? String(e.Uid).trim().replace(/\s+/g, ' ') || null : null;

        if (_avatar || _designation || _email || _name || _password || _phone || _uid) {
          const object = {
            id: i + 1,
            avatar: _avatar,
            designation: _designation,
            email: _email,
            name: _name,
            password: _password,
            phone: _phone,
            uid: _uid
          }
          csvObject.push(object);
        }

      });

      const csv = {
        data: [...csvObject],
        fields: data?.fields,
        name: data?.name
      }

      // const isValid = validateCSV(csv?.data);
      const isValid = true;

      if (isValid) {
        const csvData = [];
        setUserCsvList([]);
        setCsvErrorList({});
        setUserCsvList([...csv?.data]);
        setModalSize("modal-lg");

      } else {
        setError("Csv validation failed.");
        setTimeout(() => { setError(null) }, 4000);
      }

    } else {
      setError("Csv validation failed.");
      setTimeout(() => { setError(null) }, 4000);
    }

  };

  const deleteUser = (id) => {
    const listCsv = userCsvList;
    const result = listCsv.filter((item) => item.id !== id);
    setUserCsvList([...result]);
    if (result.length === 0) {
      setModalSize("modal-md");
    }
  };

  const handleSubmitUserCSV = async () => {
    console.log("clickkkkeddd")
    const data = {
      data: userCsvList
    }
    setCsvErrorList({})
    try {
      const response = await axios.post(`${configData.config.UNO_URL}/api/bulk-actions/user/${UUID}`, data, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
          "Content-Type ": "application/json"
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          // updateFileProgress(file, Math.floor(progress));
        }
      });

      setUserCsvList([]);
      setCsvErrorList({ ...response?.data?.data });

    } catch (error) {
      console.error(error);
    }

  }

  const handleUserCloseCsvModal = (status = false) => {
    setError(null)
    setUserCsvList([]);
    setCsvErrorList({});
    setModalSize('modal-md');
    csvUserModalClose(status);
  }

  return (
    <>
      <Modal centered className={`csvuploaddrr ${modalSize}`} show={showUserCSV} onHide={handleUserCloseCsvModal}>
          <Modal.Header className="modalheader  text-center ">
             Users Upload CSV
             <span onClick={handleUserCloseCsvModal}>
                  <CloseIcon/>
              </span>
          </Modal.Header>
        <Modal.Body>
          <div className="modalcont mt-3">
            <div className="row">
              <div className="col-12">

                {(userCsvList.length > 0 || Object.keys(csvErrorList).length > 0) ?

                  <div className="csvfiledatalist">

                    {'successCount' in csvErrorList ?
                      <div>
                        <div className='d-flex align-items-center flex-wrap mb-2'>
                          <p className='pe-5'>
                            <span>Total Entries: {csvErrorList.successCount + csvErrorList.errorCount}</span>
                          </p>
                          <p className='pe-5'>
                            <span>Added Entries: {csvErrorList.successCount} </span>
                          </p>
                          <p className=''>
                            <span>Failed Entries: {csvErrorList.errorCount} </span>
                          </p>
                        </div>

                        {csvErrorList?.errors?.length > 0 ?
                          <table className="table">
                            <thead>
                              <tr>
                                <th>S.No.</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Designation</th>
                                <th>Role</th>
                                <th>Error</th>
                                <th>{ }</th>
                              </tr>
                            </thead>
                            <tbody>

                              {csvErrorList?.errors?.map((e, i) => {
                                return (
                                  <tr key={i + 1}>
                                    <td>{i + 1}</td>
                                    <td>
                                      <span className="d-flex">
                                        {e?.avatar ? <img className="avatartc" src={e?.avatar} alt="" /> : null}
                                        {e?.name}
                                      </span>
                                    </td>
                                    <td> {e?.email} </td>
                                    <td> {e?.designation} </td>

                                    <td> {e?.phone} </td>
                                    <td>
                                      <span>{e?.reason}</span>
                                    </td>
                                  </tr>
                                );
                              })}

                            </tbody>
                          </table>
                          :
                          <div className='successmessage'>
                            <h2>Upload Successful!</h2>
                            <div className='d-flex justify-content-center mt-2'>
                              <img className='successicon' src={successIcon} alt="" />
                              <p>Your file has been uploaded.</p>
                            </div>

                          </div>
                        }

                      </div>
                      :
                      userCsvList.length > 0 ?
                        <table className="table">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Designation</th>
                              <th>Phone</th>
                              <th>Uid</th>
                              <th>Password</th>
                              <th>{ }</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userCsvList.map((e, i) => {
                              const csvName = e?.name?.split(' ').slice(0, 2).map(word => word[0].toUpperCase()).join('');
                              return (
                                <tr key={i + 1}>
                                  <td>{i + 1}</td>
                                  <td>
                                    <span className="d-flex align-items-center">
                                      {e?.avatar ? <img className="avatartc" src={e?.avatar} alt="" /> :
                                        <>
                                          <div className='avcharactr'>
                                            {csvName}
                                          </div>
                                        </>
                                      }
                                      {e?.name}
                                    </span>
                                  </td>
                                  <td> {e?.email} </td>
                                  <td> {e?.designation} </td>

                                  <td> {e?.phone} </td>
                                  <td> {e?.uid} </td>
                                  <td> {e?.password} </td>
                                  <td>
                                    <span className="cancelcsv" onClick={() => deleteUser(e.id)}>
                                      <HighlightOffOutlinedIcon />{' '}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}

                          </tbody>
                        </table>
                        :
                        null
                    }

                  </div>
                  :

                  <div className='csvuploadefs'>
                    <CSVUploadDropZone onFileUpload={handleUserFileUpload} text="Drag & drop or choose file to upload csv" accept=".csv" name="csv" />
                    <h6 className='mt-3 mb-0'>Required Fields*</h6>
                    <p className='text-xs'>
                      {`Name, Either Valid Email or (Uid & Password)`}
                      <br />
                      {`Email and Uid Should be unique.*`}
                    </p>

                    <p className='errormessage m-0 p-0' style={{ minHeight:"20px" }}> {error ? <> {error} </> : "" } </p>
                    <div className='d-flex align-items-center justify-content-between mt-3'>
                      <p className="mb-0">
                        <a href={UserSampleCSV} download className="download-text">
                          <span>Download User CSV Templates</span>
                        </a>
                      </p>
                      <div>
                        <button onClick={handleUserCloseCsvModal} className='createbtn cancelbtn'>Cancel</button>
                      </div>
                    </div>
                  </div>

                }

              </div>

              {userCsvList.length > 0 ?
              <div className='modal-footer-btn mt-3'>
                <button className='createbtn cancelbtn' onClick={handleUserCloseCsvModal}>
                Cancel
                </button>
                <button className='createbtn ' onClick={handleSubmitUserCSV}>
                Upload
                </button>
              </div>
              :
              <>
              {  Object.keys(csvErrorList).length > 0 ? 
              <div className='modal-footer-btn mt-3'>
                <button className='createbtn ' onClick={handleUserCloseCsvModal}>
                  Done
                </button>
                
              </div>
              :
              null
              }
              </>
            }
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
};


export const GroupCSVUpload = ({ showGroupCSV, csvGroupModalClose }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userCsvList, setUserCsvList] = useState([]);
  const [csvErrorList, setCsvErrorList] = useState({});
  const [error, setError] = useState(null);
  const [modalSize, setModalSize] = useState("modal-md");
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const params = useParams();
  const UUID = params?.uuid;

  const validateGroupCsv = (data) => {
    const isValid = data.every((record) => {
      const name = record.name;
      if (!name) {
        return false;
      }
      return true;
    });

    if (!isValid) {
      return false
    }
    return true
  }


  const handleUserFileUpload = (data) => {
    const csvData = [];
    setUserCsvList([]);
    setCsvErrorList({});
    setError(null);

    const headers = ["Name", "Avatar"];
    const headersIncluded = headers.every(header => data?.fields.includes(header));

    if (headersIncluded) {
      data?.data?.forEach((e, i) => {
        const _name = e?.Name ? String(e.Name).trim().replace(/\s+/g, ' ') || null : null;
        const _avatar = e?.Avatar ? String(e.Avatar).trim().replace(/\s+/g, '') || null : null;
        if (_name || _avatar) {
          const finalResult = {
            id: i + 1,
            name: _name,
            avatar: _avatar,
          };
          csvData.push(finalResult);
        }
      });

      // const validGroupCsv = validateGroupCsv(csvData);
      const validGroupCsv = true;

      if (validGroupCsv) {
        setUserCsvList([...csvData]);
        setModalSize("modal-lg");
      } else {
        setError("Csv validation failed.");
        setTimeout(() => { setError(null) }, 4000);
      }
    }
    else {
      setError("Csv validation failed.");
      setTimeout(() => { setError(null) }, 4000);
    }

  };

  const deleteUser = (id) => {
    const listCsv = userCsvList;
    const result = listCsv.filter((item) => item.id !== id);
    setUserCsvList([...result]);
    if (result.length === 0) {
      setModalSize("modal-md");
    }
  };

  const handleSubmitGroupCSV = async () => {
    const data = {
      data: userCsvList
    }

    setCsvErrorList({})
    try {
      const response = await axios.post(`${configData.config.UNO_URL}/api/bulk-actions/group/${UUID}`, data, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
          "Content-Type ": "application/json"
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          // updateFileProgress(file, Math.floor(progress));
        }
      });

      setUserCsvList([]);
      if (response?.data?.data?.errors.length === 0) {
        handleGroupCloseCsvModal(true);
      } else {
        setCsvErrorList({ ...response?.data?.data });
      }

    } catch (error) {
      console.error(error);
    }

  }

  const handleGroupCloseCsvModal = (status = false) => {
    setError(null)
    setUserCsvList([]);
    setCsvErrorList({});
    setModalSize('modal-md');
    csvGroupModalClose(status);
  }

  return (
    <>
      <Modal centered className={`csvuploaddrr ${modalSize}`} show={showGroupCSV} onHide={handleGroupCloseCsvModal}>
          <Modal.Header className="modalheader text-center ">

            Groups Upload CSV
            {/* <span className="cursor-pointer text-muted" onClick={handleGroupCloseCsvModal}>
              Cancel
            </span> */}

              <span onClick={handleGroupCloseCsvModal}>
                  <CloseIcon/>
              </span>

            {/* {(userCsvList.length === 0 && Object.keys(csvErrorList).length === 0) ?
              <p className="mb-0">
                <a href={GroupSampleCSV} download className="uloadoanload">
                  <span>Download Group CSV Templates</span>
                </a>
              </p>
              :
              ''
            } */}

            {/* {userCsvList.length > 0 ?
              <Button variant="text" onClick={handleSubmitGroupCSV}>
                Upload
              </Button>
              :
              Object.keys(csvErrorList).length > 0 ?
                <Button onClick={handleGroupCloseCsvModal} variant="text">
                  Done
                </Button>
                :
                <p>{" "}</p>
            } */}



          </Modal.Header>
        <Modal.Body>

          <div className="modalcont mt-3">
            <div className="row">
              <div className="col-12">

                {(userCsvList.length > 0 || Object.keys(csvErrorList).length > 0) ?

                  <div className="csvfiledatalist">

                    {Object.keys(csvErrorList).length > 0 ?
                      <div>
                        <div className='d-flex align-items-center flex-wrap mb-2'>
                          <p className='pe-5'>
                            <span>Total Entries: {csvErrorList.successCount + csvErrorList.errorCount}</span>
                          </p>
                          <p className='pe-5'>
                            <span>Added Entries: {csvErrorList.successCount} </span>
                          </p>
                          <p className=''>
                            <span>Failed Entries: {csvErrorList.errorCount} </span>
                          </p>
                        </div>

                        <table className="table">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>Name</th>
                              <th>{ }</th>
                            </tr>
                          </thead>
                          <tbody>

                            {csvErrorList?.errors?.map((e, i) => {
                              return (
                                <tr key={i + 1}>
                                  <td>{i + 1}</td>
                                  <td>
                                    <span className="d-flex">
                                      {e?.avatar ? <img className="avatartc" src={e?.avatar} alt="" /> : null}
                                      {e?.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span>{e?.reason}</span>
                                  </td>
                                </tr>
                              );
                            })}

                          </tbody>
                        </table>

                      </div>
                      :
                      userCsvList.length > 0 ?
                        <table className="table">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>Name</th>
                              <th>{ }</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userCsvList.map((e, i) => {
                              const csvName = e?.name?.split(' ').slice(0, 2).map(word => word[0].toUpperCase()).join('');
                              return (
                                <tr key={i + 1}>
                                  <td>{i + 1}</td>
                                  <td>
                                    <span className="d-flex align-items-center">
                                      {e?.avatar ?
                                        <img className="avatartc" src={e?.avatar} alt="" />
                                        :
                                        <div className='avcharactr'>
                                          {csvName}
                                        </div>
                                      }
                                      {e?.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="cancelcsv" onClick={() => deleteUser(e.id)}>
                                      <HighlightOffOutlinedIcon />{' '}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}

                          </tbody>
                        </table>
                        :
                        null
                    }

                  </div>

                  :

                  <>
                    <div className='csvuploadefs'>
                      <CSVUploadDropZone onFileUpload={handleUserFileUpload} text="Drag & drop or choose file to upload csv" accept=".csv" name="csv" />
                      <h6 className='mt-3 mb-0'>Required Fields*</h6>
                      <p className='text-xs m-0'> Name </p>
                      <p className='errormessage m-0 p-0' style={{ minHeight:"20px" }}> {error ? <> {error} </> : "" } </p>
                      <div className='d-flex align-items-center justify-content-between mt-3'>
                        <p className="mb-0">
                          <a href={GroupSampleCSV} download className="download-text">
                            <span>Download Group CSV Templates</span>
                          </a>
                        </p>
                        <div>
                          <button onClick={handleGroupCloseCsvModal} className='createbtn cancelbtn'>Cancel</button>
                        </div>
                      </div>
                    </div>
                  </>
                }

              </div>
              
               


              {userCsvList.length > 0 ?
              <div className='modal-footer-btn mt-3'>
               <button className='createbtn cancelbtn' onClick={handleGroupCloseCsvModal}>
                Cancel
              </button>
              <button className='createbtn ' onClick={handleSubmitGroupCSV}>
                Upload
              </button>
              </div>
              :
              <>
              { Object.keys(csvErrorList).length > 0 ?
              <div className='modal-footer-btn mt-3'>
               
                    <button className='createbtn ' onClick={handleGroupCloseCsvModal} >
                      Done
                    </button>
                    </div>
                    :
                    null
              }
              </>
              }
             
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
};


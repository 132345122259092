import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import LabelIcon from '@mui/icons-material/Label';
import { EditIcon } from '../../SvgIconsData';
import ProPopup from "../../layouts/ProPopup";

const GroupType = ({ permissions, userPlan }) => {
    const [list, setList] = useState([]);
    const navigate = useNavigate()
    const [openRow, setOpenRow] = useState(null);
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [moreListItems, setMoreListItems] = useState(false)
    const [loading, setLoading] = useState(true);
    const [openPro, setOpenPro] = useState(false);
    const fetchGroupTypes = async () => {
        try {
            const { data } = await axios.get(`${configData.config.UNO_URL}/api/role/grouptype/list`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            setList(data.data.groupTypes);
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchGroupTypes();
    }, [])
    const toggleRowVisibility = (groupId) => {
        if (openRow === groupId) {
            setOpenRow(null);
        } else {
            setOpenRow(groupId);
        }
    };

    return (
        <>

            <div className='container-fluid'>

                <div className="row mt-4 colTableStandard  align-middle subheadlist align-middle p-1 px-2">
                    <div className="col-3 p-2">Group Type</div>
                    <div className="col-3 p-2">Allowed Predefined Roles</div>
                    <div className="col-3 p-2">Allowed Group Types</div>
                    <div className="col-2 p-2 text-center">Actions</div>
                    <div className="col-1 p-2">{""}</div>
                </div>

                {
                    loading ?
                    <>
                     {[...Array(3)].map((_, index) => (
                 <div className='row rolePerRow'>
                    <div className='col-3'>
                        <div className='UserDataHere managemodal'>
                            <h3> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </h3>
                            <p> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </p>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='UserDataHere managemodal'>
                            <h3> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </h3>
                            <p> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </p>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='UserDataHere managemodal'>
                            <h3> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </h3>
                            <p> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </p>
                        </div>
                    </div>
                        </div> ))}
                    </>
                    :
                    <>
                     {list.map((groupType) => (
                    <div key={groupType.id} className={openRow === groupType.id ? 'active row rolePerRow' : 'row rolePerRow'}>
                        <div className='col-3'>
                            <div className='UserDataHere managemodal'>
                                <h3>{groupType.name} {groupType.color ? <LabelIcon style={{ color: groupType.color }} /> : null}</h3>
                                <p>{groupType.description}</p>
                            </div>
                        </div>
                        <div className='col-3 d-flex align-items-center justify-content-start'>
                            <div className='rolelist atroles' >


                                {openRow === groupType.id ?
                                    <>
                                        {groupType.allowedRoles.map((e) => e.name).map((role, roleIndex) => {
                                            if (roleIndex > 4) return null;
                                            return (
                                                <div className='userselect' style={{ padding: "3px 10px" }} key={roleIndex}> {role} </div>
                                            )
                                        })}
                                        {groupType.allowedRoles.length > 5 &&
                                            <div className='morehead'>
                                                <span className='morebtn' onClick={() => setMoreListItems(!moreListItems)}> {groupType.allowedRoles.length - 5} + more </span>

                                            </div>
                                        }
                                    </>
                                    :
                                    <>
                                        {groupType.allowedRoles.map((e) => e.name).map((role, roleIndex) => <div className='userselect' style={{ padding: "3px 10px" }} key={roleIndex}> {role} </div>
                                        )}

                                    </>
                                }





                            </div>
                        </div>
                        <div className='col-3 d-flex align-items-center justify-content-start'>
                            <div className='rolelist' >
                                {openRow === groupType.id ?
                                    <>
                                        {groupType.allowed.map((e) => e.name).map((type, typeIndex) => {
                                            if (typeIndex > 1) return null;
                                            return (
                                                <div className='userselect' style={{ padding: "3px 10px" }} key={typeIndex}> {type} </div>
                                            )
                                        })}
                                        {groupType.allowed.length > 2 && <p> {groupType.allowed.length - 2} + more </p>}
                                    </>
                                    :
                                    <>
                                        {groupType.allowed.map((e) => e.name).map((type, typeIndex) => <div className='userselect' style={{ padding: "3px 10px" }} key={typeIndex}> {type} </div>
                                        )}

                                    </>
                                }

                            </div>
                        </div>
                        <div className='col-2 d-flex align-items-center justify-content-center'>
                            {!permissions.includes("TEAM.GROUPTYPE.CREATE") ? null :
                             <div className='roleactionbtns'>
                                <span onClick={() => userPlan?.roles?.create?.groupType ?
                                navigate(`/dashboard/roles/grouptype/edit/${groupType.id}`, { state: groupType })
                                :
                                setOpenPro(true)                             
                              }><EditIcon /></span>
                            </div>
                            }
                        </div>
                        <div className='col-1 d-flex align-items-center justify-content-center'>
                            <div className='d-flex justify-content-between'>
                                <span style={{ cursor: 'pointer' }} onClick={() => toggleRowVisibility(groupType.id)}>
                                    {openRow === groupType.id ? (
                                        <i
                                            style={{ cursor: 'pointer' }}
                                            className="bi-chevron-down"
                                        />
                                    ) : (
                                        <i
                                            style={{ cursor: 'pointer' }}
                                            className="bi-chevron-up"
                                        />
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}

                    </>
                }
               
            </div>
            <ProPopup openPro={openPro} setOpenPro={setOpenPro} /> 
        </>
    );
}

export default GroupType;

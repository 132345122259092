import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Nav, Tab, Form } from 'react-bootstrap';

const ResponseModal = ({ show, handleClose, activeTab, feedback }) => {

  return (
    <>
      <Modal show={show} onHide={handleClose} className="createfeedback">
        <Modal.Header className="bg-danger p-3 py-2 text-white" closeButton>
          <Modal.Title style={{ fontSize: '1.25rem' }}>{feedback?.feedback?.name || ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 pt-2">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                {feedback?.feedback?.data.length > 0 &&
                  feedback?.feedback?.data.map((item, index) => (
                    <div className="col-md-12" key={index}>
                      <h6 className='my-2'>{`Q${index + 1}.${item?.name}`}</h6>
                      {item.type === 'TEXT' ? (
                        <input className='form-control' type="text" value={feedback?.data[item.id] || ''} readOnly />
                      ) : (
                        item.options.map((option, optionIndex) => (
                          <Form key={optionIndex}>
                            <Form.Check
                              type="radio"
                              label={option.name}
                              name={`q${index}`}
                              id={`q${index}-${optionIndex}`}
                              checked={option.id === parseInt(feedback?.data[item.id], 10)}
                            />
                          </Form>
                        ))
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResponseModal;

import React, { useState, createContext } from 'react'
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux';
import AppsIcon from '@mui/icons-material/Apps';
import { Helmet } from 'react-helmet-async';
import ToggleButton from './layoutcomp/ToggleButton';
import avatar from '../../assets/img/avatar.png';
import logo from '../../assets/img/logo.svg';
import UserSettingComp from './layoutcomp/UserSettingComp';
import SimulanisSearchApp from './layoutcomp/SimulanisSearchApp';
import HeaderSearch from './HeaderSearch';
// import {SideNavIcon } from 'src/SvgIconsData';
import { SideNavIcon } from '../../SvgIconsData';

const Header = ({ menuToggle }) => {

    const UserContext = createContext();
    const userData = useSelector((state) => state.userSlice);
    const configData = useSelector((state) => state.configSlice);


    return (
        <>
            <Helmet>
            <title> Simulanis Apps </title>
            </Helmet>
            <header className='header'>
                <div className="container-fluid">
                    <Navbar className='py-0 justify-content-between' collapseOnSelect expand="lg">
                        <div className='d-flex align-items-center'>
                            <Navbar.Brand className='logo-title' >
                                <Link to={`/dashboard/home/${userData?.user.group.uuid}`} >
                                    <img className='logo' 
                                         src={configData.config.logo}
                                         onError={(e) => {
                                          e.target.src = logo;
                                          e.target.onerror = null;
                                          }}
                                    
                                    alt="" />
                                    <p> {configData.config.name} </p>
                                </Link>
                            </Navbar.Brand>
                            <span onClick={menuToggle} className='d-block d-md-none'><SideNavIcon /></span>
                        </div>

                        <UserSettingComp />

                        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <div className='ms-auto'>
                                <div className='d-flex align-items-center'>
                                    <UserSettingComp />
                                </div>
                            </div>
                        </Navbar.Collapse> */}
                    </Navbar>
                </div>
            </header>
        </>
    )
}

export default Header

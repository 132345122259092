import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import  { DownDragIcon, UpDragIcon } from '../../assets/icons/iconshere';
import logo from '../../assets/img/sicon.png';
import NoDataFound from '../NoDataFound';

const SubscriptionList = () => {


  const navigate = useNavigate();
  const userData = useSelector((state) => state.userSlice);
  const permissions = userData.user.permissions;
  const[loading, setLoading] = useState(true)
  const [Expandlist, setExpandList] = useState(null);
  const location = useLocation();
  const groupName = location?.state?.groupName || null;
   if(!groupName) navigate(`/dashboard/subscriptions/${userData?.user?.group?.uuid}`);
  const [subscriptionList, setSubscriptionList] = useState([]);



  const configData = useSelector((state) => state.configSlice);

  const params = useParams();

  const uuid = params?.uuid;

  const ListExpandBtn = (id) => {
    if (Expandlist === id) {
      setExpandList(null);
    } else setExpandList(id);
  };

  const getSubscriptionList = async () => {
    try {
      const subscription = await axios.get(`${configData.config.UNO_URL}/api/subscriptions/list/${uuid}`, {
        headers: { Authorization: `Bearer ${userData.token}` },
      });
      const _subscriptionList = subscription?.data || [];
      setSubscriptionList(_subscriptionList);
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  function calculateDaysAgo(validTillDate) {
    const currentDate = new Date();
    const timeDifference = validTillDate - currentDate;
    const daysAgo = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysAgo;
  }

  const handleClick = (e) => {
    navigate(`/dashboard/subscriptions/create/${uuid}`, { state: e });
  };

  useEffect(() => {
    getSubscriptionList();
  }, []);


  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 38,
    height: 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 18,
      height: 18,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return (
    <>
      <div className="row align-items-center title-row">
        <div className="col-md-8 pagetitletext">
          <span onClick={() => navigate(-1)}>
            {' '}
            <button type={'button'} className="back">
              {' '}
              <KeyboardBackspaceIcon />{' '}
            </button>{' '}
          </span>
          <span>{groupName}</span>
        </div>
        {permissions.includes("RESOURCE.SUBSCRIPTION.CREATE") ? <div className="col-md-4 text-end">
          <button type='button' onClick={() => navigate(`/dashboard/subscriptions/create/${uuid}`,)} className="createbtn">
            Create Subscription
          </button>
        </div> : null}
      </div>

      <div className="substable">
  <>
    {loading ? (
      <>
        <div className="row align-items-center align-middle subheadlist align-middle p-1 px-2">
          <div className="col-3 p-2 ps-3">Plans</div>
          <div className="col-2 p-2 text-center">Applications</div>
          <div className="col-2 p-2 text-center">Resources</div>
          <div className="col p-2 text-center">Status</div>
          <div className="col-2 p-2 text-center">Expiring In</div>
          <div className="col p-2 text-center">
            <i className="bi-gear" />
          </div>
          <div className="col p-2">{''}</div>
        </div>
        {[...Array(5)].map((_, index) => (
          <div className="row align-items-center subchildlist mt-2 align-middle p-1 px-2" key={index}>
            <div className="col-3 p-2 ps-3">
              <Skeleton width="70%" height="1.3rem" />
            </div>
            <div className="col-2 p-2 text-center">
              <Skeleton width="70%" height="1.3rem" style={{ margin: "0 auto" }} />
            </div>
            <div className="col-2 p-2 text-center">
              <Skeleton width="70%" height="1.3rem" />
            </div>
            <div className="col p-2 text-center">
              <Skeleton width="70%" height="1.3rem" />
            </div>
            <div className="col-2 p-2 text-center">
              <Skeleton width="50%" height="1.3rem" />
            </div>
            <div className="col p-2 text-center">
              <Skeleton width="30%" height="1.3rem" />
            </div>
            <div className="col p-2">{''}</div>
          </div>
        ))}
      </>
    ) : (
      <>
        {subscriptionList?.data && subscriptionList?.data?.length > 0 ? (
          <>
            <div className="row align-items-center align-middle subheadlist align-middle p-1 px-2">
              <div className="col-3 p-2 ps-3">Plans</div>
              <div className="col-2 p-2 text-center">Applications</div>
              <div className="col-2 p-2 text-center">Resources</div>
              <div className="col p-2 text-center">Status</div>
              <div className="col-2 p-2 text-center">Expiring In</div>
              {permissions.includes("RESOURCE.SUBSCRIPTION.EDIT") && (
                <div className="col p-2 text-center">
                  <i className="bi-gear" />
                </div>
              )}
              <div className="col p-2">{''}</div>
            </div>
            <>
              {subscriptionList?.data?.map((e, index) => (
                <div key={index} className="row align-items-center subchildlist mt-2 align-middle p-1 px-2">
                  <div className="col-3 p-2 ps-3">Plan {index + 1}</div>
                  <div className="col-2 p-2 d-flex align-items-center justify-content-center">
                    <div className="subapplist">
                      {e?.applicationAllocations.length === 1 ? (
                        <>
                          <div className="appicon" key={index}>
                            <img
                              src={e?.applicationAllocations[0]?.application?.icon || ''}
                              alt=""
                              onError={(e) => {
                                e.target.src = logo;
                                e.target.onerror = null;
                              }}
                            />
                          </div>
                          {e?.applicationAllocations[0]?.application?.name}
                        </>
                      ) : (
                        <>
                          {e?.applicationAllocations.slice(0, 5).map((a, index) => (
                            <div className="appicon" key={index}>
                              <img
                                src={a?.application?.icon || ''}
                                alt=""
                                onError={(e) => {
                                  e.target.src = logo;
                                  e.target.onerror = null;
                                }}
                              />
                            </div>
                          ))}
                          {e.applicationAllocations.length > 5 && <p>+{e.applicationAllocations.length - 5}</p>}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-2 p-2 d-flex align-items-center justify-content-center">
                    <div className="subapplist resource">
                      {e?.resourceAllocations?.slice(0, 5).map((r, index) => (
                        <div className="appicon" key={index}>
                          <img
                            src={r?.resource?.versions?.[0]?.data?.thumbnail || ''}
                            alt=""
                            onError={(e) => {
                              e.target.src = logo;
                              e.target.onerror = null;
                            }}
                          />
                        </div>
                      ))}
                      {e.resourceAllocations.length > 5 && <p>+{e.resourceAllocations.length - 5}</p>}
                    </div>
                  </div>
                  <div className="col p-2 d-flex align-items-center justify-content-center">
                    {e.applicationAllocations[0]?.active ? (
                      <strong className="text-success">Active</strong>
                    ) : (
                      'Inactive'
                    )}                      
                     {/* <FormControlLabel
                        className='me-0'
                        control={<IOSSwitch sx={{ m: 1 }} checked={e.applicationAllocations[0]?.active} />}
                      /> */}
                      {/* <span className={e.applicationAllocations[0]?.active ? "active-label" : "inactive-label"}>{e.applicationAllocations[0]?.active ? "Active" : "Inactive"}</span> */}
                  </div>
                  <div className="col-2 p-2 d-flex align-items-center justify-content-center">
                    {e.validity ? (
                      <>
                        {new Date(e.validity.validTill) > new Date()
                          ? `Expires in ${calculateDaysAgo(new Date(e.validity.validTill))} days`
                          : `Expired ${calculateDaysAgo(new Date(e.validity.validTill))} days ago`}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </div>
                  {permissions.includes("RESOURCE.SUBSCRIPTION.EDIT") && (
                    <div className="col p-2 text-center" onClick={() => handleClick(e)}>
                      <i style={{ cursor: "pointer" }} className="bi-pencil-square" />
                    </div>
                  )}
                  <div className="col p-2 text-center">
                    {Expandlist === e.id ? (
                      <span className="expand-shrink" onClick={() => ListExpandBtn(e.id)}>
                        <UpDragIcon />
                      </span>
                    ) : (
                      <span className="expand-shrink" onClick={() => ListExpandBtn(e.id)}>
                        <DownDragIcon />
                      </span>
                    )}
                  </div>

                  <div className={Expandlist === e.id ? 'childtablelist active' : 'childtablelist'}>
                    <div className="col-12">
                      <table className="table align-items-center align-middle subhiddentable p-0 m-0 my-2">
                        <thead>
                          <tr>
                            <th scope="col">S.No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Type</th>
                            <th scope="col">Status</th>
                            <th scope="col">Expiring In</th>
                          </tr>
                        </thead>
                        <tbody>
                          {e.applicationAllocations.map((f, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <div className="subtablesub">
                                  <img
                                    src={f?.application?.icon || ''}
                                    alt=""
                                    onError={(e) => {
                                      e.target.src = logo;
                                      e.target.onerror = null;
                                    }}
                                  />
                                  {f?.application?.name || ''}
                                </div>
                              </td>
                              <td>Application</td>
                              <td>{f.active ? <strong className="text-success">Active</strong> : 'Inactive'}</td>
                              <td>
                                {e.validity ? (
                                  <>
                                    {new Date(e.validity.validTill) > new Date()
                                      ? `Expires in ${calculateDaysAgo(new Date(e.validity.validTill))} days`
                                      : `Expired ${calculateDaysAgo(new Date(e.validity.validTill))} days ago`}
                                  </>
                                ) : (
                                  'N/A'
                                )}
                              </td>
                            </tr>
                          ))}
                          {e.resourceAllocations.map((f, index) => (
                            <tr key={index}>
                              <td>{e.applicationAllocations.length + (index + 1)}</td>
                              <td>
                                <div className="subtablesub">
                                  <img
                                    src={f?.resource?.versions[0]?.data?.thumbnail || ''}
                                    alt=""
                                    onError={(e) => {
                                      e.target.src = logo;
                                      e.target.onerror = null;
                                    }}
                                  />
                                  {f?.resource?.name || ''}
                                </div>
                              </td>
                              <td>Module</td>
                              <td>{f.active ? <strong className="text-success">Active</strong> : 'Inactive'}</td>
                              <td>
                                {e.validity ? (
                                  <>
                                    {new Date(e.validity.validTill) > new Date()
                                      ? `Expires in ${calculateDaysAgo(new Date(e.validity.validTill))} days`
                                      : `Expired ${calculateDaysAgo(new Date(e.validity.validTill))} days ago`}
                                  </>
                                ) : (
                                  'N/A'
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </>
        ) : (
          <NoDataFound
            heading="No Subscription Yet!!"
            nodatatext="Create a subscription for this group using Create Subscription Button on top."
          />
        )}
      </>
    )}
  </>
</div>

    </>
  );
};

export default SubscriptionList;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dropdown from 'react-bootstrap/Dropdown';
import { FilterIcon } from '../../SvgIconsData';
import FilterSearch from './FilterSearch';

const Filter = ({ selectedCheckboxes, setSelectedCheckboxes, filterOptions }) => {
  
  const [showfilter, setShowFilter] = useState(false);
  const [sortingOpen, setSortingOpen] = useState(false);
  const [showFilterContent, setShowFilterContent] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const [openTabs, setOpenTabs] = useState({});
  

  const handleCheckboxChange = (event, item) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, item]);
    } else {
      setSelectedCheckboxes((prevSelected) => prevSelected.filter((selectedItem) => selectedItem !== item));
    }
  };

  const isChecked = (item) => {
    return selectedCheckboxes.includes(item);
  };

  const toggleTab = (item) => {
    setOpenTabs((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };

  const openFilter = () => {
    setShowFilter(!showfilter);
  };

  const openFilterContent = (filter) => {
    setShowFilterContent(filter);
  };

  const openSorting = () => {
    setSortingOpen(!sortingOpen);
  };

  const handleReset = () => {
    setSelectedCheckboxes([]);
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <div className="filteroption">
          <span onClick={openFilter}>
            <FilterIcon />
            Filter
          </span>
        </div>

        {showfilter && (
          <div className="filterbox position-absolute">
            <div className="filterhead">
              <p>Filter</p>
              <button onClick={handleReset}>Reset</button>
            </div>
            {filterOptions?.map((filter, index) => (
              <>
                <div key={index} className={`createdfilter {} ${openTabs[filter.name] ? 'createdactive' : ''}`}>
                  <div className="createrhead" onClick={() => toggleTab(filter.name)}>
                    <p>{filter.name}</p>
                    <p>{openTabs[filter.name] ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}</p>
                  </div>

                  {openTabs[filter.name] && (
                    <div className="createdatahere mt-2 pe-2">
                      <FilterSearch
                        placeholder="Search ..."
                        setSearchQuery={setSearchQuery}
                        searchQuery={searchQuery}
                      />
                      <FormGroup>
                        {filter.tags
                          .filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
                          .map((item, subIndex) => (
                            <>
                              <FormControlLabel
                                key={subIndex}
                                control={
                                  <Checkbox
                                    onChange={(event) => handleCheckboxChange(event, item)}
                                    checked={isChecked(item)}
                                  />
                                }
                                label={item.name}
                              />
                            </>
                          ))}
                      </FormGroup>
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>
        )}
      </div>

      {showfilter && (
        <div className="showoverlay" onClick={openFilter}>
          {''}
        </div>
      )}

      {sortingOpen && (
        <div className="showoverlay" onClick={openSorting}>
          {''}
        </div>
      )}
    </>
  );
};

export default Filter;

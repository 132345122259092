import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const PieCharts = ({ responses, item }) => {
  const optionIds = item.options.map((e) => e.id);
  const questionId = item.id;

  const optionCounts = {};
  optionIds.forEach((optionId) => {
    optionCounts[optionId] = 0;
  });

  responses.forEach((response) => {
    const optionId = response.data[questionId.toString()];
    if (optionIds.includes(parseInt(optionId, 10))) {
      optionCounts[optionId] = (optionCounts[optionId] || 0) + 1;
    }
  });

  const optionNames = item?.options.map((option) => option.name);
  const optionResponseCounts = optionIds.map((optionId) => optionCounts[optionId] || 0);

  const [series, setSeries] = useState(optionResponseCounts);

  useEffect(() => {
    setSeries(optionResponseCounts);
  }, [responses]);

  const colors = ['#58ca37', '#ca373c', '#3f51b5', '#ffc107', '#9c27b0', '#009688'];

  const chartOptions = {
    chart: {
      type: 'pie',
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: true,
          },
        },
      },
    ],
    legend: {
      position: 'right',
      offsetY: 0,
    },
    labels: optionNames,
    colors: colors.slice(0, optionNames.length),
  };

  return (
    <div id="chart" className="position-relative" style={{ marginLeft: '-25px' }}>
      <ReactApexChart options={chartOptions} series={series} type="pie" height="150px" />
    </div>
  );
};

export default PieCharts;

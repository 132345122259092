import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import GroupTypeTab from './GroupType';
import RolesTab from './Roles';
import Permissions from './Permissions';
import ProPopup from "../../layouts/ProPopup";

const RoleAccess = () => {
  const [activeTab, setActiveTab] = useState('role');
  const { uuid } = useParams();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userSlice);
  const permissions = userData.user.permissions;
  const [openPro, setOpenPro] = useState(false);
  const [userPlan, setUserPlan] = useState(null);

  
  useEffect(()=>{
    if(userData.plans.data) setUserPlan(userData.plans.data);
  },[userData]);

  return (
    <>
      <section className='rolesaccess'>
        <div className="row justify-content-between align-items-center" style={{ minHeight:"42px" }}>
          <div className="col">
            <div className='pagetitletext'>
              Role Management
            </div>
          </div>
          <div className="col text-end">
            {
              activeTab === 'role' ? (
                (!permissions.includes("TEAM.ROLES.CREATE") ? null : <button type='button' onClick={() => userPlan?.roles?.create?.role ? navigate(`/dashboard/roles/customize/${uuid}`, { state: { mode: 'PRIVATE' } }) : setOpenPro(true) } className='createbtn'>
                  Create Role
                </button>)
              ) : activeTab === 'grouptype' ? (
                (!permissions.includes("TEAM.GROUPTYPE.CREATE") ? null : <button type='button' onClick={() => userPlan?.roles?.create?.groupType ?  navigate(`/dashboard/roles/grouptype/create`) : setOpenPro(true) } className='createbtn'>
                  Create Group Type
                </button>)
              ) : activeTab === 'baselevel' ?
                (!permissions.includes("TEAM.ROLES.DEFAULT") ? null : <button type='button' onClick={() => userPlan?.roles?.create?.publicRole ?  navigate(`/dashboard/roles/customize/${uuid}`, { state: { mode: 'PUBLIC' } }) : setOpenPro(true) } className='createbtn'>
                  Create Public Role
                </button>)
                : null
            }
          </div>
        </div>

        <Tab.Container defaultActiveKey={activeTab} activeKey={activeTab} onSelect={(e) => setActiveTab(e)}>

          <div className="row mt-2">
            <div className='col-6'>
              <Nav className="tabsproduct " variant="pills">
                
                {!permissions.includes("TEAM.ROLES.VIEW") ? null :
                  <Nav.Item>
                    <Nav.Link eventKey="role">Roles</Nav.Link>
                  </Nav.Item>
                }
                
                {!permissions.includes("TEAM.GROUPTYPE.VIEW") ? null :
                  <Nav.Item>
                    <Nav.Link eventKey="grouptype">Group Types</Nav.Link>
                  </Nav.Item>
                }
                
                {!permissions.includes("TEAM.ROLES.DEFAULT") ? null :
                  <Nav.Item>
                    <Nav.Link eventKey="baselevel">Public Roles</Nav.Link>
                  </Nav.Item>
                }

                {!permissions.includes("TEAM.ROLES.PERMISSION") ? null :
                  <Nav.Item>
                    <Nav.Link eventKey="permissions">Permissions</Nav.Link>
                  </Nav.Item>
                }

              </Nav>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <Tab.Content>
                {!permissions.includes("TEAM.ROLES.VIEW") ? null :
                  <Tab.Pane eventKey="role">
                    <RolesTab mode={'PRIVATE'} permissions={permissions} userPlan={userPlan} />
                  </Tab.Pane>
                }
                {!permissions.includes("TEAM.GROUPTYPE.VIEW") ? null :
                  <Tab.Pane eventKey="grouptype">
                    <GroupTypeTab permissions={permissions} userPlan={userPlan} />
                  </Tab.Pane>
                }
                {!permissions.includes("TEAM.ROLES.DEFAULT") ? null :
                  <Tab.Pane eventKey="baselevel">
                    <RolesTab mode={'PUBLIC'} permissions={permissions} userPlan={userPlan} />
                  </Tab.Pane>
                }
                {!permissions.includes("TEAM.ROLES.PERMISSION") ? null :
                  <Tab.Pane eventKey="permissions" >
                    <Permissions userPlan={userPlan} />
                  </Tab.Pane>
                }
              </Tab.Content>
            </div>
          </div>

        </Tab.Container>

      </section >

      <ProPopup openPro={openPro} setOpenPro={setOpenPro} /> 
    </>
  )
}

export default RoleAccess

/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { BGGuys, HeartIcon } from '../../SvgIconsData';
import SimulanisApps from './SimulanisApps';
import HomeAppList from './HomeAppList';
import SearchAll from '../SearchAll';
import MyAppList from './MyAppList';
import BgImage from '../../assets/img/dashboardbg.png';
import OnLoadSection from './OnLoadSection';

const { innerWidth: width, innerHeight: height } = window;

export default function Dashboard() {
  const navigate = useNavigate();
  const params = useParams();
  const UUID = params?.uuid;

  const [search, setSearch] = useState('');
  const [list, setList] = useState([]);
  const [allTabAppLength, setAllTabAppLength] = useState({});
  const [showEndVideo, setShowEndVideo] = useState(false);
  const [showTour, setShowTour] = useState(false);
  const [activeKey, setActiveKey] = useState("myapp");
  const myTagData = useSelector((state) => state.myAppSlice);
  const configData = useSelector((state) => state.configSlice);
  const [showWalkThroughVideo, setShowWalkThroughVideo ] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line
    setShowWalkThroughVideo(configData?.config?.walkThroughVideo ? true : false )   // eslint-disable-line
  }, [configData]);

  useEffect(() => {
    if (localStorage.getItem('tour')) {
      setShowTour(false);
    } else {
      setShowTour(true);
    }
  }, []);

  useEffect(() => {
    setList([
      ...myTagData?.tagsList.filter((e) =>
        configData.config.appPermission.includes(String(e.name).toLocaleLowerCase())
      ),
    ]);

  }, [myTagData]);

  useEffect(() => {
    const messageListener = (event) => {
      if (event.data === 'endPlayer') {
        setShowEndVideo(true);
      }
    };
    window.addEventListener('message', messageListener);
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  const setAppLength = (e) =>{
        setAllTabAppLength(pre => ({...pre, ...e}))
  }

  return (
    <>
      <div className="dashboard ">
        {/* <div className='pagetitletext'>Home</div> */}
        <Tab.Container activeKey={activeKey} onSelect={handleSelect} >
          <div className="row">
            <div className="col-md-9">
              <Nav className="tabsproduct top-updated" variant="pills">

                <Nav.Item>
                  <Nav.Link eventKey="myapp">My Apps</Nav.Link>
                </Nav.Item>
               
                {configData.config?.appPermission?.includes("all") &&
                  <Nav.Item>
                    <Nav.Link eventKey="all">All</Nav.Link>
                  </Nav.Item>}

                {list.map((e, i) => (
                  <Nav.Item key={i}>
                    <Nav.Link eventKey={e?.name}> {e?.name} </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>

            <div className="col-md-3">
              <SearchAll search={search} setSearch={setSearch} placeholder="Search..." />
            </div>
          </div>

          <Tab.Content className="mt-0 ">

            <Tab.Pane eventKey="myapp">
              <MyAppList name="myapp" id="myapp" search={search} setAppLength={setAppLength} tabKey="myapp" />
            </Tab.Pane>

            <Tab.Pane eventKey="all">
              <HomeAppList name='all' id='all' search={search} setAppLength={setAppLength} tabKey="all" />
            </Tab.Pane>
            
            {list.length > 0 &&
              list.map((e, index) => (
                <Tab.Pane key={index} eventKey={e.name}>
                  <HomeAppList name={e.name} id={e.id} search={search} setAppLength={setAppLength} tabKey={e.name} />
                </Tab.Pane>
              ))}

          </Tab.Content>
        </Tab.Container>

        <div className={(allTabAppLength[activeKey] || 0) > 10 ?  "displaytext extra-tiles" :  "displaytext" }>
          <h3>Mastering Skills, Empowering Futures.</h3>
          <p>
            Made in India with <HeartIcon /> from Simulanis Solutions
          </p>
        </div>

        {showWalkThroughVideo && width > 776 && showTour && <OnLoadSection setShowTour={setShowTour} showEndVideo={showEndVideo} /> }
      
      </div>
    </>
  );
}

const {  createSlice, createAsyncThunk} = require("@reduxjs/toolkit");

const initialState={
    redirectUrl:null
}

const referrerSlice  = createSlice({
  name:"referrerSlice",
  initialState,
  reducers:{
      saveRedirectUrl:(state,action)=>{
        // state.redirectUrl = action.payload
    },
      removeRedirectUrl:(state)=>{
          state.redirectUrl = null
      }
  },

});

export const { saveRedirectUrl, removeRedirectUrl } = referrerSlice.actions;
export default referrerSlice.reducer;
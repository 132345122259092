import * as Yup from 'yup';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const noHtmlTags = /^(?!.*<.*?>).*$/;

export const createGroupValidationSchema = Yup.object().shape({
    groupname: Yup.string().trim().matches(noHtmlTags, 'Invalid group name').required('Group name is required'),
    name: Yup.string().when("createUser", {
        is: true,
        then: ()=>Yup.string().trim().matches(noHtmlTags, 'Invalid name').required('Name is required'),
        otherwise: ()=>Yup.string(),
    }),
    groupType: Yup.object().shape({
        value : Yup.number().required("Select a group type")
    }).required("Select a group type"),
    role: Yup.object().when("createUser", {
        is : true,
        then : () => Yup.object().shape({
            value : Yup.number().required("Select a Role for this user")
        }).required("Select a Role for this user"),
        otherwise : () => Yup.object()
    }),
    email: Yup.string().when(['createUser', 'loginType'], {
        is: (createUser, loginType) => createUser === true && loginType === "1",
        then: ()=>Yup.string().trim().matches(emailRegex, 'Invalid email format').required('Email is required'),
        // then: ()=>Yup.string().trim().email('Invalid email format').required('Email is required'),
        otherwise: ()=>Yup.string(),
    }),
    uid: Yup.string().when(['createUser', 'loginType'], {
        is: (createUser, loginType) => createUser === true && loginType === "2",
        then: ()=>Yup.string().trim().required('Uid is required'),
        otherwise: ()=>Yup.string(),
    }),
    password: Yup.string().when(['createUser', 'loginType'], {
        is: (createUser, loginType) => createUser === true && loginType === "2",
        then: ()=>Yup.string().trim().required('Password is required').min(8, 'Password must be at least 8 characters').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
          ),
        otherwise: ()=>Yup.string(),
    })
  })

export const editGroupValidationSchema = Yup.object().shape({
    groupname: Yup.string().trim().matches(noHtmlTags, 'Invalid group game').required('Group name is required'),
    groupType: Yup.object().shape({
        value : Yup.number().required("Select a group type")
    }).required("Select a group type")
  })

export const createUserValidationSchema = Yup.object({
    name: Yup.string().trim().matches(noHtmlTags, 'Invalid name').required('Name is required'),
    email: Yup.string().when('loginType', {
        is: (loginType) => loginType === "1",
        // then: ()=>Yup.string().trim().email('Invalid email format').required('Email is required'),
        then: ()=>Yup.string().trim().matches(emailRegex, 'Invalid email format').required('Email is required'),
        otherwise: ()=>Yup.string(),
    }),
    role : Yup.object().shape({
        value : Yup.number().required("Select a Role for this user")
    }).required("Select a Role for this user"),
    uid: Yup.string().when('loginType', {
        is: (loginType) => loginType === "2",
        then: ()=>Yup.string().trim().required('UID is required'),
        otherwise: ()=>Yup.string(),
    }),
    password: Yup.string().when('loginType', {
        is: (loginType) => loginType === "2",
        then: ()=>Yup.string().trim().required('Password is required').min(8, 'Password must be at least 8 characters').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
          ),
        otherwise: ()=>Yup.string(),
    })
});

export const editUserValidationSchema = Yup.object({
    name: Yup.string().trim().matches(noHtmlTags, 'Invalid name').required('Name is required'),
    role : Yup.object().shape({
        value : Yup.number().required("Select a Role for this user")
    }).required("Select a Role for this user"),
});
export const changeUserPasswordValidationSchema = Yup.object({
    password: Yup.string().trim().required('Password is required.').min(8, 'Password must be at least 8 characters').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      ),
    confirmPassword: Yup.string().trim().required('Confirm password is required.').min(8, 'Password must be at least 8 characters').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      ),
});
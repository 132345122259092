import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import UNOLogo from '../../assets/img/logo.png';
import { CloseIcon } from '../../SvgIconsData';

const OnLoadSection = ({ setShowTour,  showEndVideo }) => {
  const configData = useSelector((state) => state.configSlice);
  const iframeRef = useRef(null);
  const [startTour, setStartTour] = useState(false);
  const walkThroughVideo = configData.config.walkThroughVideo;

  const closeTour = () => {
    localStorage.setItem("tour", true)
    setShowTour(false);
  };

  const iframeClick = () => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage('startPlayer', '*');
    }
  };

  

  return (
    <>
    <div className='onload-section'>
        <div className='active-overlay'>
            <iframe src={walkThroughVideo} title="_" ref={iframeRef} />
            <span className='close-btn' onClick={closeTour}>
            <CloseIcon/>
            </span>
            {
                startTour ?
                null
                :
                <div className='overlay-shadow'/>
            }
        </div>
    
        {!startTour &&  <div className='info-box'>
            <img src={configData.config.logo} alt='logo'/>
           
                <h6>Welcome to {configData.config.name}!</h6>
               
           
            <p>Welcome to the product tour of {configData.config.name}, a one stop XR platform that revolutionizes collaboration, training, and skill development for your workforce.</p>
           

            <span className='main-btns'>
           
                <button onClick={() => { setStartTour(true); iframeClick(); }}>Start</button>
               
            </span>
        </div>}

        {showEndVideo &&  <div className='info-box'>
            <img src={UNOLogo} alt='logo'/>
           
                <h6>Thanks for taking the tour!</h6>
          
           
            <p>Schedule your live demo with our experts to upskill your workforce.</p>    
          

            <span className='main-btns'>
            
                <button onClick={()=>setShowTour(false)}>Explore</button>
          
            </span>
        </div>}

    </div>
    <div className="showoverlay"/>
    </>
 
  );
};

export default OnLoadSection;

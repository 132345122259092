import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProPopup from './ProPopup';

function UpgradeHelper() {
    const [openPro, setOpenPro] = useState(false);
    const navigate = useNavigate();
    const userData = useSelector((state) => state.userSlice);
    useEffect(()=>{
        setOpenPro(true);
    },[]);
    
    const setClosePopUp =(e) =>{
        navigate(`dashboard/${userData?.user?.group?.uuid}`);
        setOpenPro(e);
    }

  return (
    <>
    <ProPopup openPro={openPro} setOpenPro={(e)=>setClosePopUp(e)} />
    </>
  )
}

export default UpgradeHelper
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import LottiePlayer from "lottie-react";
import { Modal } from 'react-bootstrap';

const ConfirmBox = ({ show, onHide, message, title, handleConfirm, loading=false }) => {
    return (
        <>
            <Modal  centered show={show} onHide={onHide}>
            <Modal.Header className="popupheader delete pt-4">
                        {title}
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group pt-4 pb-5 m-0">
                                    <p className="popupheading delete p-0 m-0">{message}</p>
                                </div>
                                <div className="row">
                                    <div className="modal-footer-btn justify-content-center gap-3 mt-2 mb-2">
                                            <button className="ms-3 createbtn cancelbtn" onClick={onHide} >Cancel</button>
                                            { loading ? 
                                                <button className="loading save">
                                                    <svg viewBox="25 25 50 50">
                                                        <circle r="20" cy="50" cx="50">{""}</circle>
                                                    </svg>
                                                </button>
                                                :
                                                <button className="createbtn"onClick={()=>handleConfirm()} >Confirm</button>
                                            } 
                                    </div>
                                    
                                 </div>
                            </div>
                        </div>
                    </Modal.Body>
            </Modal>
        </>
    )
}

export default ConfirmBox

import React, { useEffect, useState } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { EditIcon, SettingIcon } from '../../../SvgIconsData';
import ResponseModal from './ResponseModal';
import FeedbackDetail from './FeedbackDetail';
import responimg from '../../../assets/img/responses.png';
import ProPopup from "../../../layouts/ProPopup";
import { CrownIcon } from '../../../assets/icons/iconshere';


const FeedBackList = () => {
  const [selectedFeedback, setSelectedFeedback] = useState(true);
  const [isChecked, setIsChecked] = useState({});
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [activeTab, setActiveTab] = useState('summary');
  const [feedBackList, setFeedBacks] = useState([]);
  const [responses, setResponses] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [typef, setTypef] = useState('Feedback');
  const [loading, setLoading] = useState(true);
  const skeletonNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const itemsPerPage = 10;

  const configData = useSelector((state) => state.configSlice);

  const userData = useSelector((state) => state.userSlice);
  const navigate = useNavigate();

  const { type } = useParams();

  const [openPro, setOpenPro] = useState(false);
  const [userPlan, setUserPlan] = useState(null);

  useEffect(()=>{
       if(userData.plans.data) setUserPlan(userData.plans.data);
   },[userData]);

  const handleChange = async (uuid) => {

    if(!userPlan?.feedbackAndSurvey?.manage){
      setOpenPro(true);
      return
    }
    const updatedFeedbackList = feedBackList.map((feedback) => {
      if (feedback.uuid === uuid) {
        feedback.status = feedback.status === 'LIVE' ? 'PAUSED' : 'LIVE';
      }
      return feedback;
    });

    setFeedBacks(updatedFeedbackList);

    const { data } = await axios.get(`${configData.config.UNO_URL}/api/feedback/change/status/${uuid}`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
 
    setFeedBacks(data.data || []);
  };

  const handleClose = () => setShow(false);
  const handleShow = (feedback) => {
    setShow(true);
    setActiveTab('summary');
    setResponses(feedback || []);
  };

  const feedbackByChannel = async () => {
    try {
      const { data } = await axios.get(`${configData.config.UNO_URL}/api/feedback/getFeedbackByChannel`, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      });
      setLoading(false);
      setFeedBacks(data.data || []);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };
  useEffect(() => {
    feedbackByChannel();
  }, [type]);

  const pageCount = Math.ceil(feedBackList.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentPageData = feedBackList.slice(offset, offset + itemsPerPage);

  const sortType = () => {
    const sortedList = [...feedBackList];
    sortedList.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.type.localeCompare(b.type);
      }
      return b.type.localeCompare(a.type);
    });
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setFeedBacks(sortedList);
  };

  return (
    <>
      <div className="feedbackfgg">
        {selectedFeedback ? (
          <div className="feedbacklist">
            <div className="card">
              <div className="card-body pt-1">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive workflowtb stickyhead">
                      <table className="table align-middle table-nowrap mb-0 p-4">
                        <thead>
                          <tr>
                            <th className="text-center">S.No.</th>
                            <th> Title</th>
                            <th className="text-center d-block">
                              Type
                              <span onClick={sortType} className="cursor-pointer">
                                <SwapVertIcon />
                              </span>
                            </th>
                            <th className="text-center" style={{ whiteSpace: 'nowrap' }}>
                              Date & time
                            </th>
                            <th className="text-center">Responses {userPlan?.feedbackAndSurvey?.viewResponse ? "" : <CrownIcon /> } </th>
                            <th className="text-center">Manage {userPlan?.feedbackAndSurvey?.manage ? "" :  <CrownIcon /> }  </th>
                            {userData?.user?.permissions?.includes("GENERAL.FEEDBACK.MANAGE") &&  <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Activity status</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <>
                              {skeletonNumbers.map((index) => (
                                <tr key={index}>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td className="text-center">
                                    <Skeleton />
                                    <Skeleton width="70px" height="12px" />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            currentPageData.map((item, index) => (
                              <tr key={item.id}>
                                <td className="text-center">{index + 1}</td>

                                <td>
                                  <div className="feedbackitleff">
                                    <OverlayTrigger placement="top" overlay={<Tooltip>{item.name}</Tooltip>}>
                                      <p className="m-0">{item.name}</p>
                                    </OverlayTrigger>
                                  </div>
                                </td>

                                <td className="text-center">
                                  <span
                                    className={` ${
                                      item.type === 'FEEDBACK'
                                        ? 'text-success bg-light-success'
                                        : 'text-danger bg-light-danger'
                                    }`}
                                  >
                                    {item.type.charAt(0).toUpperCase() + item.type.slice(1).toLowerCase()}
                                  </span>
                                </td>

                                <td className="text-center">
                                  <p className="m-0 line-height1">{moment(item.createdAt).format('DD/MM/YYYY')}</p>
                                  <span className="font-xsss m-0">{moment(item.createdAt).format('hh:mm A')}</span>
                                </td>

                                <td className="text-center">
                                      <OverlayTrigger placement="top" overlay={<Tooltip> {item?.responses?.length > 0 ? "View" : "No" } Responses</Tooltip>}>
                                        <span className="cursor-pointer" onClick={userPlan?.feedbackAndSurvey?.viewResponse ? item?.responses?.length > 0 ? () =>handleShow(item) : null : () =>setOpenPro(true) } >
                                          <MessageOutlinedIcon />({item?.responses?.length || 0})
                                        </span>
                                      </OverlayTrigger>
                                </td>

                                <td>
                                  <div className="actionbtn d-flex justify-content-center">
                                  
                                  {userData?.user?.permissions?.includes("GENERAL.FEEDBACK.MANAGE") &&   
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Edit Feedback</Tooltip>}>
                                      <div className="cursor-pointer"
                                        onClick={userPlan?.feedbackAndSurvey?.manage ? () =>
                                          navigate(`/dashboard/feedback/create/${item.uuid}`, {
                                            state: { key: 'feedbacklist', type },
                                          }) : () =>setOpenPro(true)
                                        }
                                      >
                                        <EditIcon />
                                      </div>
                                    </OverlayTrigger>
                                    }
                                     
                                    <OverlayTrigger placement="top" overlay={<Tooltip>{item?.responses?.length > 0 ? "View" : "No"} Detail</Tooltip>}>
                                      <div className="cursor-pointer colorbtn" onClick={userPlan?.feedbackAndSurvey?.manage ? item?.responses?.length > 0 ? () => navigate(`/dashboard/feedback/feedback/detail/${item.uuid}`, {  state: { key: 'feedbacklist', type } }) : null : ()=>setOpenPro(true) } >
                                        <RemoveRedEyeOutlinedIcon />
                                      </div>
                                    </OverlayTrigger>
                                   

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          <div className="text-start">
                                            <span>Users shared with: {item?.invites?.length || 0}</span> <br />
                                            <span>Total responses: {item?.responses?.length || 0}</span>
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="cursor-pointer ps-2">
                                        <img src={responimg} style={{ width: '20px' }} alt="" />
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                </td>

                                { userData?.user?.permissions?.includes("GENERAL.FEEDBACK.MANAGE") &&
                                <td>
                                  <div className="d-flex gap-2 align-items-center justify-content-center">
                                    {item.publishTime === null ? (
                                      <span style={{ color: '#d01119' }}>
                                        <strong>Draft</strong>
                                      </span>
                                    ) : (
                                      <>
                                        <span className={`text-${item.status === 'LIVE' ? 'success' : 'danger'}`}>
                                          {' '}
                                          <strong>
                                            {item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()}
                                          </strong>{' '}
                                        </span>
                                        <div className="form-check form-switch">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={item.status === 'LIVE'}
                                            onChange={() => handleChange(item.uuid)}
                                            id={item.id}
                                          />
                                        </div>
                                      </>
                                    )}
                                    {/* <span className={`text-${item.status === 'LIVE' ? 'success' : 'danger'}`}>
                                      <strong>
                                        {item.status === 'LIVE' && item.publishTime && item.expiryTime ? 'LIVE' : (
                                          <span style={{ color: 'red' }}>Unpublished</span>
                                        )}
                                      </strong>
                                    </span> */}
                                    {/* <OverlayTrigger placement="top" overlay={<Tooltip>Click to switch status</Tooltip>}>
                                      <div style={{ width: '30px' }}>
                                        {item.status === 'LIVE' || item.status === 'PAUSED' ? (
                                          item.expiryTime && item.publishTime ? (
                                            <div className="form-check form-switch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={item.status === 'LIVE'}
                                                onChange={() => handleChange(item.uuid)}
                                                id={item.id}
                                              />
                                            </div>
                                          ) : null
                                        ) : null}
                                      </div>
                                    </OverlayTrigger> */}
                                  </div>
                                </td> }

                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                      {!loading && (
                        <ReactPaginate
                          pageCount={pageCount}
                          onPageChange={({ selected }) => setCurrentPage(selected)}
                          containerClassName={'pagination'}
                          activeClassName={'active'}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <span onClick={() => setSelectedFeedback(true)} className="cursor-pointer backfg">
              <KeyboardBackspaceOutlinedIcon className="font-md" />
            </span>
            <FeedbackDetail />
          </div>
        )}

        <ResponseModal show={show} handleClose={handleClose} activeTab={activeTab} responses={responses || []} />
      </div>

      <ProPopup openPro={openPro} setOpenPro={setOpenPro} />                

    </>
  );
};

export default FeedBackList;

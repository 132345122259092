import React, { useEffect, useState } from 'react';

export const Thumbnail = ({ path, name }) => {
  const [pName, setPName] = useState('');

  const pathExists = !!path; 

  useEffect(() => {
    if (name) {
      const words = name.split(' ');
      const avatarName = words.slice(0, 2).map(word => word[0]).join('');
      setPName(avatarName.toUpperCase());
    }
  }, [name]); 

  return (
    <>
      {pathExists ? (
        <img src={path} alt="" />
      ) : (
        <p className="grpImagName11">{pName}</p>
      )}
    </>
  );
};

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from 'antd';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import FormGroup from '@mui/material/FormGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import { Lock, Public } from '@mui/icons-material';
import { is } from 'date-fns/esm/locale';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import sim from '../../../assets/img/sicon.png';
import { SelectAllIcon } from '../../../SvgIconsData';

const SelectDateTime = ({ show, handleClose, expiryTime }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentTime = new Date();
  const format = 'HH:mm a';
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showList, setShowList] = useState(false);
  const [search, setSearch] = useState('');
  const [endTimeError, setEndTimeError] = useState(false);
  const [startDate, setStartDate] = useState(moment(currentTime).format('MM-DD-YYYY'));
  const [startTime, setStartTime] = useState(moment(currentTime).format('HH:mm:ss'));
  const [endDate, setEndDate] = useState(moment(expiryTime || currentTime).format('MM-DD-YYYY'));
  const [endTime, setEndTime] = useState(moment(expiryTime || currentTime).format('HH:mm:ss'));
  const userData = useSelector((state) => state.userSlice);
  const [platformUsers, setPlatformUsers] = useState([]);
  const [groupUsers, setGroupUsers] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const configData = useSelector((state) => state.configSlice);

  const changeTimeFormate = (date, time) => {
    const startDateComponent = moment(date).format('YYYY-MM-DD');
    const startTimeComponent = moment(time, 'hh:mm A').format('HH:mm:00.000');
    const mergedDateTime = moment(
      `${startDateComponent} ${startTimeComponent}`,
      'YYYY-MM-DD HH:mm:00.000'
    ).toISOString();
    return mergedDateTime;
  };

  const { uuid } = useParams();

  const checkEndTimeValidity = (time) => {
    const selectedEndTime = moment(time, format);
    const currentTime = moment();
    return selectedEndTime.isBefore(currentTime);
  };

  const handleChangeEndTime = (time) => {
    if (time === null) {
      return;
    }

    const formattedTime = time.format('HH:mm');
    setEndTime(formattedTime);
    setEndTimeError(false);
  };

  const handleChangeStartTime = (time) => {
    if (time === null) {
      return;
    }

    const formattedTime = time.format('HH:mm');
    setStartTime(formattedTime);
  };

  const options = [
    { value: 'private', label: 'Private' },
    { value: 'public', label: 'Public' },
  ];

  const getUsers = async () => {
    try {
      const users = await axios.get(`${configData.config.UNO_URL}/api/resource/userListByChannel`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData.token}`,
        },
      });
      setUserList(users.data.data || []);
      setPlatformUsers(users.data.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGroupUsers = async () => {
    try {
      const { data } = await axios.get(`${configData.config.UNO_URL}/api/user/list/${userData.user.group.uuid}`, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      setGroupUsers(data.data);
    } catch (error) {

      console.log(error);
    }
  };

  const sendFeedBackInvitation = async () => {
    try {
      setIsSubmitting(true);
      const selectedEndTime = moment(`${endDate} ${endTime}`, 'MM-DD-YYYY HH:mm');
      const currentTime = moment();
      if (selectedEndTime.isBefore(currentTime)) {
        setEndTimeError(true);
        setIsSubmitting(false);
        return;
      }

      const data = {
        endTime: changeTimeFormate(endDate, endTime),
      };

      await axios.post(`${configData.config.UNO_URL}/api/feedback/UpdateTime/${uuid}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData.token}`,
        },
      });
      setIsSubmitting(false);
      handleClose()
      const payload = {
        // users: isChecked ? userList : selectedUsers,
        users: selectedUsers,
      };

      const response = await axios.post(
        `${configData.config.UNO_URL}/api/feedback/sendFeedBackInvites/${uuid}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
  
    } catch (error) {
      console.error('Error sending feedback invitations:', error);
    }
  };

  const handleSelectUsers = (type) => {
    setSelectedUsers([])
    if (type === 'group') {
      setSelectedUsers(groupUsers)
      setIsActive(false)
    } else if (type === 'platform') {
      setSelectedUsers(platformUsers)
      setIsActive(false)
    } else {
      setSelectedUsers([])
      setIsActive(true)
      getUsers()
      fetchGroupUsers()
    }
  }

  useEffect(() => {
    getUsers();
    fetchGroupUsers();
  }, []);

  return (
    <>
      <Modal show={show} onHide={() => { handleClose(); setSelectedUsers([]) }} className="createfeedback">
        <Modal.Header className="bg-danger p-3 px-3 text-white" closeButton>
          <Modal.Title style={{ fontSize: '1rem' }}>Select End Date/Time</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <p>End Date/Time</p>
          {/* <div className="border p-3 rounded-1 d-flex align-items-center gap-3"> */}
          {/* <div className="datepick">
              <p className="font-xss m-0">Start </p>
              <DatePicker
                minDate={new Date()}
                selected={startDate}
                onChange={(date) => setStartDate(moment(date).format('MM-DD-YYYY'))}
                dateFormat="dd/MM/YYYY"
              />
            </div> */}
          {/* <div className="border p-1 font-xss px-3 zerodays">
              <span>{daysDifference} Days</span>
            </div> */}

          {/* <div className="datepick text-end enddate">
              <p className="font-xss m-0">End </p>
              <DatePicker
                minDate={new Date()}
                selected={endDate}
                dateFormat="dd/MM/YYYY"
                onChange={(date) => setEndDate(moment(date).format('MM-DD-YYYY'))}
              />
            </div> */}
          {/* </div> */}
          <div className="row mt-3 timepicker">
            {/* <div className="col-6 col-md-6">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DesktopTimePicker']}>
                  <DemoItem>
                    <DesktopTimePicker
                      defaultValue={dayjs('2022-04-17T15:30')}
                      size="small"
                      onChange={handleChangeStartTime}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div> */}

            <div className="col-md-12">
              <div className="text-start pb-3">
                {/* <p className="font-xss m-0">Date </p> */}
                <DatePicker
                  minDate={endDate}
                  selected={endDate}
                  dateFormat="dd/MM/YYYY"
                  onChange={(date) => setEndDate(moment(date).format('MM-DD-YYYY'))}
                  className="form-control py-3"
                />
              </div>
            </div>
            <div className="col-md-12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DesktopTimePicker']}>
                  <DemoItem>
                    <DesktopTimePicker
                      defaultValue={dayjs()}
                      size="small"
                      onChange={handleChangeEndTime}
                      fullWidth
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
              {endTimeError && <p className="text-danger">*Selected end time cannot be before the current time.</p>}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mt-3">
                {/* <FormGroup className="checkboxcc">
                  <FormControlLabel
                    control={<Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />}
                    label="Available for everyone"
                  />
                </FormGroup> */}
              </div>
                <div className="filtergroupuser mt-3">
                  <div className="form-group position-relative">
                    <div className="rolelist">
                      {selectedUsers && selectedUsers.map((user, index) => (
                        <div key={index} className="userselect">
                          {user.avatar ? (
                            <img src={user.avatar} referrerPolicy={'no-referrer'} alt="" />
                          ) : (
                            <span className="sm-initials">
                              {user.name.length > 0
                                ? user.name.split(' ').length >= 2
                                  ? `${user.name.split(' ')[0][0]}${user.name.split(' ')[1][0]}`
                                  : `${user.name.split(' ')[0][0]}`
                                : ''}
                            </span>
                          )}
                          <p>{user.name}</p>
                          <span
                            onClick={() => {
                              setUserList([...userList, user]);
                              selectedUsers.splice(index, 1);
                            }}
                            className="removeuser"
                          >
                            X
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className='userselect-parent'>
                      <div className="userselect withinput">
                        <div className='d-flex align-items-center justify-content-between'>
                          <input
                            type="text"
                            placeholder="Add Users"
                            className="form-control"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onFocus={() => setShowList(true)}
                            onBlur={() => setTimeout(() => setShowList(false), 500)}
                          />

                        </div>
                        {isActive && showList ? (
                          <div className="userinputsearch">
                            <div onClick={() => handleSelectUsers('group')} className="userserachitem select-all">
                              <span><SelectAllIcon /></span>
                              <p>All My Groups Users</p>
                            </div>
                            <div onClick={() => handleSelectUsers('platform')} className="userserachitem select-all">
                              <span><SelectAllIcon /></span>
                              <p>All Platform Users</p>
                            </div>
                            {groupUsers.map((user, index) => {
                              if (search && !user.name.toLowerCase().includes(search.toLowerCase())) return null;
                              return (
                                <div
                                  key={index}
                                  className="userserachitem "
                                  onClick={() => {
                                    setSelectedUsers([...selectedUsers, user]);
                                    groupUsers.splice(index, 1);
                                    setSearch('');
                                  }}
                                >
                                  {user.avatar ? (
                                    <img src={user.avatar} alt="" referrerPolicy={'no-referrer'} />
                                  ) : (
                                    <span className="sm-initials position-relative">
                                      {user.name.length > 0
                                        ? user.name.split(' ').length >= 2
                                          ? `${user.name.split(' ')[0][0]}${user.name.split(' ')[1][0]}`
                                          : `${user.name.split(' ')[0][0]}`
                                        : ''}
                                    </span>
                                  )}
                                  <p>{user.name}</p>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                      <div className='selectall'>
                        <div className="checkbox-apple">
                          <button
                            type="button"
                            className="btn btn-danger btn-sm mt-4"
                            onClick={handleSelectUsers}
                          >
                            Clear
                          </button>
                        </div>

                      </div>
                    </div>
                    {/* <Dropdown>
                        <Dropdown.Toggle className="btn btn-danger">
                          <FilterAltOutlinedIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-2">Groups</Dropdown.Item>
                          <Dropdown.Item href="#/action-3">Users</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                  </div>
                </div>
            </div>
          </div>
          <div className="text-end">
            <button className="btn btn-danger mt-4" onClick={sendFeedBackInvitation} disabled={endTimeError}>
              {isSubmitting ? (
                <>
                  <Spinner size="sm" /> Publishing...
                </>
              ) : (
                'Publish'
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
};

export default SelectDateTime;

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoginForm } from '../sections/auth/login';
import slogo from '../assets/img/logo.png';
import unologo from '../assets/img/UNOLogo.png';
import welcomeimg1 from '../assets/img/welcomeimg1.svg';
import welcomeimg2 from '../assets/img/welcomeimg2.svg';
import { CloseIcon } from '../SvgIconsData';



export default function LoginPage() {
  const configData = useSelector((state) => state.configSlice);
  const location = useLocation();

  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (location.state) {
      setShow(location.state.setnewPassword || false);
      setTimeout(() => {
        setShow(false);
      }, 8000);
    }
  }, [location.state]);

  useEffect(() => {
    navigate('/login'); 
  }, []);

  return (
    <>
      <Helmet>
        <title> Login | Simulanis Apps</title>
      </Helmet>
      <div className="loginPage">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <img className="uno" src={unologo} alt="" />
              <div className="wecomfimgg d-flex">
                <img src={welcomeimg2} alt="" />
                <img src={welcomeimg1} alt="" />
              </div>
            </div>

            <div className="col-md-5">
              <div className="card" style={{ position: 'relative' }}>
                {show ? (
                  <>
                    <span className="password-change-alert">
                      {' '}
                      New password set successfully.
                      <span
                        className="close-alert"
                        onClick={() => {
                          setShow(false);
                          navigate('/login');
                        }}
                      >
                        <CloseIcon />
                      </span>
                    </span>
                  </>
                ) : null}
                <div className="card-body p-4">
                  <img className="slogo" src={configData.config.logo} alt="" />
                  <h5 className="pt-2">Welcome to {configData.config.name}! </h5>
                  <p className="mb-1">Please sign-in to your account and start learning</p>
                  {<LoginForm />}
                  {/* { configData.config.emailLogin && <LoginForm /> } */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/* eslint-disable */
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as htmlToImage from "html-to-image";
import moment from "moment"; 

export async function exportMultipleChartsToPdf(tableData ) {
  const doc = new jsPDF("p", "px");
  const elements = document.getElementsByClassName("custom-chart");
  await createPdf({ doc, elements,tableData  });
  const fileName = `Feedback_Survey__Management_Analytics_${moment().format("MMMM Do YYYY, h:mm:ss a")}.pdf`;
  doc.save(fileName);
  const blob = new Blob([doc.output('blob')], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);
  window.open(url, '_blank');
  window.URL.revokeObjectURL(url);
}


async function createPdf({ doc, elements,tableData  }) {
  const padding = 20;
  const marginTop = 20;
  let top = marginTop;

  addTable(doc, tableData, top)

  top += calculateTableHeight(tableData) + marginTop;
  
  for (let i = 0; i < elements.length; i++) {
    const el = elements[i];
    
    try {
      const imgData = await htmlToImage.toPng(el, { quality: 1.0, pixelRatio: 2 });

      let elHeight = el.offsetHeight;
      let elWidth = el.offsetWidth;

      const pageWidth = doc.internal.pageSize.getWidth();

      if (elWidth > pageWidth) {
        const ratio = pageWidth / elWidth;
        elHeight = elHeight * ratio - padding * 2;
        elWidth = elWidth * ratio - padding * 2;
      }

      const pageHeight = doc.internal.pageSize.getHeight();

      if (top + elHeight > pageHeight) {
        doc.addPage();
        top = marginTop;
      }

      const xPosition = (pageWidth - elWidth) / 2;

      doc.addImage(imgData, "PNG", xPosition, top, elWidth, elHeight, `image${i}`);
      top += elHeight + marginTop;
    } catch (error) {
      console.error("Error generating image:", error);
    }
  }
} 

function addTable(doc, tableData, startY) {
  doc.autoTable({
    startY,
    head:[tableData[0]],
    body: tableData.slice(1),

  });
}

function calculateTableHeight(tableData) {
  const rowHeight = 20;
  return (tableData.length - 1) * rowHeight;
}

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import CreateSubscription from './createsubs';
import Resources from './resources';
import SubValidity from './subvalidity';
import ValidityForm from './validityform';
import AlertBox from '../AlertBox';
import FullScreenLoader from '../../components/loaders/FullScreenLoader';

const Subscription = () => {
  const navigate = useNavigate();

  const [list, setList] = useState([]);

  const [subscriptionId, setSubcriptionId] = useState(null);

  const [loading, setloading] = useState(true)

  const [myapps, setMyapps] = useState([]);

  const [selectedResources, setSelectedResources] = useState([]);

  const [combinedData, setCombinedData] = useState([]);

  const [step, setStep] = useState(0);

  const [show, setShow] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const [endSubscriptionDate, setSubscriptionDate] = useState();

  const [endSubscrionDateEdit, setSubscriptionDateEdit] = useState();

  const [visible, setVisible] = useState(false);

  const params = useParams();

  const uuid = params?.uuid;

  const location = useLocation();

  const configData = useSelector((state) => state.configSlice);

  const applicationsUuids = combinedData?.map((obj) => obj.uuid);

  const resourcesUuids = selectedResources?.map((obj) => obj.uuid);

  const userData = useSelector((state) => state.userSlice);

  const myAppData = useSelector((state) => state.myAppSlice);

  const showVisible = () => {
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
      navigate(`/dashboard/subscriptions/list/${uuid}`)
    }, 1000)
  };

  const handleDateToogle = async () => {
    let response;
    try {
      const payload = {
        applications: applicationsUuids,
        resources: resourcesUuids,
        validTill: endSubscriptionDate,
      };
      if (subscriptionId) {
        response = await axios.post(
          `${configData.config.UNO_URL}/api/subscriptions/update/${uuid}/${subscriptionId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${userData.token}`,
            },
          }
        );
      } else {
        response = await axios.post(`${configData.config.UNO_URL}/api/subscriptions/create/${uuid}`, payload, {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        });
      }

      if (response.status === 200) {
        const responseData = response.data;
        showVisible();

      } else {
        console.error('API request failed');
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const toggleItem = (item) => {
    setCombinedData(prevState => prevState.includes(item) ? prevState.filter((e) => e.id !== item.id) : [...prevState, item]) 
  };

  const toggleResources = async (item) => {
    setSelectedResources(prevState => prevState.includes(item) ? prevState.filter((e) => e.id !== item.id) : [...prevState, item]);
  }

  const groupedData = {};

  myapps?.forEach((item) => {
    item.tags.forEach((tag) => {
      const tagName = tag.name;
      if (!groupedData[tagName]) {
        groupedData[tagName] = [];
      }
      groupedData[tagName].push(item);
    });
  });

  const resultArray = Object.keys(groupedData).map((tagName) => ({
    tag: tagName,
    items: groupedData[tagName],
  }));

  const steps = [
    {
      title: 'Create Subscription',
      component: (
        <CreateSubscription
          myapps={myapps}
          toggleItem={toggleItem}
          combinedData={combinedData}
          resultArray={resultArray}
        />
      ),
      validation: () => true,
    },
    {
      title: 'Resources',
      component: (
        <Resources
          combinedData={combinedData}
          selectedResources={selectedResources}
          setSelectedResources={setSelectedResources}
          toggleResources={toggleResources}
          setStep={setStep}
          step={step}
        />
      ),
      validation: () => true,
    },
    {
      title: 'Subscription Validity',
      component: <SubValidity combinedData={combinedData} selectedResources={selectedResources} />,
      validation: () => true,
    },
  ];

  const handleNext = () => {
    const currentStep = steps[step];
    if (currentStep.validation()) {
      if (step < steps.length - 1) {
        setStep(step + 1);
      }
    }
  };
  const handleBack = () => {
    if (selectedResources.length === 0 && step === 2) {
      setStep(0);
    } else {
      const currentStep = steps[step];
      if (currentStep.validation()) {
        if (step > 0) {
          setStep(step - 1);
        }
      }
    }
  };

  useEffect(() => {
    if (myAppData?.appsList.length > 0) {
      setloading(false);
      setMyapps(myAppData?.appsList || []);
    }

  }, [myAppData]);

  useEffect(() => {
    if (location.state) {
      const data = location.state;
      setSubcriptionId(data.uuid);
      const applicationIds = data.applicationAllocations.map((e) => e.application.uuid);
      setCombinedData(myapps.filter((e) => applicationIds.includes(e.uuid)));
      setSubscriptionDateEdit(data.validity.validTill);
      setloading(false);
    }
  }, [myapps]);

  if (loading) return <FullScreenLoader loading={loading} />;

  return (
    <div>
      {steps[step].component}

      <div className="row mt-0">
        <div className="col-12 mt-5 text-end">
          {step > 0 && (
            <button className="createbtn cancelbtn me-3" onClick={handleBack}>
              Previous
            </button>
          )}
          <button onClick={handleNext} className="createbtn">
            {step === steps.length - 1 ? <div onClick={handleShow}>Validity</div> : 'Next'}
          </button>
        </div>
      </div>

      <ValidityForm
        show={show}
        onHide={handleClose}
        setSubscriptionDate={setSubscriptionDate}
        handleDateToogle={handleDateToogle}
        endSubscrionDateEdit={endSubscrionDateEdit}
      />
      
      <AlertBox
        mainmsg={subscriptionId ? 'Subscription Updated Sucessfully' : 'Subsciption Created Succesfully'}
        show={visible}
        onHide={() => setVisible(false)}
      />
    </div>
  );
};

export default Subscription;

const { createSlice } = require("@reduxjs/toolkit");

const initialState={
    appsList:[],
    tagsList:[]
}

const myAppSlice  = createSlice({
  name:"myAppSlice",
  initialState,
  reducers:{
    myApplicationList:(state, action)=>{
        state.appsList = action.payload;
        },
    myTagsList:(state, action)=>{
        state.tagsList = action.payload;
          }
          
        }
});

export const { myApplicationList , myTagsList } = myAppSlice.actions;
export default myAppSlice.reducer;
import React, { useState, useEffect } from 'react';
import logo from '../../../assets/img/logo.png';

const ResourceList = ({ handleSelectResource, selectedResources, list, handleSelectAllResource, activeApp }) => {


  const checkBoxStatus = list.every((e) => selectedResources.some((f) => f.uuid === e.uuid));

  return (
    <div className="row mt-0">
          {list.length > 0 &&
            <div className='select-all'>
              <label className="form-check-label" htmlFor={`flexCheckDefault_${activeApp}`}>
                <input checked={checkBoxStatus} className="form-check-input" type="checkbox" id={`flexCheckDefault_${activeApp}`} onChange={()=>handleSelectAllResource(checkBoxStatus)} />
                 {checkBoxStatus ? "Unselect" : "Select"} all
              </label>
            </div> }

      {list?.map((e) => {
        return (
          <div className={`col-md-3 py-3 `} key={e.id}>
            <div className={`Appbox ${selectedResources.some((f) => f.uuid === e.uuid) ? 'selected' : ''}`}
              onClick={() => handleSelectResource(e)} >
              <div className="appthumb">
                <img
                  src={e.versions[0].data.thumbnail || ''}
                  alt=""
                  onError={(e) => {
                    e.target.src = logo;
                    e.target.onerror = null;
                  }}
                />
                <div className="forselected">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi-check-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                </div>
              </div>

              <div className="appdata">
                <div className="apptitle">
                  <p>{e.name}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ResourceList;

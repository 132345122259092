import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AssignIcon, CheckedIcon, DeleteIcon } from '../../../SvgIconsData';
import logo from '../../../assets/img/logo.png';
import NoDataFound from '../../NoDataFound';

const ResourceList = ({
  resources,
  toggleResource,
  selectedResources,
  handleSelectAllResource
}) => {


  const checkBoxStatus = resources.every((e) => selectedResources.some((f) => f.uuid === e.uuid));

  return (
    <div className="row mt-0">

        <div className='select-all'>
          <label className="form-check-label" htmlFor="flexCheckDefault">
            <input checked={checkBoxStatus} className="form-check-input" type="checkbox" id="flexCheckDefault" onChange={()=>handleSelectAllResource(checkBoxStatus) } />
            {checkBoxStatus ? "Unselect" : "Select"} all
          </label>
        </div>

      {resources?.map((e) => {
        return (
          <div className={`col-md-4 py-2 `} key={e.id}>
            <div className={`Appbox atassign ${selectedResources?.includes(e) ? 'selected' : ''}`}>
              <div className="appthumb" onClick={() => toggleResource(e)}>
                <img
                  src={e?.versions[0]?.data?.thumbnail || ''}
                  alt=""
                  onError={(e) => {
                    e.target.src = logo;
                    e.onerror = null;
                  }}
                />

                <div className="forselected">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                </div>
              </div>

              <div className="appdata" onClick={() => toggleResource(e)}>
                <div className="apptitle">
                  <p>{e.name}</p>
                </div>
              </div>

              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <div className="options">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-three-dots-vertical"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="sidebartopbar modulelistoptions">
                  <div className="listoptionsmodule">
                    <span onClick={() => toggleResource(e)}>
                      <AssignIcon />
                      Unassign
                    </span>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ResourceList;

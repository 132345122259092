import React, { useEffect, useState } from 'react'
import { Tooltip } from '@mui/material';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Fade from '@mui/material/Fade';
import { EditIcon, PassKeyIcon } from '../../SvgIconsData';
import { Thumbnail } from '../thumbnail';
import EditCreateGroup from './EditCreateGroup';
import EditUsers from './editUsers';
import ConfirmBox from './ConfirmBox'; 
import ChangeUsersPassword from './ChangeUsersPassword';
import MoveProfile from './MoveProfile';

const TeamGroupDetail = ({ type, detail, refresh, permissions }) => {
    const configData = useSelector((state) => state.configSlice);
    const groupTypes = configData?.groupTypes || [];
    const userData = useSelector((state) => state.userSlice);
    const [editGroupModal, setEditGroupModal] = useState(false);
    const [editUserModal, setEditUserModal] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [moveProfile, setMoveProfile] = useState(false)
    const openChangePasswordModal = () => {
        setChangePasswordModal(true);
    };
    const closeChangePasswordModal = () => {
        setChangePasswordModal(false);
    };
    const openEditUserModal = () => {
        setEditUserModal(true);
    };
    const closeEditUserModal = () => {
        setEditUserModal(false);
    };
    const openEditGroupModal = () => {
        setEditGroupModal(true);
    };
    const closeEditGroupModal = () => {
        setEditGroupModal(false);
    };
    const openResetPasswordLinkModal = () =>{
        setResetPasswordModal(true);
    };
    const closeResetPasswordLinkModal = () =>{
        setResetPasswordModal(false);
    };


    const generateResetPasswordLink = async() =>{
          try {
            setLoading(true);
            const {data} = await axios.post(`${configData.config.UNO_URL}/api/auth/resetPassword/${detail.uuid}`, {} );
        } catch (error) {
            console.log("error");
            console.log(error);
        }finally{
            closeResetPasswordLinkModal();
            setLoading(false);
        }
    }

    const roleallocation = detail?.roleAllocations ? detail?.roleAllocations?.length > 1 ? detail.roleAllocations.filter(e => e.role.type !== 'DEFAULT')[0] : detail.roleAllocations[0] : null;
    const role = roleallocation?.role ? roleallocation.role : null;
    // const groupType = groupTypes.filter(e => e.id === detail?.typeId );

    return (
        <>
            {type === "group" ?
                <div className="groupdetail h-100 pe-0">
                    <div className="row w-100">
                        <div className="col-12 pe-0">
                            <div className='d-flex align-items-center gap-3'>
                                <div className="businesslogo">
                                    <div className='businesslogoimg'>
                                        <Thumbnail path={detail?.avatar || ""} name={detail?.name || ""} />
                                        {detail.active ?
                                            <Tooltip arrow TransitionComponent={Fade} title="Active">
                                            <span className='userActive'>{""}</span>
                                            </Tooltip>
                                                :
                                            <Tooltip arrow TransitionComponent={Fade} title="Inactive">
                                            <span className='userInactive'>{""}</span>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                                <div className="bdetail">
                                    <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='m-0 ps-1 pb-2 '> {detail?.name} </h5>
                                    <div className='d-flex align-items-center justify-content-between pb-2'>
                                    {!permissions.includes("TEAM.GROUP.CREATE") ? null :
                                    <>
                                     <span className='cursor-pointer reset passkey' onClick={openEditGroupModal}> Edit Group </span>
                                     {/* <div className='separate'>|</div> */}
                                     </>
                                     }
                                    {/* <span className='cursor-pointer reset passkey' onClick={()=>setMoveProfile(true)}> Move Group </span> */}
                                    </div>
                                    </div>
                                    <div className='information-group  pt-0'>
                                        <span>
                                            <p  style={{ padding: "2px 10px", fontWeight:"500", borderRadius:"5px", backgroundColor : `${detail.type.color}10` , color : detail.type.color}} > {detail.type?.name} </p>
                                            {/* <p  style={{ padding: "2px 10px", fontWeight:"500", borderRadius:"5px" }}> {groupType.length ? groupType[0].name : null} </p> */}
                                        </span>
                                        <span>
                                            <p className='desig' > {detail?.groups} Groups | {detail?.users} Users </p>
                                        </span>
                                       

                                        {/* { detail.active ? <p className='active-status'> Active </p> : <p className='inactive-status'> Inactive </p> } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="groupdetail pe-0">
                    <div className="row w-100 pe-0">
                        <div className="col-12 pe-0">
                            <div className='d-flex align-items-center gap-3'>
                                <div className="businesslogo user">
                                    <div className='businesslogoimg'>
                                        <Thumbnail path={detail?.avatar || ""} name={detail?.name || ""} />
                                        {detail.active ?
                                            <Tooltip arrow TransitionComponent={Fade} title="Active">
                                            <span className='userActive'>{""}</span>
                                            </Tooltip>
                                                :
                                            <Tooltip arrow TransitionComponent={Fade} title="Inactive">
                                            <span className='userInactive'>{""}</span>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>

                                <div className="bdetail">
                                    <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='m-0 ps-1 pb-2'>{detail?.name} </h5>
                                  
                                    <div className='d-flex align-items-center justify-content-between pb-2'>
                                     {!permissions.includes("TEAM.USERS.CREATE") ?
                                     null : 
                                     <>
                                     
                                     <span className='cursor-pointer reset passkey' onClick={openEditUserModal}> Edit Profile </span>
                                    
                                     {/* <div className='separate'>|</div> */}
                                     </>
                                     }
                                      {/* <span className='cursor-pointer reset passkey' onClick={()=>setMoveProfile(true)}> Move User </span> */}
                                    {userData?.user?.permissions?.includes("TEAM.USERS.CHANGE.PASSWORD") &&
                                        <>
                                        <div className='separate'>|</div>
                                        {(detail.email && detail.email.length) ?  
                                        <span className='cursor-pointer reset passkey' onClick={openResetPasswordLinkModal}> Reset Password </span> 
                                        :
                                        <span className='cursor-pointer reset passkey' onClick={openChangePasswordModal} > Change Password </span> 
                                        }
                                        </>
                                     }
                                     </div>
                                  
                                    </div>
                                  
                                    
                                    <div className='information-group for-user pt-0 '>
                                        <span>
                                           {role && <p style={{ padding: "2px 10px", fontWeight:"500", borderRadius:"5px", backgroundColor : `${role.color}10` , color : role.color}} > {role?.name} </p>}
                                            {/* <p style={{ padding: "2px 10px", fontWeight:"500", borderRadius:"5px" }}> {role?.name} </p> */}
                                        </span>
                                        {detail.designation && <span>
                                            <p className='desig'> {detail?.designation} </p>
                                        </span> }
                                        {detail.email && <span>
                                            <p className='desig'> {detail?.email} </p>
                                        </span> }
                                        {detail.uid && <span>
                                            <p className='desig'> {detail?.uid} </p>
                                        </span> }

                                        {/* <span>
                                            { detail.active ? <p className='active-status'> Active </p> : <p className='inactive-status'> Inactive </p> }
                                        </span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {editGroupModal && <EditCreateGroup refresh={refresh} detail={detail} showEditGroup={openEditGroupModal} closeEditGroupModal={closeEditGroupModal} />}
            {editUserModal && <EditUsers refresh={refresh} showUser={editUserModal} CreateUserModalClose={closeEditUserModal} editUserData={detail} />}
            {changePasswordModal && <ChangeUsersPassword showUser={changePasswordModal} closeChangePasswordModal={closeChangePasswordModal} id={detail.uuid} />}
            <ConfirmBox
                title={"Reset password"}
                message={<div>Would you like to generate a password reset link for this user? <br /> The link will be sent to the email address registered to their account.</div>}
                show={resetPasswordModal}
                loading={loading}
                onHide={closeResetPasswordLinkModal}
                handleConfirm={generateResetPasswordLink}
            />
            <MoveProfile moveProfile={moveProfile} setMoveProfile={setMoveProfile} /> 
        
        
        
        </>
    )
}

export default TeamGroupDetail

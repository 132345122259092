import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import LottiePlayer from "lottie-react";
import { CheckIcon, CloseIcon } from '../SvgIconsData'
import homeAnimation from '../animations/home.json';
import CheckAnimation from '../animations/CheckAnimation.json';


const  AlertBox = ({mainmsg, show, onHide}) => {
    return (
        <>
        
        <Modal style={{ border:"none" }} className='alertmodal' centered show={show} onHide={onHide}>
            
            <div className='AlertBox'>
            <div>
                <LottiePlayer
                    animationData={CheckAnimation}
                    style={{ height: 150, marginBottom: 0 }}
                    // autoplay = {false}
                    loop = {false}
                    
                />
            </div>
            <div className='texthere'>
                <h5>{mainmsg}</h5>
                {/* <h7>Your File has been uploaded.</h7> */}
            </div>
            <div className='close' onClick={onHide} >
                <CloseIcon />
            </div>
            </div>
    
        </Modal>
        </>
    )
}
export default AlertBox
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';
import UserBoxLoad from './UserBoxLoad';

const TeamManagementLoadComp = () => {
    
    const location = useLocation();
    const [userTab, setUserTab] = useState("user");
    useEffect(() => {
        const  path = location.pathname;
        let active = 'user';
        if (path.startsWith('/dashboard/team-management/user')) active = 'user';
        else if (path.startsWith('/dashboard/team-management')) active = 'group';
        setUserTab(active);
      }, [location.pathname]);


    return (
    <section>
        <div className="row align-items-center mb-4">
            <div className="col pagetitletext mt-2">
                <span>
                    <Skeleton 
                        width="30%" 
                        style={{ lineHeight: "normal" }} 
                        baseColor="#2f3a7900" 
                        highlightColor="#fff" 
                    />
                </span>
            </div>
        </div>
        <div className="groupdetail " style={{ marginTop:"33px" }}>
                    <div className="row w-100 ">
                        <div className="col-12 col-md-9 ">
                            <div className='d-flex align-items-center'>
                                <div className="businesslogo">
                                    <div className='businesslogoimg'>
                                    <Skeleton 
                                        width="100%" 
                                        height="100%"
                                        circle
                                        style={{ lineHeight: "normal" }} 
                                        baseColor="#2f3a7900" 
                                        highlightColor="#fff" 
                                    />
                                    </div>
                                </div>
                                <div className="bdetail w-100 ps-3">
                                  
                                    <Skeleton width="20vw" highlightColor='#ffffff' baseColor='#f3f3f3' />
                                  
                                    <p className='pt-2'>
                                    <Skeleton width="30vw" highlightColor='#ffffff' baseColor='#f3f3f3'/>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-5'>
                { userTab === "group" ?
                <UserBoxLoad/>
                :
                null
                }
                </div>
    </section>
    )
};

export default TeamManagementLoadComp

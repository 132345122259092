import { configureStore } from "@reduxjs/toolkit";
import configSlice from "./slice/configSlice";
import userSlice from "./slice/userSlice";
import referrerSlice from "./slice/referrerSlice";
import myAppSlice from "./slice/myAppSlice";
import moduleFilterSlice from "./slice/moduleFilter";

export const store = configureStore({
    reducer:{
        configSlice,
        userSlice,
        referrerSlice,
        myAppSlice,
        moduleFilterSlice
    }
});

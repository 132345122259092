import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabsCard from './tabsCard';
import AllCard from './allCard';
import SearchAll from '../SearchAll';
import Filter from '../filter/Filter';

const CreateSubscription = ({ myapps, toggleItem, combinedData, resultArray }) => {

  const navigate = useNavigate();

  const[search,setSearch]=useState("")

  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-8 pagetitletext">
          <span onClick={() => navigate(-1)}>
            {' '}
            <button className="back">
              {' '}
              <KeyboardBackspaceIcon />{' '}
            </button>{' '}
          </span>
          Simulanis Solutions
        </div>
        <div className="col-md-4">
          <div className="d-flex justify-content-end">
            <SearchAll search={search} setSearch={setSearch} placeholder={"Search Applications"}/>
            {/* <div className="ms-2">
              <Filter />
            </div> */}
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 filterhere">
          {myapps?.length > 0 ? (
            <Tabs defaultActiveKey="all">
              <Tab eventKey="all" title="All">
                <div className="row">
                  <AllCard myapps={myapps} toggleItem={toggleItem} combinedData={combinedData}  search={search}/>
                </div>
              </Tab>

              {resultArray?.map((e, index) => {
                return (
                  <Tab eventKey={`${e?.tag}`} key={index} title={`${e?.tag}`}>
                    <div className="row">
                      
                      <TabsCard
                        name={e.name}
                        id={e?.id}
                        myapps={myapps}
                        data={e ?.items}
                        toggleItem={toggleItem}
                        combinedData={combinedData}
                        resultArray={resultArray}
                        search={search}
                      />

                    </div>
                  </Tab>
                );
              })}
            </Tabs>
          ) : null}
          
        </div>
      </div>
      {/* <div className="row mt-0">
        <div className="col-12 mt-5 text-end">
          <button className="createbtn cancelbtn me-3">Cancel</button>
          <button onClick={handleNavigate} className="createbtn">
            Next
          </button>
        </div>
      </div> */}
    </>
  );
};

export default CreateSubscription;

import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import MenuItems from './SidebarData';

const SideBar = ({ sideBarAddRemoveClass, menuToggle }) => {
  const userData = useSelector((state) => state.userSlice);
  const currentLocation = useLocation();
  const navigate = useNavigate();

  const permissions = userData.user.permissions;
  const goToTab = (e) => {
      navigate(`${e.url}/${userData?.user?.group?.uuid}`);
      const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      menuToggle();
    }
  };

  return (
    <div className={`sidebar ${sideBarAddRemoveClass}`}>
      <ul>
        {MenuItems.map((e) =>
          e.permission && !permissions.includes(e.permission) ? null : (
            <li key={e.id}>
              <div onClick={() => goToTab(e)}>
                <a className={shouldActivateItem(e.url, currentLocation.pathname)}>
                  <span>{e.icon}</span>
                  <span>{e.menuName}</span>
                </a>
              </div>
            </li>
          )
        )}
      </ul>

      {/* <button className='prd-tour'>Product <br/> Tour</button> */}
    </div>
  );
};

function shouldActivateItem(menuUrl, currentPath) {
  return currentPath.startsWith(menuUrl) ? 'active' : '';
}

export default SideBar;

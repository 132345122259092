import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import jwtEncode from 'jwt-encode';
import { useGoogleLogin } from '@react-oauth/google';
import { saveUserData } from '../../../store/slice/userSlice';
import { loginValidationSchema } from './validation';
import { WarningIcon } from '../../../SvgIconsData';

const SetNewPasswordForm = () => {
  const configData = useSelector((state) => state.configSlice);
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [error, setError] = useState(null);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const link = new URLSearchParams(location.search).get('link');

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
        if (!isOnline) {
          setError('Please check your connection and try again.');
          return
        }

        // if(!verified){
        //   setError('Please check the reCAPTCHA.');
        //   return
        // }

        setError(null);
        try {
          const data = {
            link,
            password: values.password,
            confirmPassword: values.confirmPassword,
          };
          setLoading(true);
          const response = await axios.post(
            `${configData.config.UNO_URL}/api/auth/verifyForgotPasswordLink/${configData.config.applicationId}`,
            data
          );

          if (response.status === 200) {
            navigate('/login?show', { state: { setnewPassword: true } });
          }
          setError(error?.response?.data?.message);
          console.log('error');
          setLoading(false);
        } catch (error) {
          setError(error?.response?.data?.message);
          setLoading(false);
        }
        // finally{
        //   setVerified(false);
        //   recaptchaRef.current.reset();
        // }

    },
  });

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        formik.handleSubmit();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // const handleRecaptchaClick = () => {
  //   setVerified(true);
  // };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <div className="form-group">
            <div className="d-flex justify-content-between">
              <p className="mb-1"> Set Password* </p>
            </div>
            <TextField
              name="password"
              fullWidth
              placeholder="Password"
              size="sm"
              defaultValue={formik.values.password}
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => {
                formik.setFieldValue('password', e.target.value);
                setError(null);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              autoComplete="off"
            />
            <span className="login-error"> {formik.touched.password && formik.errors.password}</span>
          </div>
          <div className="form-group">
            <div className="d-flex justify-content-between">
              <p className="mb-1">Confirm Password*</p>
            </div>
            <TextField
              name="password"
              fullWidth
              placeholder="Confirm Password"
              size="sm"
              defaultValue={formik.values.confirmPassword}
              type='text'
              onChange={(e) => {
                formik.setFieldValue('confirmPassword', e.target.value);
                setError(null);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.confirmPassword)}
              autoComplete="off"
            />
          </div>
        </Stack>
       <hr/>
        {/* <div className='mt-3' >
          <ReCAPTCHA ref={recaptchaRef} sitekey={configData.config.captcha} onChange={handleRecaptchaClick} />
        </div> */}

        <span className="login-error">
          {(formik.touched.confirmPassword && formik.errors.confirmPassword) || error}
        </span>

        <button className="reset-page-btn mt-3" size="large" type="submit" color="secondary">
          {loading ? (
            <>
              <div className="loader-btn">
                <span className="bar" />
                <span className="bar" />
                <span className="bar" />
              </div>
            </>
          ) : (
            <> Set password</>
          )}
        </button>
      </form>
    </>
  );
};

export default SetNewPasswordForm;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import logo from '../../../assets/img/sicon.png';
import ResourceList from './ResourceList';

const ResourceAppList = ({ toggleApplication, applications, selectApp, search, resources, toggleResource, selectedResources, viewResources, setViewResources, handleSelectAllResource  }) => {

  const configData = useSelector((state) => state.configSlice);

  return (

    <div className="row">

      {viewResources ? <> <ResourceList
        resources={resources}
        toggleResource={toggleResource}
        selectedResources={selectedResources}
        handleSelectAllResource={handleSelectAllResource}
      />
      </> : <>
        <div className="row validity">
          {applications?.map((item, index) => {
            if (item.uuid === configData.config.applicationId) return null;
            if (search && !item.name?.toLowerCase()?.includes(search?.toLowerCase())) {
              return null;
            }
            return (
              <div className="col-md-4 py-2" key={index}>
                <div className={`Appbox ${selectApp.includes(item) ? 'selected' : ''}`}>
                  <div className="appthumb" onClick={() => toggleApplication(item)}>
                    <img
                      src={item.icon || ''}
                      alt=""
                      onError={(e) => {
                        e.target.src = logo;
                        e.target.onerror = null;
                      }}
                    />
                    <div className="forselected">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-check-lg"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022" />
                      </svg>
                    </div>
                  </div>
                  <div className="appdata">
                    <div className="apptitle withresource">
                      <span onClick={() => toggleApplication(item)}>{item.name}</span>
                      {item.resourceType[0] != null ? <>
                        {selectApp.length > 0 ?
                          <> </> : <> <span onClick={() => setViewResources(item)}> View Resources</span></>
                        }
                      </> : (
                        <>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
      }
    </div>
  );
};

export default ResourceAppList;

import React, { useState, useEffect } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const MultipleChoice = ({ options, setOptions }) => {
  
    useEffect(() => {

        const updatedOptions = options.map((option, index) => ({
            ...option,
            
        }));
        setOptions(updatedOptions || []); 
    }, []);

    const addOption = () => {
        if (options.length < 4) {
            const nextLetter = String.fromCharCode(options.length + 97);
            const newOption = { id: options.length + 1, letter: nextLetter, name: '' };
            setOptions([...options, newOption] );
        }
    };

    const removeOption = (id) => {
        if (options.length > 1) {
            const removableOptions = options.filter(option => option.removable !== false);
            if(removableOptions.length > 1){
              setOptions(options.filter(option => option.id !== id));
            }
        }
    };

    const handleChange = (id, newText) => {
        setOptions(options.map(option => {
            if (option.id === id) {
                return { ...option, name: newText };
            }
            return option;
        }));
    };

    return (
        <>
            {options?.map(option => (
                <div className="row mt-2" key={option.id}>
                    <div className="col-1">
                        <p className='text-end'>{String.fromCharCode(option.id + 96)}.</p>
                    </div>
                    <div className="col-10">
                        <input
                            type="text"
                            placeholder='Enter an answer choice'
                            className='form-control'
                            value={option.name}
                            onChange={(e) => handleChange(option.id, e.target.value)}
                        />
                    </div>
                    <div className="col-1">
                        <div className='d-flex pmbtnf'>
                            <button onClick={addOption}><AddCircleOutlineIcon /></button>
                            <button onClick={() => removeOption(option.id)}><RemoveCircleOutlineIcon /></button>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default MultipleChoice;

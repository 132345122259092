import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { useFormik } from 'formik';
import axios from 'axios';
import { loginValidationSchema } from './validation';
import Iconify from '../../../components/iconify';

const LDAP = ({ showError, handleSuccess, loading, setLoading }) => {
  const configData = useSelector((state) => state.configSlice);
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      const bodyData = {
        email: values.email.trim(),
        password: values.password,
      };
      setLoading(true);
      try {
        const { data } = await axios.post(
          `${configData.config.UNO_URL}/api/auth/login/ldap/${configData.config.applicationId}`,
          bodyData
        );
        const userData = {
          token: data.data.token,
          user: data.data.user,
        };
        handleSuccess(userData);
      } catch (error) {
        showError(error.response.data.message);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        formik.handleSubmit();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  //
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <div className="form-group">
          <p className="mb-1">Email*</p>
          <TextField
            name="email"
            fullWidth
            placeholder="Email address"
            size="sm"
            defaultValue={formik.values.email}
            onChange={(e) => formik.setFieldValue('email', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            autoComplete="off"
          />
        </div>

        <div className="d-flex justify-content-start pt-0 mt-0 px-1">
          <span className="login-error"> {formik.touched.email && formik.errors.email} </span>
        </div>

        <div className="form-group">
          <div className="d-flex justify-content-between">
            <p className="mb-1">Password*</p>
          </div>

          <TextField
            name="password"
            fullWidth
            placeholder="Password"
            size="sm"
            defaultValue={formik.values.password}
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => formik.setFieldValue('password', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
        </div>
      </Stack>

      <div className="d-flex justify-content-between pt-0 mt-0 px-1">
        <span className="login-error"> {formik.touched.password && formik.errors.password} </span>
        <Link to="/forgot-password" className="forgotpassword">
          Forgot Password ?
        </Link>
      </div>

      {loading ? (
        <>
          <button className="reset-page-btn mt-3" size="large" type="submit" color="secondary">
            <div className="loader-btn">
              <span className="bar" />
              <span className="bar" />
              <span className="bar" />
            </div>
          </button>
        </>
      ) : (
        <button className="reset-page-btn mt-3" size="large" type="submit" color="secondary">
          Log in
        </button>
      )}
    </form>
  );
};

export default LDAP;

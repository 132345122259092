import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import logo from '../../assets/img/sicon.png';
import { filterResourcesByTags } from '../../utils/constant';


const ResourceAppList = ({ resourceTypeId, setSelectedResources, selectedResources, toggleResources, filterTags, search }) => {

        const userData = useSelector((state) => state.userSlice);
        const configData = useSelector((state) => state.configSlice);
        const [list, setList] = useState([]);

        const resource = async () => {
          try {
            const {data} = await axios.get(`${configData.config.UNO_URL}/api/resource/list/${resourceTypeId === 11 ? "characters" : resourceTypeId}`, {
              headers: { Authorization: `Bearer ${userData.token}`},
            });
            setList(data.data);
          } catch (error) {
            console.error(error);
          }
        };

        useEffect(() => {
          resource();
        }, []);
  
          
        let filteredList = filterResourcesByTags(list, filterTags);
            filteredList = filteredList?.filter((e) => {
              if (search && !e.name.toLowerCase().includes(search.toLowerCase())) return false;
              return true;
            });
  
            const handleSelectAllResource = (status) =>{

              if(status){
                setSelectedResources((prevItems) => {
                  return [...prevItems.filter((item) => !filteredList.some((i) => i.uuid === item.uuid ))];
                });
                return;
              }
              setSelectedResources((prevItems) => {
                return [...prevItems, ...filteredList.filter((item) => !prevItems.some((i) => i.uuid === item.uuid ))];
              });
            }
  
        const checkBoxStatus = filteredList.every((e) => selectedResources.some((f) => f.uuid === e.uuid));

        return (
            <div className="row mt-0">

            {filteredList.length > 0 &&
              <div className='select-all'>
                <label className="form-check-label" htmlFor={`flexCheckDefault_${resourceTypeId}`} onChange={()=>handleSelectAllResource(checkBoxStatus)}>
                  <input checked={checkBoxStatus} className="form-check-input" type="checkbox" id={`flexCheckDefault_${resourceTypeId}`}  />
                    {checkBoxStatus ? "Unselect" : "Select"} all
                </label>
              </div> }

              {filteredList.map((e) =>(  
                  <div className={`col-md-3 py-3 `} key={e.id}>
                    <div className={`Appbox ${selectedResources.some((f) => f.uuid === e.uuid) ? 'selected' : ''}`} onClick={() => toggleResources(e)} >
                      <div className="appthumb">
                        <img
                          src={e.thumbnail || e.versions[0].data.thumbnail || ''}
                          alt=""
                          onError={(e) => {
                            e.target.src = logo;
                            e.target.onerror = null;
                          }}
                        />
                        <div className="forselected">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi-check-lg"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                          </svg>
                        </div>
                      </div>

                      <div className="appdata">
                        <div className="apptitle">
                          <p>{e.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
              
            </div>
        );
};

export default ResourceAppList;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';
import jwtEncode from 'jwt-encode';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { saveUserData, saveUserPlan } from '../../../store/slice/userSlice';
import { loginValidationSchema } from './validation';
import { myApplicationList, myTagsList } from '../../../store/slice/myAppSlice';
import { moduleFilter } from '../../../store/slice/moduleFilter';
import { groupTypes, roles } from '../../../store/slice/configSlice';
import Google from '../methods/google';
import Native from '../methods/native';
import LDAP from '../methods/ldap';
import { applicationId } from '../../../env';

export default function LoginForm() {
  const configData = useSelector((state) => state.configSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const config = configData.config;

  const handleSuccess = async (userData) => {
    const referrerUrl = sessionStorage.getItem('referrer');
    const redirectUri = sessionStorage.getItem('redirectUri');
    localStorage.setItem('token', userData.token);

    if (referrerUrl) {
      const payload = { token: userData.token, redirectUri };
      const token = jwtEncode(payload, '');
      sessionStorage.removeItem('referrer');
      window.location.href = `${referrerUrl}?referrer=${token}`;
      return;
    }

    const plansFind = await axios.get(`${config.UNO_URL}/api/user/plan/subscription/${applicationId}`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      }});

      const userAssignedPlan={...plansFind.data.data};
      dispatch(saveUserPlan(userAssignedPlan));

      dispatch(saveUserData(userData));
      getMyApps(config, {
        token: userData.token,
        user: userData.user,
      });
      getAllTags(config, {
        token: userData.token,
        user: userData.user,
      });
      getModuleFilters(config, {
        token: userData.token,
        user: userData.user,
      });
      getGroupType(config, {
        token: userData.token,
        user: userData.user,
      });
      navigate('/dashboard');
      setLoading(false);
    
  };

  const getMyApps = async (config, userInfo) => {
    try {
      const myApp = await axios.get(`${config.UNO_URL}/api/application/mine`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      const appList = myApp?.data?.data || [];
      dispatch(myApplicationList(appList));
    } catch (error) {
      console.error(error);
    }
  };
  
  const getAllTags = async (config, userInfo) => {
    try {
      const getAppList = await axios.get(`${config.UNO_URL}/api/tag/list/${config?.applicationCategoryId}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      const tagsList = getAppList.data.data.tags || [];
      dispatch(myTagsList(tagsList));
    } catch (error) {
      console.error(error);
    }
  };

  const getModuleFilters = async (config, userInfo) => {
    try {
      const modulefilters = await axios.get(`${config.UNO_URL}/api/tag/list/${config?.moduleFilterId}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      const moduleFilterList = modulefilters.data || [];

      dispatch(moduleFilter(moduleFilterList));
    } catch (error) {
      console.error(error);
    }
  };

  const getGroupType = async (config, userInfo) => {
    try {
      const { data } = await axios.get(`${config.UNO_URL}/api/role/grouptype/list`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      dispatch(groupTypes(data.data.groupTypes));
      dispatch(roles(data.data.roles));
    } catch (error) {
      console.log(error);
    }
  };

  const showError = (error) => {
    setError(error);
    setTimeout(() => setError(null), 5000);
  };

  return (
    <>
      {configData.config.loginMethods.map((method) => {
        switch (method.type) {
          case 'NATIVE':
            return (
              <>
                <Native handleSuccess={handleSuccess} method={method} loading={loading} setLoading={setLoading} />
                <hr className="my-4" />
              </>
            );
          case 'GOOGLE':
            return (
              <><GoogleOAuthProvider clientId={method.data.clientId}>
                <Google showError={showError} handleSuccess={handleSuccess} loading={loading} setLoading={setLoading} />
              </GoogleOAuthProvider>
              <hr className="my-4" />
              </>
            );
          case 'LDAP':
            return (
              <>
                <LDAP showError={showError} handleSuccess={handleSuccess} loading={loading} setLoading={setLoading} />
                <hr className="my-4" />
              </>
            );
          default:
            return null;
        }
      })}

      {/* {loginMethods.includes("GOOGLE") && (
          <>
            <GoogleOAuthProvider clientId={configData.config.googleConfig.clientId}>
              <Google showError={showError} handleSuccess={handleSuccess} />
            </GoogleOAuthProvider>
          </>
        )} */}

      <span className="login-error">{error}</span>
    </>
  );
}

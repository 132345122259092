import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { useSelector } from 'react-redux';
import LabelIcon from '@mui/icons-material/Label';
import Skeleton from 'react-loading-skeleton';
import { CloseIcon, CopyIcon, DeleteIcon, EditIcon, EyeIcon } from '../../SvgIconsData';
import ConfirmBox from '../teammanagement/ConfirmBox';
import { DEFAULT, PRIVATE, PUBLIC } from '../../utils/constant';
import ProPopup from "../../layouts/ProPopup";

const Roles = ({ mode, permissions, userPlan }) => {

    const navigate = useNavigate();
    const { uuid } = useParams();
    const [showList, setShowList] = useState(false);
    const [deleteRole, setDeleteRole] = useState({});
    const [manageUsers, setManageUsers] = useState(false);
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [selectedRole, setSelectedRole] = useState({});
    const [roleUsers, setRoleUsers] = useState([]);
    const [toDelete, setToDelete] = useState([]);
    const [toAdd, setToAdd] = useState([]);
    const [search, setSearch] = useState("");
    const [userList, setUserList] = useState([]);
    const [openPro, setOpenPro] = useState(false);
    const [loading, setLoading] = useState(true)
    const CloseManageUsers = () => {
        setShowList(false);
        setManageUsers(false);
        setRoleUsers([]);
        setToDelete([]);
        setToAdd([]);
    };
    const [list, setList] = useState([]);
    const OpenManageUsers = (role) => {
        setSelectedRole(role);
        setRoleUsers([]);
        fetchRoleUsers(role.id);
        setManageUsers(true)
    };

    const fetchRoleUsers = async (id) => {
        try {
            const { data } = await axios.get(`${configData.config.UNO_URL}/api/role/users/list/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            setRoleUsers(data.data);
        } catch (error) {
            console.log(error);
        }
    }


    const handleDelete = async () => {
        try {
            await axios.post(`${configData.config.UNO_URL}/api/role/delete`, { id: deleteRole.id }, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            setDeleteRole({});
            fetchRoles();
        } catch (error) {
            console.log(error);
        }
    }

    const removeUser = (id) => {
        setToDelete([...toDelete, id]);
    }


    const fetchRoles = async () => {
        try {
            const { data } = await axios.get(`${configData.config.UNO_URL}/api/role/list/${mode}/${userData.user.group.typeId}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            setList(data.data);
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    }
    const addNew = (user) => {
        setRoleUsers([...roleUsers.filter((e) => e.uuid !== user.uuid), user]);
        setToAdd([...toAdd, user.uuid]);
        setToDelete(toDelete.filter(e => e !== user.uuid))
        setSearch("")
    }
    const updateRoleUsers = async () => {
        try {
            const { data } = await axios.post(`${configData.config.UNO_URL}/api/role/users/update/${selectedRole.id}`, {
                users: toAdd,
                toDelete
            }, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            fetchRoles();
            CloseManageUsers();
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchRoles();
        fetchUsers();
    }, []);
    const fetchUsers = async () => {
        try {
            const { data } = await axios.post(`${configData.config.UNO_URL}/api/bulk-actions/group/user/details/${userData.user.group.uuid}`, {
                fields: ['name', 'uuid', 'avatar']
            }, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            setUserList(data.users);
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div className='container-fluid'>
                <div className="row mt-4 colTableStandard align-items-center align-middle subheadlist align-middle p-1 px-2">
                    <div className="col-3 p-2">Roles</div>
                    {/* <div className="col-9 p-2"></div> */}
                </div>
                { loading ? 
                <>
                {[...Array(3)].map((_, index) => (
                 <div className='row rolePerRow'>
                    <div className='col-3'>
                        <div className='UserDataHere managemodal'>
                            <h3> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </h3>
                            <p> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </p>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='UserDataHere managemodal'>
                            <h3> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </h3>
                            <p> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </p>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='UserDataHere managemodal'>
                            <h3> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </h3>
                            <p> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </p>
                        </div>
                    </div>
                 </div> ))}
                </>
                :
                <>
                  {
                    list.map((item, index) => {
                        return (
                            <div key={index} className='row rolePerRow'>

                                <div className='col-3'>
                                    <div className='UserDataHere managemodal'>
                                        <h3>{item.name} {item.type === 'DEFAULT' ? <span style={{ fontStyle: "italic", fontSize: "10px" }}>Default</span> : item.type === PUBLIC ? <span style={{ fontStyle: "italic", fontSize: "10px" }}>Public</span> : null} {item.color ? <LabelIcon style={{ color: item.color }} /> : null}</h3>
                                        <p>{item.description}</p>
                                    </div>
                                </div>

                                {item.type === 'DEFAULT' && mode !== PUBLIC ?
                                    <>
                                        <div className='col-7 d-flex align-items-center justify-content-start'>
                                            <p style={{ fontSize: "13px", margin: "0" }}>This role cannot be edited or deleted as all users onboarded to this platform will be assigned this role by default.</p>
                                        </div>
                                        <div className='col-2 d-flex align-items-center justify-content-center'>
                                            <div className='roleactionbtns'>
                                                {!permissions.includes("TEAM.ROLES.VIEW") ? null : <span onClick={() => userPlan?.roles?.create?.role ? 
                                                navigate(`/dashboard/roles/customize/${uuid}`, {
                                                    state: {
                                                        type: 'view',
                                                        role: item
                                                    }
                                                })
                                                :
                                                setOpenPro(true)                                        
                                            }><EyeIcon /></span>}

                                                {!permissions.includes("TEAM.ROLES.CREATE") ? null : <span onClick={() =>  userPlan?.roles?.create?.role ?
                                                 navigate(`/dashboard/roles/customize/${uuid}`, {
                                                    state: {
                                                        type: 'copy',
                                                        role: item
                                                    }
                                                }) 
                                                :
                                                setOpenPro(true) 
                                            }><CopyIcon /></span>}
                                            
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='col-2 d-flex align-items-center justify-content-center'>
                                            {mode === PUBLIC ? null : (!permissions.includes("TEAM.USERS.CREATE") ? null : <p style={{ color: "#ca373c", fontSize: "15px", margin: "0", cursor: "pointer" }}><span onClick={() => userPlan?.roles?.create?.role ? OpenManageUsers(item) : setOpenPro(true) }>Manage users</span></p>)}
                                        </div>
                                        <div className='col-5 d-flex align-items-center'>
                                            {mode === PUBLIC ? null : <div className='rolelist'>
                                                {item.allocations.map((allocation) => userData.user.uuid === allocation.user.uuid ? null :
                                                    <div key={allocation.id} className='userselect'>
                                                        {allocation.user.avatar ?
                                                            <img src={allocation.user.avatar} alt={allocation.user.name} referrerPolicy={'no-referrer'} />
                                                            :
                                                            <span className='sm-initials'>
                                                                {allocation.user.name.length > 0 ? allocation.user.name.split(" ").length >= 2 ? `${allocation.user.name.split(" ")[0][0]}${allocation.user.name.split(" ")[1][0]}` : `${allocation.user.name.split(" ")[0][0]}` : ""}
                                                            </span>

                                                        }
                                                        {allocation.user.name}
                                                    </div>)}
                                            </div>}
                                        </div>
                                        <div className='col-2'>
                                            <div className='roleactionbtns'>
                                                <div className='roleactionbtns_child'>
                                                    {!permissions.includes("TEAM.ROLES.VIEW") ? null : <span onClick={() => userPlan?.roles?.create?.publicRole ? 
                                                    navigate(`/dashboard/roles/customize/${uuid}`, {
                                                        state: {
                                                            type: 'view',
                                                            role: item,
                                                            mode
                                                        }
                                                    })
                                                    :
                                                 setOpenPro(true)
                                                }><EyeIcon /></span>}
                                                    {!permissions.includes("TEAM.ROLES.CREATE") ? null : <span onClick={() => userPlan?.roles?.create?.publicRole ? 
                                                     navigate(`/dashboard/roles/customize/${uuid}`, {
                                                        state: {
                                                            type: 'copy',
                                                            role: item,
                                                            mode
                                                        }
                                                    })
                                                    :
                                                    setOpenPro(true)
                                                    }><CopyIcon /></span>}
                                                </div>

                                                <div className='roleactionbtns_child'>
                                                    {(item.type !== PUBLIC) || (mode === PUBLIC) ?
                                                        (!permissions.includes("TEAM.ROLES.EDIT") ? null : <span onClick={() => 
                                                            userPlan?.roles?.create?.publicRole ? 
                                                            navigate(`/dashboard/roles/customize/${uuid}`, {
                                                            state: {
                                                                type: 'edit',
                                                                role: item,
                                                                mode
                                                            }
                                                        })
                                                        :
                                                        setOpenPro(true)
                                                        }><EditIcon /></span>) : null}
                                                    {(item.type === PRIVATE) || (mode === PUBLIC && item.type !== DEFAULT) ? (!permissions.includes("TEAM.ROLES.DELETE") ? null : <span onClick={() => userPlan?.roles?.create?.publicRole ? setDeleteRole(item) : setOpenPro(true) } ><DeleteIcon /></span>) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                        )
                    }
                    )
                }
                </>
                }
              


            </div>

            <ConfirmBox
                messagehead={"Delete Role"}
                message={'Are you sure you want to delete this role ?'}
                show={deleteRole.id}
                onHide={() => setDeleteRole({})}
                handleDelete={handleDelete}
            />

            <Modal show={manageUsers} onHide={CloseManageUsers}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body >
                    <div className='container-fluid d-flex justify-content-between flex-column managerolemodal'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className='UserDataHere'>
                                    <h3>{selectedRole.name}</h3>
                                    <p>{selectedRole.description}</p>
                                </div>
                            </div>
                            <div className='col-md-9'>
                                <div className='UserBox'>
                                    <p style={{ fontWeight: "500" }}>Users</p>
                                    <div className='rolelist'>
                                        {roleUsers.map((user, index) => {
                                            if (toDelete.includes(user.uuid)) return null;
                                            if (userData.user.uuid === user.uuid) return null;
                                            return <div key={index} className='userselect'>
                                                {user.avatar ?
                                                    <img src={user.avatar} alt={user.name} referrerPolicy={'no-referrer'} />
                                                    :
                                                    <span className='sm-initials'>
                                                        {user.name.length > 0 ? user.name.split(" ").length >= 2 ? `${user.name.split(" ")[0][0]}${user.name.split(" ")[1][0]}` : `${user.name.split(" ")[0][0]}` : ""}
                                                    </span>
                                                }
                                                {user.name}
                                                <span className='removeuser' onClick={() => removeUser(user.uuid)}><CloseIcon /></span>
                                            </div>
                                        })}


                                        <div className='userselect withinput'>
                                            <input
                                                type="text"
                                                placeholder='Add Users'
                                                className='form-control'
                                                value={search}
                                                onFocus={() => setShowList(true)}
                                                onBlur={() => setTimeout(() => setShowList(false), 500)}
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                            {showList ?
                                                <>
                                                    <div className='userinputsearch'>
                                                        {userList.map((user) => {
                                                            if (roleUsers.map(e => e.uuid).includes(user.uuid) && !toDelete.includes(user.uuid)) return null;
                                                            if (search.length && !user.name?.toLowerCase().includes(search.toLowerCase())) return null;
                                                            if (userData.user.uuid === user.uuid) return null;
                                                            return <div onClick={() => addNew(user)} key={user.uuid} className='userserachitem'>
                                                                {user.avatar ?
                                                                    <img src={user.avatar} alt={user.name} referrerPolicy={'no-referrer'} />
                                                                    :
                                                                    <span className='sm-initials position-relative'>
                                                                        {user.name.length > 0 ? user.name.split(" ").length >= 2 ? `${user.name.split(" ")[0][0]}${user.name.split(" ")[1][0]}` : `${user.name.split(" ")[0][0]}` : ""}
                                                                    </span>
                                                                }
                                                                <p>{user.name}</p>
                                                            </div>
                                                        })}
                                                    </div>
                                                </> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12 d-flex align-items-center justify-content-end'>
                                <button onClick={() => CloseManageUsers()} className='createbtn cancel me-4'>Cancel</button>
                                <button onClick={() => updateRoleUsers()} className='createbtn'>Save</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <ProPopup openPro={openPro} setOpenPro={setOpenPro} /> 

        </>
    )
}

export default Roles

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { Nav, Tab, Form } from 'react-bootstrap';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { exportMultipleChartsToPdf } from '../../../utils/pdf';
import PieCharts from '../ChartsFeedAndSurvey/PieCharts';
import SatisfactionChart from '../ChartsFeedAndSurvey/SatisfactionChart';
import Progress from './Progress';
import { CloseIcon } from '../../../SvgIconsData';

const ResponseModal = ({ show, handleClose, activeTab, responses }) => {
  const [pageNum, setPage] = useState(0);
  const[view,setView]=useState(false)
  const [selectedOption, setSelectedOption] = useState('option1');
  const userData = useSelector((state) => state.userSlice);
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const tableData = [
    ['Feedback and Management Survey Analysis', ''],
    ['FeedBack/Survey Name', responses?.name],
    ['Feedback/Survey Description', responses?.description],
    ['Name', userData?.user?.name],
    ['Report Generated at ', moment().format('ll')],
  ];

  return (
    <>
      <Modal centered show={show} onHide={handleClose} className="createfeedback">
        <Modal.Header className="modalheader text-center">
          {responses.name || ''} 
            <span onClick={handleClose}>
                <CloseIcon/>
            </span>
         
        </Modal.Header>
        <Modal.Body className="p-4 pt-2">
          <Tab.Container className="mt-5" id="nested-tabs" defaultActiveKey={activeTab}>
            <div className="row align-items-center justify-content-between">
              <div className="col-md-8">
                <Nav className="tabsproduct" variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="summary"  onClick={()=>setView(false)}>Summary</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="individual" onClick={()=>setView(true)}>Individual</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              {!view && (
                <div className="col-md-4 mt-3 mt-md-0">
                  <span className="text-danger cursor-pointer" onClick={() => exportMultipleChartsToPdf(tableData)}>
                    View Report
                  </span>
                </div>
              )}
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="summary">
                {responses?.data?.length > 0 &&
                  responses?.data?.map((item, index) => (
                    <>
                      <div className="row mt-3">
                        <div className="col-md-12 custom-chart">
                          <p className=" m-0"><strong>{`Q${index + 1}.${item.name}`}</strong></p>
                          <p className=" font-xs">{responses.responses.length} Responses</p>
                          <div className="row">
                            <div className="col-md-12 ">
                              {item.type === 'MULTIPLE' && (
                                <SatisfactionChart responses={responses.responses} item={item} />
                              )}
                              {item.type === 'MOOD' && <Progress responses={responses.responses} item={item} />}

                              {item.type === 'TEXT' && (
                                <div className="row">
                                  {responses.responses.map((e, index) => {
                                    return (
                                      <div className="col-md-12">
                                        <ul className="list-style-type-none m-0 p-0">
                                          <li className="font-xs">
                                            <strong>{`R${index + 1}`}.</strong> <span>{e.data[item.id]}</span>{' '}
                                          </li>
                                        </ul>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6">
                              {item.type === 'BOOLEAN' && <PieCharts responses={responses.responses} item={item} />}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </Tab.Pane>
              <Tab.Pane eventKey="individual">
                {responses?.data?.length > 0 && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex mt-3 ml--10">
                        <span className="cursor-pointer" onClick={() => setPage(Math.max(0, pageNum - 1))}>
                          <NavigateBeforeOutlinedIcon />
                        </span>
                        {pageNum + 1} of {responses?.responses?.length ? responses?.responses?.length : 0}
                        <span
                          className="cursor-pointer"
                          onClick={() => setPage(Math.min(pageNum + 1, responses?.responses?.length - 1))}
                        >
                          <KeyboardArrowRightOutlinedIcon />
                        </span>
                      </div>
                      <div className="row mt-2">
                        {responses?.data.map((item, index) => (
                          <div className="col-md-12" key={index}>
                            <p className="my-2"><strong>{`Q${index + 1}.${item.name}`}</strong> </p>
                            {item.type === 'TEXT' ? (
                              <input
                                type="text"
                                value={responses.responses[pageNum]?.data[item.id] || ''}
                                readOnly
                                className="form-control"
                              />
                            ) : (
                              item.options.map((option, optionIndex) => (
                                <Form key={optionIndex}>
                                  <Form.Check
                                    type="radio"
                                    label={option.name}
                                    name={`q${index}`}
                                    id={`q${index}-${optionIndex}`}
                                    checked={option.id === parseInt(responses.responses[pageNum]?.data[item.id], 10)}
                                  />
                                </Form>
                              ))
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResponseModal;

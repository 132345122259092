import React, { useState, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/home/Dashboard';
import SideBar from './pages/layout/SideBar';
import Header from './pages/layout/Header';
import TeamManagement from './pages/teammanagement/TeamManagement';
import Subscriptions from './pages/Subscriptions';
import RolePermission from './pages/rolesaccess/RolePermission';
import SubscriptionList from './pages/Subscription/subscriptionlist';
import CreateRoles from './pages/rolesaccess/CreateRoles';
import UserResources from './pages/teammanagement/UserResources';
import AssignResourceIndex from './pages/teammanagement/GroupAssignResources/TeamSubscriptionList';
import AssignedList from './pages/teammanagement/AssignedList';
import ForgotPassword from './pages/ForgotPassword';
import SetNewPassword from './pages/SetNewPassword';
import Subscription from './pages/Subscription/Subscription';
import CreateGroupType from './pages/rolesaccess/CreateGroupType';
import CreateNewPassword from './pages/CreateNewPassword';
import Feedback from './pages/FeedAndSurveyMngt/Feedback';
import FeedbackDetail from './pages/FeedAndSurveyMngt/feedbacksurveycomp/FeedbackDetail';
import CreateQuestions from './pages/FeedAndSurveyMngt/feedbacksurveycomp/CreateQuestions';
import EditCreateQuestions from './pages/FeedAndSurveyMngt/feedbacksurveycomp/EditCreateQuestions';
import FeedBackPreview from './pages/FeedBackPreview';
import UpgradeHelper from './layouts/UpgradeHelper';


const AuthenticatedRoutes = ({ user, plan }) => useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard" />,
      index: true,
    },
    {
      path: '/dashboard',
      children: [
        { element: <Navigate to={`/dashboard/home/${user.group.uuid}`} />, index: true },
        { path: 'home/:uuid', element: <Dashboard  /> },
        {
          path: 'team-management/:uuid',
          element: !user.permissions.includes('TEAM.VIEW') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            <TeamManagement />
          ),
        },
        {
          path: 'team-management/user/:uuid',
          element: !user.permissions.includes('TEAM.USERS.VIEW') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            <UserResources />
          ),
        },
        {
          path: 'team-management/assign/resources/:type/:uuid',
          element: !user.permissions.includes('RESOURCE.ASSIGNMENT.ASSIGN') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            <AssignResourceIndex />
          ),
        },
        {
          path: 'team-management/confirm/resources/:type/:uuid',
          element: !user.permissions.includes('RESOURCE.ASSIGNMENT.ASSIGN') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            <AssignedList />
          ),
        },
        // {
        //   path: 'team-management/assign-resources/:uuid',
        //   element: !user.permissions.includes('RESOURCE.ASSIGNMENT.ASSIGN') ? (
        //     <Navigate to={`/dashboard/home/${user.group.uuid}`} />
        //   ) : (
        //     <AssignResourceIndex />
        //   ),
        // },
        // {
        //   path: 'team-management/assign-resources/confirm/:uuid',
        //   element: !user.permissions.includes('RESOURCE.ASSIGNMENT.ASSIGN') ? (
        //     <Navigate to={`/dashboard/home/${user.group.uuid}`} />
        //   ) : (
        //     <AssignedList />
        //   ),
        // },
        // {
        //   path: 'team-management/user/assign-resources/:uuid',
        //   element: !user.permissions.includes('RESOURCE.ASSIGNMENT.ASSIGN') ? (
        //     <Navigate to={`/dashboard/home/${user.group.uuid}`} />
        //   ) : (
        //     <UserAssignResources />
        //   ),
        // },
        // {
        //   path: 'team-management/user/assign-resources/confirm/:uuid',
        //   element: !user.permissions.includes('RESOURCE.ASSIGNMENT.ASSIGN') ? (
        //     <Navigate to={`/dashboard/home/${user.group.uuid}`} />
        //   ) : (
        //     <UserAssignedList />
        //   ),
        // },
        {
          path: 'subscriptions/:uuid',
          element: !user.permissions.includes('RESOURCE.SUBSCRIPTION.VIEW') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            <Subscriptions />
          ),
        },
        {
          path: 'subscriptions/list/:uuid',
          element: !user.permissions.includes('RESOURCE.SUBSCRIPTION.VIEW') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            <SubscriptionList />
          ),
        },
        {
          path: 'subscriptions/create/:uuid',
          element: !user.permissions.includes('RESOURCE.SUBSCRIPTION.CREATE') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            <Subscription />
          ),
        },
        {
          path: 'roles/:uuid',
          element: !user.permissions.includes('TEAM.ROLES.VIEW') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            plan && plan.roles.access ?
            <RolePermission />
            :
            <UpgradeHelper />
          ),
        },
        {
          path: 'roles/customize/:uuid',
          element: !user.permissions.includes('TEAM.ROLES.VIEW') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            <CreateRoles />
          ),
        },
        {
          path: 'roles/grouptype/create',
          element: !user.permissions.includes('TEAM.GROUPTYPE.CREATE') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            <CreateGroupType />
          ),
        },
        {
          path: 'roles/grouptype/edit/:id',
          element: !user.permissions.includes('TEAM.GROUPTYPE.EDIT') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            <CreateGroupType />
          ),
        },

        {
          path: 'feedback/:uuid',
          element: !user.permissions.includes('GENERAL.FEEDBACK') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
           plan && plan.feedbackAndSurvey.access ? <Feedback />
            :
            <UpgradeHelper />
          ),
        },
        {
          path: 'feedback/feedback/detail/:uuid',
          element: !user.permissions.includes('GENERAL.FEEDBACK') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            <FeedbackDetail />
          ),
        },
        {
          path: 'feedback/create/:uuid',
          element: !user.permissions.includes('GENERAL.FEEDBACK') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            <CreateQuestions />
          ),
        },
        {
          path: 'feedback/edit-create-questions',
          element: !user.permissions.includes('GENERAL.FEEDBACK') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            <EditCreateQuestions />
          ),
        },
        {
          path: 'feedback/feedback-preview/:uuid',
          element: !user.permissions.includes('GENERAL.FEEDBACK') ? (
            <Navigate to={`/dashboard/home/${user.group.uuid}`} />
          ) : (
            <FeedBackPreview />
          ),
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/dashboard" replace />,
    },
  ]);

  const UnauthenticatedRoutes = () =>  useRoutes([
        {
          path: '/',
          element: <Navigate to="/login" />,
          index: true,
        },
        {
          path: '/login',
          element: <LoginPage />,
          index: true,
        },
        {
          path: '/forgot-password',
          element: <ForgotPassword />,
          index: true,
        },
        {
          path: '/set-new-password',
          element: <SetNewPassword />,
          index: true,
        },
        {
          path: '/create-new-password',
          element: <CreateNewPassword />,
          index: true,
        },
        {
          path: '/feedback',
          element: <FeedBackPreview />,
          index: true,
        },
        {
          path: '*',
          element: <Navigate to="/login" replace />,
        },
      ]);

const Router = () => {
  const userData = useSelector((state) => state.userSlice);
  // const [openPro, setOpenPro] = useState(false);
  // const [userPlan, setUserPlan] = useState(null);

  const [sideBarAddRemoveClass, setsideBarAddRemoveClass] = useState('sidebaractive');

  const menuToggle = () => {
    const classExist = sideBarAddRemoveClass;
    setsideBarAddRemoveClass(classExist.length > 0 ? '' : 'sidebaractive');
  };


  return userData.token ? (
    <>
      <Header menuToggle={menuToggle} />
      <SideBar sideBarAddRemoveClass={sideBarAddRemoveClass} menuToggle={menuToggle} />
      <div className="marginright maindashbg mobiledash">
        <div className="container-fluid">
          <AuthenticatedRoutes user={userData.user} plan={ userData.plans.data ? userData.plans.data : null } />
        </div>
      </div>

      {/* <ProPopup openPro={openPro} setOpenPro={setOpenPro} />   */}

    </>
  ) : (
    <>
      <UnauthenticatedRoutes />
    </>
  );
};

export default Router;

import React from 'react'
import { SearchesCIconData } from '../SvgIconsData';

const SearchAll = ({ search, setSearch, placeholder }) => {
    

    return (
        <>
            <div className="searchfields form-group">
                <span className='searchinconssd'> <SearchesCIconData /> </span>
                <input type="text" placeholder={placeholder} value={search} onChange={(e) => setSearch(e.target.value)} className='form-control' />
            </div>

        </>
    )
}

export default SearchAll

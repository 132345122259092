import React, { useEffect, useState } from 'react';
import FlipIcon from '@mui/icons-material/Flip';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import {  Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import { useSelector } from 'react-redux';
import { Thumbnail } from '../thumbnail';
import NoDataFound from '../NoDataFound';
import EditCreateGroup from './EditCreateGroup';

const Groups = ({ search, groupsList, type, refresh , permissions}) => {
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [flippedId, setFlippedId] = useState(null);
    const [list, setList] = useState([]);
    const [detail, setDetail] = useState()


    const [editGroupModal, setEditGroupModal] = useState(false);

    const openEditGroupModal = (e) => {
        setEditGroupModal(true);
        setDetail(e)
    };

    const closeEditGroupModal = () => {
        setEditGroupModal(false);
    };


    useEffect(() => {
        setList([...groupsList])
    }, [groupsList])

    const flipBtn = (id) => {
        if (flippedId === id) {
            setFlippedId(null);
        } else {
            setFlippedId(id);
        }
    }

    return (
        <>
            <div className="row userndgrouptabs">
                {list.length === 0 ? (
                    <NoDataFound heading="No Sub-Groups Found" nodatatext="Add a new Sub-Group using Add Group button on top." />
                ) :
                    list.map((e) => {
                        if (!e.name.toLowerCase().includes(search.toLowerCase())) { return null }
                        return (
                            <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-6 mt-0 lefrightcolspacing' key={e.id}>
                                <div className={flippedId === e.id ? "flip-card backside" : "flip-card"}>
                                    <div className="flip-card-inner">

                                        <div className="flip-card-front">
                                            <div className='groupcard' >
                                                <div className='flipbtn text-end'>
                                                    <FlipIcon onClick={() => flipBtn(e.id)} style={{ cursor: "pointer", zIndex: "2" }} />
                                                </div>

                                                <Link to={`/dashboard/team-management/${e?.uuid}`}>
                                                    <div className='groupbody'>
                                                        <div className='grouplogo outline-none'>
                                                            <Thumbnail path={e?.avatar || ""} name={e?.name || ""} />
                                                            
                                                        {e.active ?
                                                            <Tooltip arrow TransitionComponent={Fade} title="Active">
                                                            <span className='userActive'>{""}</span>
                                                            </Tooltip>
                                                                :
                                                            <Tooltip arrow TransitionComponent={Fade} title="Inactive">
                                                            <span className='userInactive'>{""}</span>
                                                            </Tooltip>
                                                        }

                                                        </div>
                                                        <div className='groupdetails'>
                                                            <p className='cn'>{e.name}</p>
                                                            {e.type && <p className='gn' style={{backgroundColor : `${e.type.color}10` , color : e.type.color}}>{e.type?.name}</p>}
                                                            <p className='datahere'>
                                                                {e.groups} Groups | {e.users} Users
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="flip-card-back">
                                            <div className='groupcardback'>
                                                <div className='backdetails'>
                                                    <KeyboardBackspaceIcon onClick={() => flipBtn(e.id)} style={{ cursor: "pointer", zIndex: "2" }} />
                                                    <div className='grouplogo'>
                                                        <Thumbnail path={e?.avatar || ""} name={e?.name || ""} />
                                                    </div>
                                                    <div className='backinfo'>
                                                        <p>{e.name}</p>
                                                    </div>
                                                </div>
                                                <div className='backoptions'>
                                                    <div className='optionhere'>
                                                        <Link><i className="bi bi-calendar2-check-fill" />Subscriptions</Link>
                                                    </div>
                                                    {!permissions.includes("TEAM.GROUP.CREATE") ? null:<div className='optionhere editone' onClick={() => openEditGroupModal(e)}>Edit</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    )
                }
            </div>

            {editGroupModal && <EditCreateGroup refresh={refresh} detail={detail} showEditGroup={openEditGroupModal} closeEditGroupModal={closeEditGroupModal} />}

        </>
    );
};

export default Groups;
import React from 'react'
import Skeleton from 'react-loading-skeleton';


const UserBoxLoad = () => {
  return (
    <>
     <div className="row userndgrouptabs">
    {[...Array(5)].map((_, index) => (
             <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-6 mt-0 lefrightcolspacing' key={index}>
             <div className="flip-card">
                 <div className="flip-card-inner">
                     <div className="flip-card-front">
                         <div className='groupcard userprofileimg' >
                             <div className='groupbody w-100'>
                                 <div className='grouplogo outline-none'/>
                                 
                                 <div className='groupdetails w-100'>
                                     <div className='d-flex align-items-center justify-content-center w-100 mt-2' >
                                         <p className='cn w-70'>  <Skeleton width="100px" highlightColor='#ffffff' baseColor='#f3f3f3' /> </p>
                                     </div>
                                     <div className='d-flex align-items-center justify-content-center w-100' >
                                         <p className='cn w-70'>  <Skeleton width="70px" highlightColor='#ffffff' baseColor='#f3f3f3' /> </p>
                                     </div>

                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>


             </div>
         </div>
      ))}
    
      </div>
      </>
  )
}

export default UserBoxLoad

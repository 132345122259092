const {  createSlice, createAsyncThunk} = require("@reduxjs/toolkit");

const initialState={
    module:{
    },

}

const moduleFilterSlice  = createSlice({
  name:"moduleFilter",
  initialState,
  reducers:{
    moduleFilter:(state,action)=>{
        state.module= action.payload.data;
      },
  },

});

export const { moduleFilter } = moduleFilterSlice.actions;
export default moduleFilterSlice.reducer;
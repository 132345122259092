import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import AddIcon from '@mui/icons-material/Add';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { QrCodeScannerOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import BackBtn from '../BackBtn';
import MultipleChoice from './CreateQuestions/MultipleChoice';
import YesNoQue from './CreateQuestions/YesNoQue';
import TrueFalseQue from './CreateQuestions/TrueFalseQue';
import EmotionsQue from './CreateQuestions/EmotionsQue';
import SelectDateTime from './SelectDateTime';
import TextChoiceQue from './CreateQuestions/TextChoiceQue';

const CreateQuestions = () => {
  const location = useLocation();
  const { state } = location;
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [questions, setQuestions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [endTime, setEndTime] = useState('');
  const [expiryTime, setExpirytime] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imgUrl, setUrl] = useState('');
  const [save, setSave] = useState(false);
  const [isDone,setDone]=useState(true)
  const navigate = useNavigate();

  const QueOptions = [
    { value: 'MULTIPLE', label: 'Multiple Choice' },
    { value: 'BOOLEAN', label: 'Yes/No' },
    { value: 'TEXT', label: 'Text' },
    { value: 'MOOD', label: 'Emotions' },
  ];

  const handleOptionChange = (selectedOption, index) => {
    setSelectedOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = selectedOption;
      return newOptions;
    });
    let defaultOptions = [];
    switch (selectedOption.value) {
      case 'MULTIPLE':
        defaultOptions = [{ id: 1, name: '' }];
        break;
      case 'BOOLEAN':
        defaultOptions = [
          { id: 1, letter: 'a', name: 'Yes', removable: false },
          { id: 2, letter: 'b', name: 'No', removable: false },
        ];
        break;
      case 'TEXT':
        defaultOptions = [{ id: 1, name: '' }];
        break;
      case 'MOOD':
        defaultOptions = [
          { id: 1, name: 'Happy', removable: false },
          { id: 2, name: 'Ok', removable: false },
          { id: 3, name: 'Sad', removable: false },
        ];
        break;
      default:
        defaultOptions = [{ id: 1, name: '' }];
    }
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index].type = selectedOption.value;
      updatedQuestions[index].options = defaultOptions;
      return updatedQuestions;
    });
  };

  const { uuid } = useParams();

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
    }),
  };

  const openDateTime = () => {
    setShow(true);
  };

  const hideDateTime = () => {
    setShow(false);
  };

  const getFeedBackData = async () => {
    const { data } = await axios.get(`${configData.config.UNO_URL}/api/feedback/getFeedback/${uuid}`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
    
    setType(data.data.type);
    setTitle(data.data.name);


    if (data.data.data) {
      setQuestions(data.data.data || []);
    } else {
      addDefaultQuestion();
    }
  
    setUrl(data.data.thumbnail || '');
    setExpirytime(data.data.expiryTime || '');
    if (data.data.data) {
      const initialSelectedOptions = data.data.data.map((question) =>
        QueOptions.find((option) => option.value === question.type)
      );
      setSelectedOptions(initialSelectedOptions);
    }
  };

  useEffect(() => {
    getFeedBackData();
  }, []);

  const addDefaultQuestion = () => {

    const defaultQuestion = {
      id: 1, 
      name: '', 
      type: 'MULTIPLE', 
      options: [{ id: 1, name: '' }], 
    };
    setQuestions([defaultQuestion]);
  };
  const onDrop = useCallback(async (acceptedFiles) => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    try {
      const response = await axios.post(
        `${configData.config.UNO_URL}/api/resource/upload/feedback/thumbnail/${uuid}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      setUrl(response.data.url || '');
    } catch (error) {
      console.error('Error:', error);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const configData = useSelector((state) => state.configSlice);

  const userData = useSelector((state) => state.userSlice);

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1); 
    setQuestions(updatedQuestions);
  };
  
  const handleAddNewQuestion = () => {
    addNewQuestion();
  };
  const addNewQuestion = () => {
    const newQuestion = {
      id: questions.length + 1,
      options: selectedOptions[questions.length] ? selectedOptions[questions.length].options : [],
    };
    setQuestions([...questions, newQuestion]);
  };
  const handleSaveFeedback = async () => {
    try {
      const payload = {
        questions,
        thumbnail: imgUrl,
      };
      setIsSubmitting(true);

      const response = await axios.post(`${configData.config.UNO_URL}/api/feedback/update/${uuid}`, payload, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      });
      setIsSubmitting(false);
      setSave(false);
      if (response.status === 200) {
        setDone(false)
      } else {
        console.error('API request failed');
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const handleSave = ()=>{
    setSave(true);
    setDone(true)
  }

  return (
    <>
      <div className="bg-grey-main createstions">
        <div className="row">
          <div className="col-md-6">
            <span
              className="cursor-pointer"
              onClick={() =>
                navigate(`/dashboard/feedback/${userData?.user?.group?.uuid}`, {
                  state: { key: state?.key || 'analytics' },
                })
              }
            >
              <KeyboardBackspaceIcon />
              Back
            </span>
          </div>
          {/* <div className="col-md-6">
            <div className="text-end">
              <div
                onClick={() => navigate(`/dashboard/feedback/feedback-preview/${uuid}`)}
                className="btn btn-sm btn-danger"
              >
                Preview
              </div>
            </div>
          </div> */}
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="beedbackbanner" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {!imgUrl.length > 0 ? (
                    <>
                      <div>
                        <div className="text-danger">
                          <FileUploadOutlinedIcon />
                          <h6 className="m-0 font-xs">Upload banner</h6>
                        </div>
                        <p className="font-xss">Drop the files here ...</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <img src={imgUrl} alt="" />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                {/* {feedback.status.charAt(0).toUpperCase() + feedback.status.slice(1).toLowerCase()} */}
                <h6 className="d-flex gap-2">
                  <strong>{type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()} Title: </strong>{' '}
                  <span className="text-capitalize">
                    {title}
                    <EditIcon className="font-md" />
                  </span>{' '}
                </h6>
                {questions?.length > 0 &&
                  questions.map((question, index) => (
                    <div key={index} className="bg-light p-4">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex gap-4 align-items-center">
                            <h5>
                              <strong>{`Q${index + 1}`}</strong>
                            </h5>
                            <div className="d-flex w-100">
                              <input
                                type="text"
                                className="form-control creatq"
                                placeholder="Enter your Question"
                                value={question.name}
                                onChange={(e) => {
                                  const updatedQuestions = [...questions];
                                  updatedQuestions[index].name = e.target.value;
                                  setQuestions(updatedQuestions);
                                  handleSave()
                                }}
                              />
                              <Select
                                className="form-control coptff"
                                styles={style}
                                placeholder="Select type of questions"
                                options={QueOptions}
                                value={selectedOptions[index]}
                                onChange={(selectedOption) =>{handleOptionChange(selectedOption, index);handleSave()}}
                              />
                              <span className="ps-2 pt-3 cursor-pointer text-danger" onClick={()=>{handleDeleteQuestion(index);handleSave()}}>
                                <DeleteIcon />{' '}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        {question.type === 'MULTIPLE' && (
                          <MultipleChoice
                            options={question.options}
                            setOptions={(newOptions) => {
                              const updatedQuestions = [...questions];
                              updatedQuestions[index].options = newOptions;
                              setQuestions(updatedQuestions);
                            }}
                          />
                        )}
                        {question.type === 'BOOLEAN' && (
                          <YesNoQue
                            options={question.options}
                            setOptions={(newOptions) => {
                              const updatedQuestions = [...questions];
                              updatedQuestions[index].options = newOptions;
                              setQuestions(updatedQuestions);
                            }}
                          />
                        )}

                        {question.type === 'TEXT' && <TextChoiceQue />}

                        {question.type === 'MOOD' && (
                          <EmotionsQue
                            options={question.options}
                            setOptions={(newOptions) => {
                              const updatedQuestions = [...questions];
                              updatedQuestions[index].options = newOptions;
                              setQuestions(updatedQuestions);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ))}

                <div className="row mt-3">
                  <div className="col-md-6">
                    <button className="btn btn-sm btn-danger" onClick={()=>{handleAddNewQuestion();handleSave()}}>
                      <AddIcon /> {questions.length === 0 ? 'Create First Question' : 'Next Question'}
                    </button>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex gap-3 justify-content-end">
                      <button className="btn btn-sm btn-light">Cancel</button>
                      <button className="btn btn-sm btn-danger" onClick={handleSaveFeedback} disabled={isSubmitting || !save}>
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="d-flex justify-content-center">
              <button onClick={openDateTime} className="btn btn-danger p-2 px-4" disabled={isDone}>
                Done
              </button>
            </div>
          </div>
        </div>
      </div>

      <SelectDateTime show={show} handleClose={hideDateTime} expiryTime={expiryTime} />
    </>
  );
};

export default CreateQuestions;

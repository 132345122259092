import react, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import { RightDragIcon } from '../../assets/icons/iconshere';
import NoDataFound from '../NoDataFound';
import { Thumbnail } from '../thumbnail';

const GroupCard = ({ search, groupsList }) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [flippedId, setFlippedId] = useState(null);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setList([...groupsList]);
    setLoading(false)
  }, [groupsList]);

  const flipBtn = (id) => {
    if (flippedId === id) {
      setFlippedId(null);
    } else {
      setFlippedId(id);
    }
  };

  const handleSubscriptionClick = (event, data) => {
    event.stopPropagation();
    event.preventDefault();
    navigate(`/dashboard/subscriptions/list/${data.uuid}`, { state: { groupName: data.name } });
  };

  return (
    <>
      <div className="row mt-4 userndgrouptabs">
        {list.length === 0 ? (
          <NoDataFound heading="No Sub-Groups Found" nodatatext="Add a new Sub-Group using Add Group button on top." />
        ) : (
          list.map((e) => (
            <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-6 mt-0 lefrightcolspacing" key={e.id}>
              <Link to={`/dashboard/subscriptions/${e?.uuid}`}>
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <div className="groupcard">
                        <div className="groupbody">
                          <div className="grouplogo outline-none">
                            <Thumbnail path={e?.avatar || ''} name={e?.name || ''} />
                          </div>
                          <div className="groupdetails">
                            <p className="cn">{e.name}</p>
                          </div>

                          {/* <button 
                           to={{
                            pathname: `/dashboard/subscriptions/list/${e?.uuid}`,
                            state: { groupName: e.name }
                          }}
                          > */}
                          <Tooltip arrow TransitionComponent={Fade} title="View List">
                            <button className="subscriptionbtn" 
                            onClick={(event) => handleSubscriptionClick(event, e)}>
                              Subscription
                              {/* <ChevronRightOutlinedIcon /> */}
                              <div className="appside">
                                <RightDragIcon/>
                                <RightDragIcon/>
                              </div>
                            </button>
                            </Tooltip>
                          {/* </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default GroupCard;

import React, { useState, useEffect } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const TextChoiceQue = () => {
    return (
        <>
            <div className="row mt-2">
                <div className="col-1">
                    {/* <p className='text-end'>a.</p> */}
                </div>
                <div className="col-11">
                    <textarea
                        type="text"
                        placeholder='Enter an answer choice'
                        className='form-control'
                        rows={2}
                        disabled
                    />
                </div>
                {/* <div className="col-1">
                    <div className='d-flex pmbtnf'>
                        <button><AddCircleOutlineIcon /></button>
                        <button><RemoveCircleOutlineIcon /></button>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default TextChoiceQue

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import SearchAll from '../../SearchAll';
import Filter from '../../filter/Filter';
import ConfirmBox from '../ConfirmBox';
import Applications from './Applications';
import AlertBox from '../../AlertBox';
import NoDataFound from '../../NoDataFound';
import { filterResourcesByTags } from '../../../utils/constant';


const NewTeamSubscriptionList = ({ type }) => {

  const [selectApp, setSelectApp] = useState([]);
  const [loading, setLoading] = useState(true)
  const [selectedResources, setSelectedResources] = useState([]);

  const [showConfirmBox, setShowConfirmBox] = useState(false);

  const [applications, setApplications] = useState([]);

  const [showRemoveBtn, setShowRemoveBtn] = useState(false);

  const [activeApp, setActiveApp] = useState('Applications');

  const [resources, setResources] = useState([]);

  const [filterList, setFilterList] = useState([]);

  const [filterResourceList, setFilterResourceList] = useState([]);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const [search, setSearch] = useState('');

  const [visible, setVisible] = useState(false);

  const [viewResources, setViewResources] = useState(null);

  const moduleFilterData = useSelector((state) => state.moduleFilterSlice);

  const handleCloseConfirmBox = () => setShowConfirmBox(false);

  const handleShowConfirmBox = () => setShowConfirmBox(true);

  const { uuid } = useParams();

  const navigate = useNavigate();

  const configData = useSelector((state) => state.configSlice);

  const userData = useSelector((state) => state.userSlice);

  const permissions = userData.user.permissions;

  const getResources = async () => {
    try {
      const getResourceList = await axios.get(`${configData.config.UNO_URL}/api/resource/all/${type}/${uuid}`, {
        headers: { Authorization: `Bearer ${userData.token}` },
      });
      setResources(getResourceList.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getApplications = async () => {
    try {
      const getApplicationList = await axios.get(
        `${configData.config.UNO_URL}/api/application/assigned/${type}/${uuid}`,
        {
          headers: { Authorization: `Bearer ${userData.token}` },
        }
      );

      setApplications(getApplicationList.data.data);
      setLoading(false)
    } catch (error) {
      console.error(error);
    }
  };

  const toggleApplication = (item) => {
    setSelectApp(prevState => prevState.includes(item) ? prevState.filter((e) => e.id !== item.id) : [...prevState, item]) 
  };

  const toggleResource = (item) => {
    setSelectedResources(prevState => prevState.includes(item) ? prevState.filter((e) => e.id !== item.id) : [...prevState, item])
  };

  const handleDelete = async () => {
    try {
      const applicationUuids = selectApp.map((item, index) => item.uuid);
      const resourcesUuids = selectedResources.map((item, index) => item.uuid);

      const payload = {
        applications: applicationUuids,
        resources: resourcesUuids,
      };

      const response = await axios.post(
        `${configData.config.UNO_URL}/api/allocation/delete/${type}/${uuid}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );

      if (response.status === 200) {
        const responseData = response.data;
        handleCloseConfirmBox(false);
        setSelectApp([]);
        setSelectedResources([])

      } else {
        console.error('API request failed');
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const handleVisible = () => {
    handleShowConfirmBox(true);
  };
  const resetMyObject = () => {
    setViewResources(null);
    setFilterResourceList([])
    setSelectedResources([])
  };

  useEffect(() => {
    getResources();
    getApplications();

  }, [uuid, showConfirmBox]);

  useEffect(() => {
    setSelectApp([]);
  }, []);

  const getAllTags = async (filterId) => {
    try {
      setFilterList([]);
        const {data} = await axios.get(`${configData.config.UNO_URL}/api/tag/list/${filterId}`,  {
          headers: { Authorization: `Bearer ${userData.token}` },
        });  
      setFilterList(data?.data?.children || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(()=>{
         if(viewResources && viewResources?.resourceTypeId){
          const list = resources.filter((e) => e.typeId === viewResources.resourceTypeId)
          setFilterResourceList(list);
          switch (viewResources.resourceType.type) {
            case "MODULE":
              getAllTags(configData.config.moduleFilterId);
              break;
            case "ENVIRONMENT":
              getAllTags(configData.config.environmentFilterId);
              break;
            case "2D":
              getAllTags(configData.config.skillStream2DFilterId);
              break;
            default:
              console.error("Invalid type: ", type);
          }
        }else{
          setFilterResourceList([]);
          setFilterList([]);
         }
  },[viewResources]);


        let resourceList = filterResourcesByTags(filterResourceList, selectedCheckboxes);
            resourceList = resourceList?.filter((e) => {if(search && !e.name.toLowerCase().includes(search.toLowerCase())) return false; return true;});

        const handleSelectAllResource = (status) =>{
          if(status){
            setSelectedResources((prevItems) => {
              return [...prevItems.filter((item) => !resourceList.some((i) => i.uuid === item.uuid))];
            });
            return;
          }

          setSelectedResources((prevItems) => {
                return [...prevItems, ...resourceList.filter((item) => !prevItems.some((i) => i.uuid === item.uuid))];
            });
            }
          

  return (
    <>
      { !loading ?
        <>
      {applications.length > 1 ? (
        <>
          <div className="teamsubscription mt-2">
            <div className="row">
              {userData?.user?.group?.uuid !== uuid ? (
                <div className="col-md-2 filterredclear" style={{ justifyContent: 'end' }}>
                  {showRemoveBtn ? (
                    <>
                      <button> Remove </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row mt-0 position-relative">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      {viewResources ? (
                        <div className="Appbox headbox">
                          <button className="back">
                            <KeyboardBackspaceOutlinedIcon onClick={resetMyObject} />
                          </button>
                          <div className="appthumb">
                            <img src={viewResources.icon} alt="" />
                          </div>
                          <div className="appdata">
                            <div className="apptitle withresource">
                              <span>{viewResources.name}</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p style={{ fontWeight: '500', fontSize: '15px', margin: '0' }}>Assigned Applications</p>
                      )}
                      {selectedResources.length ? (
                        (!permissions.includes("RESOURCE.ASSIGNMENT.REVOKE") ? null : <button
                          style={{ minWidth: 'fit-content' }}
                          className="createbtn ms-3"
                          onClick={() => handleVisible()}
                        >
                          Unassign Resources
                        </button>)
                      ) : selectApp.length ? (
                        (!permissions.includes("RESOURCE.ASSIGNMENT.REVOKE") ? null : <button
                          style={{ minWidth: 'fit-content' }}
                          className="createbtn ms-3"
                          onClick={() => handleVisible()}
                        >
                          Unassign Applications
                        </button>)
                      ) : null}
                    </div>
                    <div className="d-flex ms-2">
                      <SearchAll search={search} setSearch={setSearch} placeholder={'Search...'} />
                      {viewResources ? (
                        <div className="ms-2">
                          <Filter
                            selectedCheckboxes={selectedCheckboxes}
                            setSelectedCheckboxes={setSelectedCheckboxes}
                            filterOptions={filterList}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <Applications
                  applications={applications}
                  toggleApplication={toggleApplication}
                  selectApp={selectApp}
                  search={search}
                  resources={resourceList}
                  toggleResource={toggleResource}
                  selectedResources={selectedResources}
                  viewResources={viewResources}
                  setViewResources={setViewResources}
                  handleSelectAllResource={handleSelectAllResource}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <NoDataFound heading={'No Data Found'} />
        </>
      )}
      </>
      :
      <>
      <div className='row validity mt-3'>
      <p>  <Skeleton 
            width="25%" 
            style={{ lineHeight: "normal" }} 
            /></p>
        {[...Array(5)].map((_, index) => (
                  <div className={`col-md-4 py-2`} key={index} >
                  <div className="Appbox" >
                      <div className='appthumb'>
                      <Skeleton width="100%" height="100%" borderRadius={10} style={{ lineHeight:"normal" }} baseColor='#2f3a7900' highlightColor='#fff'  />
                      </div>
                      <div className='appdata'>
                      <div >
                          <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/>
                      
                      </div>
                      </div>
                  </div>
                  </div>
                  ))}
          </div>
      </>
    }
      <ConfirmBox
        title={'Remove Resources'}
        message={'Would you really like to remove the selected resources from this group?'}
        show={showConfirmBox}
        onHide={handleCloseConfirmBox}
        handleConfirm={handleDelete}
      />
      <AlertBox mainmsg={'UnAssigned Succesfully'} show={visible} onHide={() => setVisible(false)} />
    </>
  );
};

export default NewTeamSubscriptionList;

import React, { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField } from '@mui/material';
import { CloseIcon } from '../../../SvgIconsData';

const CreateFeedback = ({ show, handleClose }) => {
  const navigate = useNavigate();
  const [formLoading, setFormLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackId, setFeedBackId] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const noHtmlTags = /^(?!.*<.*?>).*$/;

  const validationSchema = Yup.object().shape({
    title: Yup.string().matches(noHtmlTags, 'Invalid title').required('Title is required'),
    description: Yup.string().matches(noHtmlTags, 'Invalid description').required('Description is required'),
  });

  const options = [
    { value: 'FEEDBACK', label: 'Feedback' },
    { value: 'SURVEY', label: 'Survey' },
  ];

  const initialValues = {
    title: '',
    description: '',
  };

  const configData = useSelector((state) => state.configSlice);

  const userData = useSelector((state) => state.userSlice);

  const handleSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);
    setFormLoading(true)
    try {
      const payload = {
        name: values.title,
        description: values.description,
        type: selectedOption.value,
      };

      const response = await axios.post(`${configData.config.UNO_URL}/api/feedback/create`, payload, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      });

      resetForm();

      handleClose();
      setFormLoading(false)
      setFeedBackId(response.data.data.uuid);
    } catch (error) {
      console.log(error.message);
      setFormLoading(false)
    } finally {
      setIsSubmitting(false);
      setFormLoading(false)
    }
  };
  const navigateIfFeedbackIdExists = useCallback(() => {
    if (feedbackId) {
      navigate(`/dashboard/feedback/create/${feedbackId}`);
    }
  }, [feedbackId, navigate]);

  useEffect(() => {
    navigateIfFeedbackIdExists();
  }, [feedbackId, navigateIfFeedbackIdExists]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };
  const handleBlur = (e) => {
    const { name } = e.target;
    formik.setFieldTouched(name, true);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  function debounce(func, wait) {
    let timeout;
    return function(...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  const handleFormSubmit = debounce(formik.handleSubmit, 700);

  return (
    <>
      <Modal centered show={show} onHide={handleClose} className="createfeedback">
        <Modal.Header className='modalheader text-center'>
          Create Feedback | Survey                     
           <span onClick={handleClose}>
                <CloseIcon/>
            </span>

        </Modal.Header>
        <Modal.Body className="p-4">
          
          
            <div className='createform'>
            <div className="form-group with-select">
              <p>Select Type  <span> * </span></p>
              <Select 
              className="single-select"
              classNamePrefix="react-select"
              menuPlacement="auto"
              maxMenuHeight={200}
              menuPosition={'fixed'}
              theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                colors: {
                ...theme.colors,
                primary50 : '#ca373c50',
                primary25 : '#ca373c50',
                primary: '#ca373c70',
                danger :'#000000',
                neutral30: '#00000050'
                },
              })}
              styles={{
                control: (styles) => ((formik.touched.role && Boolean(formik.errors.role)) ? {
                    ...styles,
                    borderColor: 'red'
                } : { ...styles })
                
              }}
              options={options}
              placeholder="Select Type" 
              onChange={setSelectedOption} />
            </div>
            <div className="form-group w-100">
              <p> Title <span> * </span></p>
              <TextField
                type="text"
                id="title"
                className='w-100'
                placeholder="Enter your title"
                name="title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={formik.values.title}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="text-danger validatetextds">{formik.errors.title}</div>
              ) : null}
            </div>
            <div className="form-group w-100">
              <p>Description</p>
              <textarea
                id="description"
                type="text"
                className={`form-control ${
                  formik.touched.description && formik.errors.description ? 'is-invalid' : ''
                }`}
                placeholder="Enter your description"
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={formik.values.description}
                rows={4}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="text-danger validatetextds">{formik.errors.description}</div>
              ) : null}
            </div>
            </div>

                            <div className='modal-footer-btn mt-4 mb-1'>
                            <button className='createbtn cancelbtn' onClick={handleClose}>
                                Cancel
                            </button>
                            { !formLoading ? 
                            <button className='createbtn ' onClick={handleFormSubmit} disabled={isSubmitting}>
                                Create
                            </button>
                            :
                            <button className="loading save">
                                <svg viewBox="25 25 50 50">
                                    <circle r="20" cy="50" cx="50">{""}</circle>
                                </svg>
                            </button>
                            }       
                            </div>
        
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateFeedback;

import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
// import sicon from '../../../assets/img/sicon.png';

const FilterSearch = ({ placeholder,setSearchQuery,searchQuery }) => {
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    return (
        <>
            <div className='search form-group'>
                <span><SearchIcon /> </span>
                <input
                    type="text"
                    placeholder={placeholder}
                    className='form-control'
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </div>
        </>
    )
}

export default FilterSearch

import React from 'react'
import { useDropzone } from 'react-dropzone'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import * as Papa from 'papaparse'


const CSVUploadDropZone = ({ name, onFileUpload, accept, text }) => {
    
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop: async (acceptedFiles) => {
            const file = acceptedFiles[0];
            Papa.parse(file, {
                complete: (results) => {
                    onFileUpload({ data: results.data, name, fields: results.meta.fields });
                },
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
            });
        },
        maxFiles: 1,
        multiple: false,
        accept: '.csv'
    });


    return (
        <>
            <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <FileUploadOutlinedIcon />
                <p>{text}</p>
            </div>

        </>
    )
}

export default CSVUploadDropZone

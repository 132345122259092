import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useSelector } from "react-redux";
import axios from 'axios';
import { TextField, Button } from '@mui/material';
import { changeUserPasswordValidationSchema } from './validation';
import { CloseIcon } from '../../SvgIconsData';


const ChangeUsersPassword = ({ showUser, closeChangePasswordModal, id }) => {
    const [userUUID, setUserUUID] = useState(null);
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [formLoading, setFormLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

useEffect(()=>{
    if(id) setUserUUID(id);
},[id])

    const formik = useFormik({
        initialValues: {
             password:"",
             confirmPassword:""
        },
        validationSchema: changeUserPasswordValidationSchema,
        onSubmit: async (values) => {
            const data = {
                password: values.password,
                confirmPassword: values.confirmPassword,
            }
            try {
                setFormLoading(true)
                const resData = await axios.post(`${configData.config.UNO_URL}/api/user/change/password/${userUUID}`, data, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                        "Content-Type ": "application/json"
                    }
                });

                if (resData.data.status) {
                    formik.resetForm();
                    setSuccess("Password changed successfully." );
                    setTimeout(()=> {
                        setSuccess("" );
                        closeChangePasswordModal();
                        setFormLoading(false)
                    } , 2000)
                }

            } catch (error) {
                console.log(error);
                setError(error.response.data.message || "Something went wrong, try after some time." );
                setTimeout(()=> setError("") , 5000)
                setFormLoading(false)
            }
        }
    });

    const handleChangeField = async(e) =>{
        formik.setFieldValue(e.target.name, e.target.value)
    }

    function debounce(func, wait) {
        let timeout;
        return function(...args) {
          const context = this;
          clearTimeout(timeout);
          timeout = setTimeout(() => func.apply(context, args), wait);
        };
      }


    const handleFormSubmit = debounce(formik.handleSubmit, 700);


    return (
        <>
            <Modal centered size='md' show={showUser} onHide={closeChangePasswordModal}>
                
            <Modal.Header className='modalheader text-center'>
                        Change Password
                        <span onClick={closeChangePasswordModal}>
                            <CloseIcon/>
                        </span>
                    </Modal.Header>
               
                <Modal.Body>
                    <div className="modalcont">
                            <div className='createform '>
                                <div className="form-group">
                                    <p>New password <span> * </span></p>
                                    <TextField
                                        name="password"
                                        type="password"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Enter password"
                                        size="small"
                                        value={formik.values.password}
                                        onChange={handleChangeField}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                    />
                                </div>

                                <div className="form-group">
                                    <p>Confirm new password  <span> * </span></p>
                                    <TextField
                                        name="confirmPassword"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Confirm password"
                                        size="small"
                                        value={formik.values.confirmPassword}
                                        onChange={handleChangeField}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                    />
                                </div>
                                <div className="form-group">
                                     {error && <p className='errormessage'>{error}</p>}
                                     {success && <p className='successmessage'>{success}</p>}
                                </div>
                            </div>

                            <div className='modal-footer-btn mt-4 mb-1'>
                            <button className='createbtn cancelbtn' onClick={closeChangePasswordModal}>
                                Cancel
                            </button>
                            { !formLoading ? 
                            <button className='createbtn ' onClick={handleFormSubmit}>
                                Update
                            </button>
                            :
                            <button className="loading save">
                                <svg viewBox="25 25 50 50">
                                    <circle r="20" cy="50" cx="50">{""}</circle>
                                </svg>
                            </button>
                            }       
                            </div>

                        </div>
                   
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChangeUsersPassword

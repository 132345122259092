import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom'

const BackBtn = ({ text }) => {
    const navigate = useNavigate()
    return (
        <>
            <span style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}><KeyboardBackspaceIcon />{text} </span>
        </>
    )
}

export default BackBtn

import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const FilterDateRange = ({selectionRange,setSelectionRange}) => {



  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  return (
    <>
      <div className="daterangefdf">
        <div className="card">
          <div className="card-body border rounded-2 p-2">
            <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} rangeColors={['#ca373c']} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterDateRange;

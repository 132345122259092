import {
  CalenderIcon,
  ConfigIcon,
  FeedbackSurveyIcon,
  HomeIcon,
  OneView,
  PrivacyIcon,
  ProfileIcon,
  SettingIcon,
  UsersIcon,
} from '../../SvgIconsData';

const MenuItems = [
  {
    id: 1,
    icon: <HomeIcon />,
    url: '/dashboard/home',
    menuName: 'Home',
    permission: null,
  },
  {
    id: 2,
    icon: <UsersIcon />,
    url: '/dashboard/team-management',
    menuName: 'Team Management',
    permission: 'TEAM.VIEW',
  },
  {
    id: 3,
    icon: <CalenderIcon />,
    url: '/dashboard/subscriptions',
    menuName: 'Subscriptions',
    permission: 'RESOURCE.SUBSCRIPTION.VIEW',
  },
  {
    id: 4,
    icon: <ProfileIcon />,
    url: '/dashboard/roles',
    menuName: 'Roles',
    permission: "TEAM.ROLES.VIEW"
    // permission: null,
  },
  {
    id: 5,
    icon: <FeedbackSurveyIcon />,
    url: '/dashboard/feedback',
    menuName: 'Feedback & Survey',
    // permission: "TEAM.ROLES.VIEW"
    permission: 'GENERAL.FEEDBACK',
  },
  // {
  //     id: 5,
  //     icon: <SettingIcon />,
  //     url: '/dashboard/S',
  //     menuName: "Settings",
  // },
  // {
  //     id: 6,
  //     icon: <OneView />,
  //     url: '/dashboard/O',
  //     menuName: "One View",
  // },
  // {
  //     id: 7,
  //     icon: <ConfigIcon />,
  //     url: '/dashboard/C',
  //     menuName: "Configurations",
  // },
  // {
  //     id: 8,
  //     icon: <PrivacyIcon />,
  //     url: '/dashboard/p',
  //     menuName: "Privacy & Compliance",
  // },
];

export default MenuItems;

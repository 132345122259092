import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import { Thumbnail } from '../thumbnail';
import { EditIcon } from '../../SvgIconsData';
import EditUsers from './editUsers';
import NoDataFound from '../NoDataFound';
import UserCardTooltp from '../tooltip/UserCardTooltp';

const UserCard = ({ refresh, usersList, search, permissions }) => {
    const navigate = useNavigate();
    const [flippedId, setFlippedId] = useState(null);
    const [list, setList] = useState([]);
    const [editData, setEditData] = useState({})
    const [showUserModal, setShowUserModal] = useState(false);

    useEffect(() => {
        setList([...usersList]);
    }, [usersList])

    const flipBtn = (id) => {
        if (flippedId === id) {
            setFlippedId(null);
        } else {
            setFlippedId(id);
        }
    }

    const openCreateUserModal = (e, event) => {
        event.stopPropagation();
        setEditData({ ...e })
        setShowUserModal(true);
    };

    const closeCreateUserModal = () => {
        setShowUserModal(false);
    };

    const navigateToUser = (userUUid, event) => {
        event.stopPropagation()
        navigate(`/dashboard/team-management/user/${userUUid}`);
    }

    return (
        <>
            <div className="row userndgrouptabs">
                {list.length === 0 ? (
                    <>
                        <NoDataFound heading="No Users Yet!!" nodatatext="Add a new user in this group using Create User Button on top." />
                    </>
                ) :
                    list.map((f, i) => {
                        if (!f.name?.toLowerCase().includes(search?.toLowerCase())) { return null }
                        const roleallocation = f.roleAllocations.length > 1 ? f.roleAllocations.filter(e => e.role.type !== 'DEFAULT')[0] : f.roleAllocations[0];
                        // const { role } = roleallocation;
                        const role = roleallocation?.role ? roleallocation.role : null;
                        return (
                            <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-6 mt-0 lefrightcolspacing' key={i}>
                                <div className={flippedId === f.id ? "flip-card backside" : "flip-card"}>
                                    <div className="flip-card-inner" onClick={(event) => navigateToUser(f.uuid, event)}>
                                        <div className="flip-card-front">
                                            <div className='groupcard userprofileimg' >
                                                <div className='groupbody'>
                                                {/* <span className='inactive-user'>Inactive</span> */}
                                                    <div className='grouplogo outline-none'>
                                                        <Thumbnail path={f?.avatar || ""} name={f?.name || ""} />
                                                        { f.active ?
                                                            <Tooltip arrow TransitionComponent={Fade} title="Active">
                                                            <span className='userActive'>{""}</span>
                                                            </Tooltip>
                                                        :
                                                            <Tooltip arrow TransitionComponent={Fade} title="Inactive">
                                                            <span className='userInactive'>{""}</span>
                                                            </Tooltip>
                                                        }

                                                    </div>

                                                    <div className='groupdetails'>
                                                        <div className='d-flex align-items-center' onClick={(event) => !permissions.includes("TEAM.USERS.CREATE") ? null : openCreateUserModal(f, event)}>
                                                            <p className='cn'>{f.name}</p>
                                                            {!permissions.includes("TEAM.USERS.CREATE") ? null : <span className='edit'> <EditIcon /> </span>}
                                                        </div>
                                                        {role && <p className='gn' style={{ backgroundColor: `${role.color}10`, color: role.color }}>{role?.name}</p>}
                                                        <p className='post'>{f.designation}</p>
                                                        <p className='email'>{f.email}</p>
                                                        <p className='post'>{f.uid}</p>

                                                        {/* <p className='post'>{f.post}<ManageAccountsIcon /></p> */}
                                                        {/* <p className='lastaccessed'>Last Accessed <span className='px-1'>{f.date}</span> <span className='px-1'>{e.time}</span> </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        )
                    }
                    )}
            </div>

            <EditUsers refresh={refresh} showUser={showUserModal} CreateUserModalClose={closeCreateUserModal} editUserData={editData} />
        </>
    )



}

export default UserCard

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios"
import Select, { StylesConfig } from 'react-select'
import { styled } from '@mui/material/styles';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Checkbox } from '@mui/material';
import { CloseIcon, DeleteIcon } from '../../SvgIconsData';
import { createGroupValidationSchema } from './validation';
import DropZone from '../dropzone/DropZone';
import sicon from '../../assets/img/sicon.png';
import signature from '../../assets/img/signature.png';
import { Thumbnail } from '../thumbnail';

const CreateGroup = ({ show, handleClose, refresh }) => {
    const dispatch = useDispatch();
    const [groupError, setGroupError] = useState(null);
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [showPassword, setShowPassword] = useState(false);
    const [formLoading, setFormLoading] = useState(false)
    // const [groupType , setGroupType] = useState({value : 0 , label : "Choose Group Type"});
    const params = useParams();
    const UUID = params?.uuid;

    const formik = useFormik({
        initialValues: {
            groupname: "",
            type: 0,
            address: "",
            groupType: null,
            role: null,
            name: "",
            password: "",
            email: "",
            phone: "",
            uid: "",
            designation: "",
            logo: "",
            avatar: "",
            loginType: "1",
            createUser: false
        },

        validationSchema: createGroupValidationSchema,

        onSubmit: async (values) => {

            const group = {
                name: values.groupname.trim(),
                avatar: values?.logo.trim() || null,
                typeId: values.groupType.value
            }

            let data = {
                group,
            }

            if (values.createUser === true) {
                const user = {
                    name: values.name.trim() || null,
                    avatar: values.avatar.trim() || null,
                    designation: values.designation.trim() || null,
                    email: values.loginType === "1" ? values.email.trim() || null : null,
                    uid: values.loginType === "2" ? values.uid.trim() || null : null,
                    password: values.loginType === "2" ? values.password.trim() || null : null,
                    phone: values.phone.trim() || null,
                    role : values.role.value 
                }
                data = { ...data, user };
            }

            try {
                setFormLoading(true)
                const resData = await axios.post(`${configData.config.UNO_URL}/api/user/group/create/with-admin/${configData.config.applicationId}/${UUID}`, data, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                        "Content-Type ": "application/json"
                    }
                });

                if (resData?.data?.data?.name) {
                    refresh();
                    setTimeout(() => {
                        handleClose();
                        formik.resetForm();
                    }, 500)
                    setFormLoading(false)
                }


            } catch (error) {
                console.error(error);
                setGroupError(error?.response?.data?.message);
                setTimeout(()=> setGroupError(null) , 1000)
            }
        }
    });

 

    const handleFileUpload = async (data) => {
        try {
            if (data?.name === "avatar") { formik.setFieldValue('avatar', data?.file) }
            else if (data?.name === "logo") { formik.setFieldValue('logo', data?.file) }
        } catch (error) {
            console.log(error);
        }
    };



    const handleModalClose = () => {
        formik.resetForm();
        handleClose();
    }

    const handleChangeField = (e) => {
        if (e.target.name === "email" || e.target.name === "uid" || e.target.name === "password") {
            const value = e.target.value.replace(/\s/g, '');
            formik.setFieldValue(e.target.name, value)
        } else if (e.target.name === "phone") {
            const numericValue = e.target.value.replace(/\D/g, '');
            formik.setFieldValue("phone", numericValue);
        } else {
            formik.setFieldValue(e.target.name, e.target.value.replace(/\s+/g, ' '))
        }
    }
    const options = configData.groupTypes.map((e) => ({ value: e.id, label: e.name }));
    const roleoptions = configData.roles.map((e) => ({ value: e.id, label: e.name }));
    useEffect(() => {
        const defaultrole = configData.roles.filter((e) => e.type === 'DEFAULT').map((e) => ({ value: e.id, label: e.name }));
        formik.setFieldValue('role' , defaultrole[0]);
    }, [show]);

    function debounce(func, wait) {
        let timeout;
        return function(...args) {
          const context = this;
          clearTimeout(timeout);
          timeout = setTimeout(() => func.apply(context, args), wait);
        };
      }

    const handleFormSubmit = debounce(formik.handleSubmit, 700);
    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        marginRight: 10,
        boxShadow:
          theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
          theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
        },
        'input:hover ~ &': {
          backgroundColor: theme.palette.mode === 'dark' ? '#ca373c' : '#ca373c',
        },
        'input:disabled ~ &': {
          boxShadow: 'none',
          background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
      }));
      
      const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#ca373c',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&::before': {
          display: 'block',
          width: 16,
          height: 16,
          backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
          content: '""',
        },
        'input:hover ~ &': {
          backgroundColor: '#ca373c',
        },
      });


    function BpRadio(props) {
        return (
          <Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
          />
        );
      }

    return (
        <>
             <Modal size="md" centered show={show} onHide={handleModalClose}>
             <Modal.Header className='modalheader text-center'>
                        Create Group
                        <span onClick={handleModalClose}>
                            <CloseIcon/>
                        </span>

                    </Modal.Header>
                <Modal.Body>
                    
                        {/* <div className="modalheader">
                            <span className="cursor-pointer text-muted" >
                                Cancel
                            </span>
                            <p className="mb-0">Create Group</p>

                            <Button variant="text" onClick={handleFormSubmit}> Create </Button>

                        </div> */}
                        <div className="modalcont mt-2">
                            <div className="row">
                                <div className="col d-flex justify-content-center gap-3">
                                    <div className='profile-Logo'>
                                        {formik.values.logo?.length > 0 ?
                                            <div className='groupEditLogo position-relative '>
                                                <Thumbnail path={formik.values.logo || ""} name={""} />
                                                <span className=' closeicon position-absolute'  onClick={() => formik.setFieldValue('logo', null)} >
                                                    <DeleteIcon/>
                                                </span>
                                            </div>
                                            :
                                            <DropZone text="Logo" name="logo" img={sicon} onFileUpload={handleFileUpload} />
                                        }
                                        <p>Logo</p>
                                    </div>

                                    {formik.values.createUser === true ?
                                        <div className='profile-Logo'>
                                            {formik.values.avatar?.length > 0 ?
                                                <div className='groupEditLogo position-relative '>
                                                    <Thumbnail path={formik.values.avatar || ""} name={""} />
                                                    <span className='closeicon position-absolute' onClick={() => formik.setFieldValue('avatar', null)} >
                                                        <CloseIcon />
                                                    </span>
                                                </div>
                                                :
                                                <DropZone text="Avatar" name="avatar" img={signature} onFileUpload={handleFileUpload} />
                                            }
                                            <p>Profile</p>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>

                            <div className='createform'>
                                <div className="form-group">
                                    <p>Group Name <span> * </span></p>
                                    <TextField
                                        name="groupname"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Enter Group Name"
                                        size="small"
                                        value={formik.values.groupname}
                                        onChange={handleChangeField}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.groupname && Boolean(formik.errors.groupname)}
                                        helperText={formik.touched.groupname && formik.errors.groupname}
                                    />
                                </div>

                                <div className="form-group with-select">
                                    <p>Group Type</p>
                                    <Select
                                    className="single-select"
                                    classNamePrefix="react-select"
                                    menuPlacement="auto"
                                    maxMenuHeight={200}
                                    menuPosition={'fixed'}
                                    // menuPortalTarget={document.body}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        colors: {
                                        ...theme.colors,
                                        primary50 : '#ca373c50',
                                        primary25 : '#ca373c50',
                                        primary: '#ca373c70',
                                        danger :'#000000',
                                        neutral30: '#00000050'
                                        },
                                    })}
                                    styles={{
                                        control: (styles) => ((formik.touched.groupType && Boolean(formik.errors.groupType)) ? {
                                            ...styles,
                                            borderColor: 'red'
                                        } : { ...styles })
                                    }} options={options} placeholder="Select Type" value={formik.values.groupType} onChange={(e) => formik.setFieldValue('groupType', e)} />
                                    {formik.touched.groupType ? <p className='text-danger ps-3 mt-1' style={{ fontSize: "0.75rem" }} >{formik.errors.groupType}</p> : null}
                                </div>


                                <div className='form-group only-check'>
                               
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formik.values.createUser}
                                                onChange={(e) =>
                                                    formik.setFieldValue('createUser', !formik.values.createUser)
                                                }
                                                name='createUser'
                                                id='createUser'
                                            />
                                        }
                                    />
                                     <p>Create a user with this group</p>
                                </div>

                                {formik.values.createUser === true ?
                                    <>
                                        <div className="form-group">
                                        <p>Name  <span> * </span></p>
                                            <TextField
                                                name="name"
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Enter Name*"
                                                size="small"
                                                value={formik.values.name}
                                                onChange={handleChangeField}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.name && Boolean(formik.errors.name)}
                                                helperText={formik.touched.name && formik.errors.name}
                                            />
                                        </div>

                                        <div className="form-group with-select">
                                            <p>User Role</p>
                                        <Select
                                        className="single-select"
                                        classNamePrefix="react-select"
                                        menuPlacement="auto"
                                        maxMenuHeight={200}
                                        menuPosition={'fixed'}
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 5,
                                                colors: {
                                                ...theme.colors,
                                                primary50 : '#ca373c50',
                                                primary25 : '#ca373c50',
                                                primary: '#ca373c70',
                                                danger :'#000000',
                                                neutral30: '#00000050'
                                                },
                                            })}
                                            styles={{
                                                control: (styles) => ((formik.touched.role && Boolean(formik.errors.role)) ? {
                                                    ...styles,
                                                    borderColor: 'red'
                                                } : { ...styles })
                                            }} options={roleoptions} placeholder="Select Role" value={formik.values.role} onChange={(e) => formik.setFieldValue('role', e)} />
                                            {formik.touched.role ? <p className='text-danger ps-3 mt-1' style={{ fontSize: "0.75rem" }} >{formik.errors.role}</p> : null}
                                        </div>

                                        <FormControl className='select-option-radio' component="fieldset">
                                        <p className='mb-2'>Login Type :</p>
                                            <RadioGroup
                                                aria-label="options"
                                                name="options"
                                                value={formik.values.loginType}
                                                onChange={(e) => formik.setFieldValue("loginType", e.target.value)}
                                            >
                                                <div className='radio-options ms-3'>
                                                    <FormControlLabel value="1" control={<BpRadio />} label="Email" />
                                                    <FormControlLabel value="2" control={<BpRadio />} label="UID / PASSWORD" />
                                                </div>
                                            </RadioGroup>
                                        </FormControl>

                                        {formik.values.loginType === "1" ?

                                            <div className="form-group mt-2">
                                                <p>Email  <span> * </span></p>
                                                <TextField
                                                    name="email"
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Enter Email*"
                                                    size="small"
                                                    value={formik.values.email}
                                                    onChange={handleChangeField}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                                    helperText={formik.touched.email && formik.errors.email}
                                                />
                                            </div>

                                            : null}

                                        {formik.values.loginType === "2" ?
                                            <>
                                                <div className="form-group mt-2">
                                                <p>UID  <span> * </span></p>
                                                    <TextField
                                                        name="uid"
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="Enter uid*"
                                                        size="small"
                                                        value={formik.values.uid}
                                                        onChange={handleChangeField}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.uid && Boolean(formik.errors.uid)}
                                                        helperText={formik.touched.uid && formik.errors.uid}
                                                    />
                                                </div>
                                                <div className="form-group position-relative">
                                                    <p>Password  <span> * </span></p>
                                                    <TextField
                                                        name="password"
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="Enter password*"
                                                        size="small"
                                                        type={showPassword ? 'text' : 'password'}
                                                        value={formik.values.password}
                                                        onChange={handleChangeField}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                                        helperText={formik.touched.password && formik.errors.password}
                                                    />
                                                    <span className='vieweye' onClick={() => setShowPassword(!showPassword)}>
                                                        {showPassword ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                                                    </span>
                                                </div>
                                            </>
                                            : null}

                                        {groupError && <p className='errormessage' >{groupError}</p>}

                                        <div className="form-group">
                                            <p>Phone</p>
                                            <TextField
                                                name="phone"
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Enter phone"
                                                size="small"
                                                type='tel'
                                                inputProps={{ maxLength: 15 }}
                                                value={formik.values.phone}
                                                onChange={handleChangeField}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                                helperText={formik.touched.phone && formik.errors.phone}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <p>Designation</p>
                                            <TextField
                                                name="designation"
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Enter Designation"
                                                size="small"
                                                value={formik.values.designation}
                                                onChange={handleChangeField}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.designation && Boolean(formik.errors.designation)}
                                                helperText={formik.touched.designation && formik.errors.designation}
                                            />
                                        </div>
                                    </>
                                    : null}

                            </div>

                            <div className='modal-footer-btn mt-4 mb-1'>
                            <button className='createbtn cancelbtn' onClick={handleModalClose}>
                                Cancel
                            </button>
                            { !formLoading ? 
                            <button className='createbtn ' onClick={handleFormSubmit}>
                                Create
                            </button>
                            :
                            <button className="loading save">
                                <svg viewBox="25 25 50 50">
                                    <circle r="20" cy="50" cx="50">{""}</circle>
                                </svg>
                            </button>
                            }       
                            </div>

                        </div>
               
                </Modal.Body>
            </Modal> 
            {/* <Modal size="md" centered show={show} onHide={handleModalClose}>
              <Modal.Header className="modalheader">
                    Create Group
                    <span onClick={handleModalClose}>
                    <CloseIcon />
                    </span>
                </Modal.Header>
              <Modal.Body>
              <div className='mx-2'>
                    {formik.values.logo?.length > 0 ?
                        <div className='groupEditLogo position-relative '>
                            <Thumbnail path={formik.values.logo || ""} name={""} />
                            <p className='mt-2 text-center'>Logo</p>
                            <span className=' closeicon position-absolute' style={{ right: '-15px', top: '-5px', cursor: 'pointer' }} onClick={() => formik.setFieldValue('logo', null)} >
                                <CloseIcon />
                            </span>
                        </div>
                        :
                        <div className='dropzone-parent'>
                        <DropZone text="Logo" name="logo" img={sicon} onFileUpload={handleFileUpload} />
                        <div className='dropbox-info-btns'>
                        <p className='text-center'>Logo</p>
                        <span> 
                            <button>Upload New</button>
                            <button className='remove'>   Remove</button>
                        </span>    
                        </div>
                        </div>
                    }
                </div>
              </Modal.Body>
            </Modal> */}
        </>
    );
};

export default CreateGroup;

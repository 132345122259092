import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import ReplyModal from './ReplyModal';
import UserModal from './UserModal';
import { SettingIcon } from '../../../SvgIconsData';
import FilterDateRange from './FilterDates/FilterDateRange';
import ProPopup from "../../../layouts/ProPopup";

const FeedbackDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [show, setShow] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [list, setList] = useState([]);
  const [feedback, setFeedBack] = useState({});
  const [response, setResponse] = useState(0);
  const [activeTab, setActiveTab] = useState('summary');
  const [loading, setLoading] = useState(true);
  const skeletonNumbers = [1, 2, 3, 4, 5];
  const [showFilter, setShowFilter] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [openPro, setOpenPro] = useState(false);
  const [userPlan, setUserPlan] = useState(null);

  
  const handleShowResponse = (item) => {
    if(!userPlan?.feedbackAndSurvey?.viewResponse){
      setOpenPro(true);
      return
    }
    setFeedBack(item);
    setShowResponse(true);
    setActiveTab('individual');
  };

  const handleResponseReply = (feedback) => {
    if(!userPlan?.feedbackAndSurvey?.reply){
      setOpenPro(true);
      return
    }
    if(feedback) setResponse(feedback);
    setShow(true)
  };

  const handleCloseResponse = () => {
    setShowResponse(false);
  };

  useEffect(()=>{
    if(userData.plans.data) setUserPlan(userData.plans.data);
  },[userData]);

  const filteredData = list.filter((feedback) => {
    const createdAt = moment(feedback.createdAt);
    return (
      createdAt.isSameOrAfter(selectionRange.startDate, 'day') &&
      createdAt.isSameOrBefore(selectionRange.endDate, 'day')
    );
  });

  const { uuid } = useParams();

  const feedBacks = async (req, res) => {
    try {
      const { data } = await axios.get(
        `${configData.config.UNO_URL}/api/feedback/getFeedbackResponsesByUsers/${uuid}`,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      setLoading(false);
      setList(data.data || []);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const DateRangeOpen = () => {
    setShowFilter(!showFilter);
  };
  useEffect(() => {
    feedBacks();
  }, [uuid, show]);
  return (
    <>
      <div className="bg-grey-main createstions">
        <div className="row">
          <div className="col-md-6">
            <span
              className="cursor-pointer"
              onClick={() =>
                navigate(`/dashboard/feedback/${uuid}`, {
                  state: { key: state?.key || 'analytics' },
                })
              }
            >
              <KeyboardBackspaceIcon />
              Back
            </span>
          </div>
          <div className="col-md-6">
            <div className="text-end">
              <div onClick={DateRangeOpen} className="btn btn-sm btn-danger">
                Filter
              </div>
            </div>
          </div>
        </div>

        <div className="feedbacklist mt-3">
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive workflowtb stickyhead">
                <table className="table align-middle table-nowrap mb-0 p-4">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>List of users</th>
                      <th> Title</th>
                      <th className="text-center" style={{ whiteSpace: 'nowrap', minWidth: '150px' }}>
                        Date & time
                      </th>
                      <th>Responses</th>
                      <th>
                        <SettingIcon />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <>
                        {skeletonNumbers.map((index) => (
                          <tr key={index}>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td className="text-center">
                              <Skeleton />
                              <Skeleton width="70px" height="12px" />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                        ))}
                      </>
                    ) 
                    : 
                    showFilter ? (
                      filteredData.length > 0 &&
                      filteredData.map((feedback, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="d-flex gap-2 align-items-center justify-content-start namewithmail">
                              {feedback.user.avatar ? (
                                <img src={feedback.user.avatar} alt={feedback.user.name} />
                              ) : (
                                <span className="logo-placeholder">
                                  {feedback.user.name.split(' ')[0].charAt(0).toUpperCase()}
                                  {feedback.user.name.split(' ')[1]
                                    ? feedback.user.name.split(' ')[1].charAt(0).toUpperCase()
                                    : ''}
                                </span>
                              )}
                              <div>
                                <p className="line-height1">{feedback.user.name}</p>
                                <p className="font-xsss">{feedback.user.email}</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="feedbackitleff">
                              <OverlayTrigger placement="top" overlay={<Tooltip>{feedback.feedback.name}</Tooltip>}>
                                <p className="m-0">{feedback.feedback.name}</p>
                              </OverlayTrigger>
                            </div>
                          </td>
                          <td className="text-center">
                            <p className="m-0 line-height1">{moment(feedback.createdAt).format('DD/MM/YYYY')}</p>
                            <span className="font-xsss m-0">{moment(feedback.createdAt).format('hh:mm A')}</span>
                          </td>
                          <td>
                            <OverlayTrigger placement="top" overlay={<Tooltip>See individual response</Tooltip>}>
                              <span className="cursor-pointer" onClick={() => handleShowResponse(feedback)}>
                                <MessageOutlinedIcon />
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <div className="feedbackitleff">
                              <OverlayTrigger placement="top" overlay={<Tooltip>{feedback.responseuser}</Tooltip>}>
                                <p className="m-0">{feedback.responseuser}</p>
                              </OverlayTrigger>
                            </div>
                            {feedback.responseuser === null ? (
                              <OverlayTrigger placement="top" overlay={<Tooltip>Click to reply user feedback</Tooltip>}>
                                <span onClick={()=>handleResponseReply(null)} className="text-danger cursor-pointer">
                                  <strong>Reply</strong>
                                </span>
                              </OverlayTrigger>
                            ) : (
                              <span className="text-gray">
                                <strong>Replied</strong>
                              </span>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      list.length > 0 &&
                      list.map((feedback, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="d-flex gap-2 align-items-center justify-content-start namewithmail">
                              {feedback.user.avatar ? (
                                <img src={feedback.user.avatar} alt={feedback.user.name} />
                              ) : (
                                <span className="logo-placeholder">
                                  {feedback.user.name.split(' ')[0].charAt(0).toUpperCase()}
                                  {feedback.user.name.split(' ')[1]
                                    ? feedback.user.name.split(' ')[1].charAt(0).toUpperCase()
                                    : ''}
                                </span>
                              )}
                              <div>
                                <p className="line-height1">{feedback.user.name}</p>
                                <p className="font-xsss">{feedback.user.email}</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="feedbackitleff">
                              <OverlayTrigger placement="top" overlay={<Tooltip>{feedback.feedback.name}</Tooltip>}>
                                <p className="m-0">{feedback.feedback.name}</p>
                              </OverlayTrigger>
                            </div>
                          </td>
                          <td className="text-center">
                            <p className="m-0 line-height1">{moment(feedback.createdAt).format('DD/MM/YYYY')}</p>
                            <span className="font-xsss m-0">{moment(feedback.createdAt).format('hh:mm A')}</span>
                          </td>
                          <td>
                            <OverlayTrigger placement="top" overlay={<Tooltip>See individual response</Tooltip>}>
                              <span className="cursor-pointer" onClick={() => handleShowResponse(feedback)}>
                                <MessageOutlinedIcon />
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <div className="feedbackitleff">
                              <OverlayTrigger placement="top" overlay={<Tooltip>{feedback.reply}</Tooltip>}>
                                <p className="m-0">{feedback.reply}</p>
                              </OverlayTrigger>
                            </div>
                            {feedback.reply === null ? (
                              <OverlayTrigger placement="top" overlay={<Tooltip>Click to reply user feedback</Tooltip>}>
                                <span
                                  onClick={() => handleResponseReply(feedback)}
                                  className="text-danger cursor-pointer"
                                >
                                  <strong>Reply</strong>
                                </span>
                              </OverlayTrigger>
                            ) : (
                              <span className="text-gray">
                                <strong>Replied</strong>
                              </span>
                            )}
                          </td>
                        </tr>
                      ))
                    )}

                    {/* {list.length === 0 && filteredData.map((feedback, index) => (
                      <h1>No Data Found</h1>
                    )
                     } */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <ReplyModal list={list} show={show} setShow={setShow} handleClose={handleClose} response={response} />

        <UserModal show={showResponse} handleClose={handleCloseResponse} feedback={feedback} />

        {showFilter && (
          <>
            <div onClick={DateRangeOpen} className="overlaydf" />
            <FilterDateRange selectionRange={selectionRange} setSelectionRange={setSelectionRange} />
          </>
        )}
      </div>

      <ProPopup openPro={openPro} setOpenPro={setOpenPro} /> 

    </>
  );
};

export default FeedbackDetail;

import React from 'react'
import { useDropzone } from 'react-dropzone'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios'; 

const DropZone = ({ text, name, img, onFileUpload }) => {
    const dispatch = useDispatch();
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const params = useParams();
    const UUID = params?.uuid;
    // const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    //     onDrop: async (acceptedFiles) => {
    //         onFileUpload({file : acceptedFiles , name})
    //     },
    //     maxFiles: 1,
    // });

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop: async (acceptedFiles) => {
            try {

                if (acceptedFiles.length === 1) {
                    const file = acceptedFiles[0]
                    const formData = new FormData();
                    formData.append('file', file);
                    const response = await axios.post(`${configData.config.UNO_URL}/api/user/upload/${name}/${UUID}`, formData, {
                        headers: {
                            Authorization: `Bearer ${userData?.token}`,
                            "Content-Type ": "multipart/form-data"
                        },
                        onUploadProgress: (progressEvent) => {
                            const progress = (progressEvent.loaded / progressEvent.total) * 100;
                            // updateFileProgress(file, Math.floor(progress));
                        }
                    });

                    onFileUpload({ file: response?.data?.url, name });

                } else {
                    console.error('Please select only one file');
                }

            } catch (error) {
                console.error(error);
            }
        },
        maxFiles: 1,
        multiple: false,
    });

    return (
        <>
               
                    <div {...getRootProps()} className="dropzone">
                        <input {...getInputProps()} />
                        <FileUploadOutlinedIcon />
                        <img className='dropzonimg' src={img} alt="" />
                    </div>
                
        </>
    )
}

export default DropZone

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import SquareIcon from '@mui/icons-material/Square';

const FeedbackSubmission = ({ analyticsData }) => {

  const labels = analyticsData?.feedbackCount?.map((e) => e.label) || [];
  const feedBackCounts = analyticsData?.feedbackCount?.map((e) => e.feedbacks) || [];
  const surveyCounts = analyticsData?.feedbackCount?.map((e) => e.surveys) || [];

  const feedBackCountsSum = feedBackCounts.reduce((accum, current) => {
    return accum + current;
  }, 0);

  const SurveyCountsSum = surveyCounts.reduce((accum, current) => {
    return accum + current;
  }, 0);

  const chartData = {
    series: [
      {
        name: 'Feedback',
        type: 'bar',
        data: feedBackCounts || [],
      },
      {
        name: 'Survey',
        type: 'bar',
        data: surveyCounts || [],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: '40%',
          borderRadius: 2,
          borderWidth: 1,
        },
      },
      colors: ['#dc3545', '#000'],
      stroke: {
        show: true,
        width: 1,
        colors: ['transparent'],
      },
      fill: {
        type: 'solid',
        // opacity: [0.35, 1],
      },
      labels: labels || [],
      markers: {
        size: 0,
      },
      yaxis: [
        {
          title: {},
        },
        // {
        //     opposite: true,
        //     title: {},
        // },
      ],
      legend: {
        offsetY: 10,
        horizontalAlign: 'center',
      },
    },
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h6 className="m-0">Submission's</h6>
        <p className="m-0">Total posts : {feedBackCountsSum + SurveyCountsSum} </p>
      </div>
      <div className='d-flex gap-3'>
        <span className="font-xss">
          <SquareIcon className="text-danger rounded-1" style={{ fontSize: '1rem' }} />Feedback : <strong>{feedBackCountsSum || 0}</strong>{' '}
        </span>
        <span className="font-xss">
          <SquareIcon className="text-dark rounded-1" style={{ fontSize: '1rem' }} />Survey : {SurveyCountsSum || 0}
        </span>
      </div>
      <div id="chart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height="260px" />
      </div>
    </>

  );
};

export default FeedbackSubmission;

import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { CloseIcon } from '../assets/icons/iconshere';
import ABG from "../assets/img/pro/abg.png";
import Hp from "../assets/img/pro/hp.png";
import Jj from "../assets/img/pro/jj.png";
import Piramal from "../assets/img/pro/piramal.png";
import Ya from "../assets/img/pro/ya.png";
import Cairn from "../assets/img/pro/cairn.png";
import TuvSud from "../assets/img/pro/tuvsud.png";
import Lupin from "../assets/img/pro/lupin.png";
import Merc from "../assets/img/pro/merc.png";
import ProupDash from "../assets/img/pro/Dashboard.jpg";
import ProupReport from "../assets/img/pro/Report.png";
import ProupKPI from "../assets/img/pro/KPI.png";


const ProPopup = ( { openPro, setOpenPro}) => {

    const body = document.body;
  
     if (openPro) {
        body.classList.add('propopup-active');
      } else {
        body.classList.remove('propopup-active');
      }
    
  return (
    <>

      <Modal centered show={openPro} onHide={() => setOpenPro(false) } className="PopupModal" >

       <div className='pro-detail-box'>
            <div className='detail-side'>
                <div className='text-parent'>
                <div className='text-detail'>
                    <h5> Go Pro to unlock all the features.</h5>
                    <h6>Enhance your Team's Performance with Comprehensive Insights.</h6>
                </div>
                <div className='text-list-detail'>
                    <ul>
                        <li>
                            <strong> Team Analytics </strong>  Gain deep insights into your team's progress and performance.
                        </li>
                        <li>
                            <strong> Real-time Monitoring </strong> Track your team's activities in real-time for swift decision-making.
                        </li>
                        <li>
                            <strong> Custom Dashboard </strong> Customize your dashboard to align with your team's unique needs.
                        </li>
                        <li>
                            <strong> Interactive Visuals </strong> Dive into interactive charts for in-depth analysis of team data.
                        </li>
                    </ul>
                </div>
                <span className='d-flex align-items-center justify-content-start w-100'>
                <button className='pro-btn'>Upgrade to Pro</button>
                </span>
                </div>
                   <div className='bottom'>
                <div className='company-text'>
                Used by <br/> top companies
                </div>
                <div className='company-logos'>
                    <img alt="ABG" src={ABG}/>
                    <img alt="Cairn" src={Cairn}/>
                    <img alt="Merc" src={Merc}/>
                    <img alt="Ya" src={Ya}/>
                    <img alt="TuvSud" src={TuvSud}/>
                </div>
            </div>
            </div>
            <div className='media-side'>
                <img alt="ProupDash" src={ProupDash}/>
            </div>
            <span className='close-icon' onClick={() => setOpenPro(false)}>
                <CloseIcon/>    
            </span>   
         
           
       </div>
      </Modal>

    </>
  )
}

export default ProPopup
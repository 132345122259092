import React, { useState, useEffect } from 'react';
import { FormControlLabel, Radio, RadioGroup, Checkbox } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import feedback from '../assets/img/feedback.jpg';
import logo from '../assets/img/logo.svg';
import BackBtn from './FeedAndSurveyMngt/BackBtn';


const FeedBackPreview = () => {

  const location = useLocation();
  const hideBackButton = location.pathname === '/feedback';

  const { uuid } = useParams();

  const configData = useSelector((state) => state.configSlice);

  const userData = useSelector((state) => state.userSlice);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [questions, setQuestions] = useState([]);
  const [responses, setSelectedResponses] = useState({}) ;

  const initialValues = {
    name: '',
    mobile: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values)=>{
     console.log(responses);
    },
  });

  const handleOptionChange = (questionId, optionId ) => {
    setSelectedResponses( pre => ({ ...pre , [questionId] : optionId }));
  }
  
  
  const handleSubmit = async ( { resetForm }) => {
    console.log(responses)

    const payload ={
        responses,
        uuid,
        userId:userData.user.uuid
    }

    // try {
    //   const { data } = await axios.post(`${configData.config.UNO_URL}/api/feedback/submitFeedBack`,payload, {
    //     headers: {
    //         Authorization: `Bearer ${userData.token}`,
    //       }
    //   });
    //   console.log(data);
    //   resetForm();
    // } catch (error) {
    //   console.log(error.message);
    // } 


  };

  const handleClose = () => setShow(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    mobile: Yup.string().required('Mobile is required'),
  });


  

  const getFeedBackData = async () => {
    const { data } = await axios.get(`${configData.config.UNO_URL}/api/feedback/getFeedback/${uuid}`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
    setTitle(data.data.name);
    setDescription(data.description);
    setQuestions(data.data.data || []);
  };

  useEffect(() => {
    getFeedBackData();
  }, []);

  return (
    <>
      {!hideBackButton && <BackBtn text="Back" />}

      <section className="preview pb-3">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="border">
                <form onSubmit={formik.handleSubmit}>
                  <img
                    src={feedback}
                    className="img-fluid"
                    style={{ height: '110px', width: '100%', objectFit: 'cover' }}
                    alt=""
                  />
                  <div className="p-4 bg-light">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="m-0">{title}</h5>
                        <p className="m-0">{""}</p>
                      </div>
                    </div>
                    <div className="card mt-3">
                      <div className="card-body">
                        <p className="m-0">Email</p>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Record the email to be included with my response"
                        />
                      </div>
                    </div>
                    <div className="card mt-3">
                      <div className="card-body">
                        <p className="m-0">Name*</p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your name"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="text-danger">{formik.errors.name}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="card mt-3">
                      <div className="card-body">
                        <p className="m-0">Mobile*</p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your mobile"
                          name="mobile"
                          value={formik.values.mobile}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.mobile && formik.errors.mobile ? (
                          <div className="text-danger">{formik.errors.mobile}</div>
                        ) : null}
                      </div>
                    </div>
                    <h3 className="mt-4">Questions</h3>

                    {questions.map((item, index) => {
                      return (
                        <div className="card mt-3" key={`question_${index}`}>
                          <div className="card-body">
                            <p>{`Q${index + 1}. ${item.name}`}</p>
                            <RadioGroup
                              value={formik.values[`question_${index}`]}
                              onChange={formik.handleChange}
                              name={`question_${index}`}
                            >
                              {item.options.map((option, optionIndex) => (
                                <FormControlLabel
                                  key={`option_${index}_${optionIndex}`}
                                  value={option.name}
                                  control={<Radio />}
                                  label={option.name}
                                  onChange={() => handleOptionChange(item.id, option.id)}
                                />
                              ))}
                            </RadioGroup>
                          </div>
                        </div>
                      );
                    })}



                    <div className="row mt-4">
                      <div className="col-md-6">
                        <button type="submit" className="btn btn-danger">
                          {submitLoading ? (
                            <div className="d-flex align-items-center gap-1">
                              <Spinner size="sm" /> Submitting...
                            </div>
                          ) : (
                            'Submit'
                          )}
                        </button>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex justify-content-end">
                          <button type="button" onClick={formik.resetForm}>
                            Clear form
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="text-center py-5 pb-4">
                      <p className="my-2">This form created inside {'Simulanis'}</p>
                      <img src={logo} style={{ width: '130px', margin: '0 auto', display: 'block' }} alt="" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal size="sm" show={show} onHide={handleClose}>
        <Modal.Body className="p-0">
          <div className="bg-success text-center p-4 py-3 text-white rounded-top">
            {' '}
            <FileDownloadDoneIcon style={{ fontSize: '3rem' }} />{' '}
          </div>
          <div className="p-3 text-center">
            <h6>
              <strong>Thank You</strong>{' '}
            </h6>
            <p>For your feedback it's greatly appriciated</p>
            <button className="btn btn-light w-25" onClick={handleClose}>
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FeedBackPreview;

import React, { useEffect, useState } from 'react'
import {Tab , Tabs} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Skeleton from 'react-loading-skeleton';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import GroupCard from './Subscription/groupcard';
import Filter from './filter/Filter';
import SearchAll from './SearchAll';

const Subscriptions = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('');
  const [groupsList, setGroupsList] = useState([]);
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const params = useParams();
  const UUID = params?.uuid;

  const fetchGroupsList = async () => {
    try {
      const resData = await axios.get(`${configData.config.UNO_URL}/api/user/group/list/${UUID}`,{
        headers: {
        Authorization: `Bearer ${userData?.token}`
        }
      });
      setGroupsList([...resData?.data?.data])
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (UUID.length) {
      fetchGroupsList();
    }
  }, [UUID]);

  return (
    <div>
      <div className="col pagetitletext">
            {userData?.user?.group?.uuid !== UUID ? <span onClick={() => navigate(-1)} > <button className='back'> <KeyboardBackspaceOutlinedIcon /> </button> </span> : null}
           <span> Subscription Management </span>
      </div>

      <div className='row mt-2'>
        <div className='col-12 filterhere'>
          { !loading ?   
          <Tabs defaultActiveKey="group">
            <Tab eventKey="group" title="Groups">
                <GroupCard groupsList={groupsList} />
            </Tab>
          </Tabs>
            :
            <>
            <div className='row mt-0 userndgrouptabs'>

              <Skeleton width="5rem" height="1.5rem" className='mb-4 mt-2'/>

              {[...Array(5)].map((_, index) => (
            
              <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-6 mt-0 lefrightcolspacing" key={index}>
                    <div className="flip-card">
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <div className="groupcard">
                            <div className="groupbody">
                             
                              <div className="grouplogo outline-none">
                              <Skeleton width="25%" />
                              </div>
                              <div className="groupdetails w-100">
                                <p className="cn w-100">
                                <Skeleton width="50%" baseColor='#f2f3f7' highlightColor='#fff'/>
                                </p>
                              </div>
                                <Skeleton width="8rem" height="1.5rem" baseColor='#ca373c' highlightColor='#ffffff70'/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              ))}
            </div>
          </>
        }


        </div>
      </div>




    </div>
  )
}

export default Subscriptions

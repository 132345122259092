import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import jwtEncode from "jwt-encode";
import Skeleton from 'react-loading-skeleton';
import MetaBG from '../../assets/img/meta-bg.png'
import { AppArrowIcon, LeftDragIcon, RightDragIcon } from '../../assets/icons/iconshere';
import logo from '../../assets/img/sicon.png'
import { LockIcon } from '../../SvgIconsData';


const MyAppList = ({ search, setAppLength, tabKey }) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const myAppData = useSelector((state) => state.myAppSlice);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const _myAppData = myAppData?.appsList || [];
    setList(_myAppData);
    setLoading(false);
    setAppLength({[tabKey]: _myAppData.filter((e) => e.uuid !== configData.config.applicationId).length})
  }, [myAppData])

  const redirectApplication = (link) => {
    if (userData?.token && link) {
      const payload = { token: userData?.token };
      const token = jwtEncode(payload, "");
      window.open(`${link}?referrer=${token}`);
      // window.location.href = `${link}?referrer=${token}`;
    }
  }


  return (
    <>

      <div className='list-here'>
        {loading ? 
          <>
            {[...Array(5)].map((_, index) => (
               <div className={`application-card`} key={index} >
               <div className='application-logo-bg loading'>
                
                 <Skeleton width="100%" height="100%" baseColor='#2f3a7900' highlightColor='#fff'  />
                 <span className='app-logo' style={{ backgroundColor:"#ffffff00" }}>
                  <Skeleton width="3rem" height="3rem" baseColor='#fff' highlightColor='#4d4d4d20'  />
                 </span>
               </div>
               <div className='application-details'>
             <p className='application-name'>
             <Skeleton width="10vw" height="1rem" borderRadius={5} style={{ lineHeight:"normal" }} baseColor='#4d4d4d05' highlightColor='#4d4d4d15'  />
             </p>
             <p className='application-des'>
             <Skeleton width="15vw" height="1rem" count={2} borderRadius={5} style={{ lineHeight:"normal" }} baseColor='#4d4d4d05' highlightColor='#4d4d4d15'  />
             </p>
           </div>
           </div>
            ))}
          </>
          :
          <>
         
          {list.map((e) => {

            let linkExists = false;
            const ids = [11,12]    // for Beta Tag
            if(!ids.includes(e.id)){ linkExists = true; }
            if (e.uuid === configData.config.applicationId) return null;
            if (!e.name.toLowerCase()?.includes(search.toLowerCase())) { return null }
            return (
              <div className={`application-card`} key={e.id} onClick={() =>  e.channel?.origins.length > 0 ? redirectApplication(e.channel?.origins[0]?.url) : {}}>
              <div className={e.banner ? 'application-logo-bg ' : 'application-logo-bg default-bg ' }>
                <img className= 'default-img-centered'
                src={e.banner || logo} 
                alt=''
                onError={(e) => {
                  e.target.src = logo;
                  e.onerror = null;
                }}
                />
                <span className='app-logo'>
                  <img src={e?.icon || ""}
                    alt=""
                    onError={(e) => {
                      e.target.src = logo;
                      e.onerror = null;
                    }} />
                  
                  
                  {!linkExists &&
                  <span className='in-beta'>
                    Beta
                  </span>}

                </span>

                { e.channel?.origins.length > 0 &&
                 <span className='arrow-logo'>
                    <AppArrowIcon/>
                </span> 
                }
              
              </div>
              <div className='application-details'>
                <p className='application-name'>
                  {e.name}
                </p>
                <p className='application-des'>
                  {e.description}
                </p>
              </div>
            </div>
            )
          })}
          </>
        }

        
      </div>


    </>
  )
}

export default MyAppList

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { DateRange } from 'react-date-range';

const ValidityForm = ({ show, onHide, setSubscriptionDate, handleDateToogle }) => {

  
  const location = useLocation();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ]);

  const [endDate, setEndDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedTab, setSelectedTab] = useState('');
  const [customDate, setCustomDate] = useState("");

  const calculateTimeInterval = (selectedTab) => {
    const currentDate = new Date();

    const end = new Date();

    let years = 0;
    let months = 0;

    switch (selectedTab) {
      case 'one':
        months = 1;
        break;
      case 'three':
        months = 3;
        break;
      case 'nine':
        months = 9;
        break;
      case 'oneYear':
        years = 1;
        break;
      case 'threeYear':
        years = 3;
        break;
      case 'nineYear':
        years = 9;
        break;
      default:
        return {
          formatted: 'Unknown Interval',
          duration: null,
        };
    }

    end.setMonth(end.getMonth() + months);

    end.setFullYear(end.getFullYear() + years);

    return {
      endDateISO: end.toISOString(),
    };
  };

  const selectedInterval = calculateTimeInterval(selectedTab);


  function formatISODateToCustom(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const customDate = new Date(dateString).toLocaleDateString('en-US', options);
    return customDate;
  }

 

  useEffect(() => {
    const initialStartDate = new Date();
    const initialEndDate = new Date();
    setState([{ startDate: initialStartDate, endDate: initialEndDate, key: 'selection' }]);
    setEndDate(initialEndDate);
  }, [show]);

  const setSelectedDate = () => {
    const date = new Date(endDate);
    const isoString = date.toISOString();
    if (location.state && selectedTab.length === 0) {
      const data = location.state;
      setSelectedEndDate(data.validity.validTill);
       const customDate = formatISODateToCustom(data.validity.validTill);
       setCustomDate(customDate)
      } else {
        setSelectedEndDate(selectedInterval.endDateISO || isoString);
        const customDate = formatISODateToCustom(selectedInterval.endDateISO || isoString);
        setCustomDate(customDate)
    }
  };

  useEffect(() => {
    setSelectedDate();
  }, [selectedTab, endDate, location]);

  useEffect(()=>{
      setSubscriptionDate(selectedEndDate)
  },[selectedEndDate])

  const timeRangeChange = (key) => {
    setSelectedTab(key);
  };

  const changeSetSelectedTab = () => {
    setSelectedTab('');
  };

  const closeModal = () => {
    setSelectedTab("");
    setSelectedDate();
    onHide();
  };

  return (
    <>
      <Modal className="validitymodal" centered show={show}>
        
        <div className="validheader">
          <div className="cancel" onClick={closeModal}>
            Cancel
          </div>
          <div className="valid">Validity</div>
          <div
            className="done"
            onClick={() => {
              onHide();
              handleDateToogle();
            }}
          >
            Done
          </div>
        </div>
        <div className="validbody">
          <div className="col-md-8 validtitle">Simulanis Solutions</div>
          <div className="validtitle">Validity</div>
          <Tabs defaultActiveKey="month" onSelect={changeSetSelectedTab}>
            <Tab eventKey="month" title="Months">
              <div className="mt-3 validbodychild">
                <Tabs activeKey={selectedTab} onSelect={(key) => timeRangeChange(key)}>
                  <Tab eventKey="one" title="1 Month">
                    {''}
                  </Tab>
                  <Tab eventKey="three" title="3 Months">
                    {''}
                  </Tab>
                  <Tab eventKey="nine" title="9 Months">
                    {''}
                  </Tab>
                </Tabs>
              </div>
            </Tab>

            <Tab eventKey="year" title="Years">
              <div className="mt-3 validbodychild">
                <Tabs activeKey={selectedTab} onSelect={(key) => timeRangeChange(key)}>
                  <Tab eventKey="oneYear" title="1 Year">
                    {''}
                  </Tab>
                  <Tab eventKey="threeYear" title="3 Year">
                    {''}
                  </Tab>
                  <Tab eventKey="nineYear" title="9 Year">
                    {''}
                  </Tab>
                </Tabs>
              </div>
            </Tab>

            <Tab eventKey="custom" title="Custom">
              <div className="mt-3 validbodychild">
                <DateRange
                  editableDateInputs
                  onChange={(item) => {
                    setEndDate(item.selection.endDate);
                    setState([item.selection]);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                />
              </div>
            </Tab>
          </Tabs>
        </div>
        <div
          className="py-1 mb-1"
          style={{
            fontWeight: '600',
            color: '#333333',
            background: '#33333320',
            textAlign: 'center',
            borderRadius: '5px',
          }}
        >
          Subscription Valid Till {customDate}
        </div>
        
      </Modal>
    </>
  );
};

export default ValidityForm;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import logo from '../../../assets/img/sicon.png';
import { CheckedIcon } from '../../../SvgIconsData';

const ResourceAppList = ({ selectApp, applications, toggleApplication, search }) => {

  const configData = useSelector((state) => state.configSlice);

  return (
    <div className="row mt-3">
      <div className="row validity">
        {applications?.map((item, index) => {
          if (search && !item.name?.toLowerCase()?.includes(search?.toLowerCase())) {
            return null;
          }
          if (item.uuid === configData.config.applicationId) return null;

          return (
            <div className="col-md-4 py-3" key={index}>
              <div
                className={`Appbox ${selectApp.includes(item) ? 'selected' : ''}`}
                onClick={() => toggleApplication(item)}
              >
                <div className="appthumb">
                  <img
                    src={item.icon || ''}
                    alt=""
                    onError={(e) => {
                      e.target.src = logo;
                      e.target.onerror = null;
                    }}
                  />
                  <div className="forselected">
                    <CheckedIcon />
                  </div>
                </div>
                <div className="appdata">
                  <div className="apptitle">
                    <p>{item.name}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ResourceAppList;

import { LocalConvenienceStoreOutlined } from '@mui/icons-material';
import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const SatisfactionChart = ({ responses, item }) => {
const optionIds = item.options.map(e => e.id);
const questionId = item.id;
const optionCounts = {};
optionIds.forEach(optionId => {
    optionCounts[optionId.toString()] = 0;
});
responses.forEach(response => {
    const optionId = response.data[questionId.toString()];
    if (optionIds.includes(parseInt(optionId, 10))) {
        optionCounts[optionId] = (optionCounts[optionId] || 0) + 1;
    }
});
const optionNames = item?.options.map(option => option.name);

const optionResponseCounts = optionIds.map(optionId => optionCounts[optionId.toString()] || 0);

  const chartData = {
    series: [
      {
        name: 'Users',
        type: 'bar',
        data:optionResponseCounts || [],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: '35%',
          borderRadius: 3,
          borderWidth: 1,
        },
      },
      colors: ['#FF0000', '#0000FF', '#00FF00', '#000000'],
      stroke: {
        show: true,
        width: 1,
        colors: ['transparent'],
      },
      fill: {
        type: 'solid',
      },
      labels: optionNames || [],
      markers: {
        size: 0,
      },
      yaxis: [
        {
          title: {},
        },
      ],
      legend: {
        offsetY: 5,
        horizontalAlign: 'left',
      },
    },
  };

  return (
    <>
      <div id="chart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={145} />
      </div>
    </>
  );
};

export default SatisfactionChart;

import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';

const ReplyModal = ({ show, handleClose, response, setShow }) => {


  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = Yup.object().shape({
    description: Yup.string().required('Reply to feedback'),
  });

  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);

  const initialValues = {
    description: '',
  };

  const handleSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);
    const payload = {
      values,
      email: response.user.email,
      name: response.user.name
    };

    try {
      const { data } = await axios.post(
        `${configData.config.UNO_URL}/api/feedback/replyToResponse/${response.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setShow(false)
      resetForm();
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };
  const handleBlur = (e) => {
    const { name } = e.target;
    formik.setFieldTouched(name, true);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });
  
  const handleCloseModal = () => {
    handleClose(); // Close the modal
    formik.resetForm(); // Reset the form values
  };

  return (
    <>
      {/* <Modal centered show={show} onHide={handleClose} className="createfeedback"> */}
      <Modal centered show={show} onHide={handleCloseModal} className="createfeedback">
        <Modal.Header className="bg-danger p-2 px-3 text-white" closeButton>
          <Modal.Title style={{ fontSize: '1rem' }}>Reply to {response?.user?.name || "User Feedback"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 pt-3">
          <form onSubmit={formik.handleSubmit}>
            {/* <h5>{response.feedback.name} </h5> */}
            <h5>Title</h5>
            <div className="form-group position-relative my-3">
              <textarea
                type="text"
                className={`form-control ${formik.touched.description && formik.errors.description ? 'is-invalid' : ''
                  }`}
                name="description"
                placeholder='Description*'
                onChange={handleChange}
                onBlur={handleBlur}
                value={formik.values.description}
                rows={4}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="text-danger validatetextds">{formik.errors.description}</div>
              ) : null}
            </div>
            <div className="form-group mt-4">
              <button type="submit" className="btn btn-danger d-block w-100 py-2" disabled={isSubmitting}>
                {isSubmitting ? (
                  'Submitting...'
                ) : (
                  <>
                    {`Send `}
                    <NearMeOutlinedIcon />
                  </>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReplyModal;

import React, { useEffect, useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';


const initialSettings = {
    inherit: false,
    handDown: false,
    parent: { handDown: false }
}

const settingsHandler = {
    "user": ['inheritence'],
    "group": ['inheritence', 'handDown']
}



const SettingTeamManagement = ({ id, type }) => {

    const [checked, setChecked] = useState(false);
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [settings, setSettings] = useState(initialSettings);
    const visibleSettings = settingsHandler[type]
    const handleChange = () => {
        setChecked((prev) => !prev);
    };
    const fetchSettings = async () => {
        try {
            const { data } = await axios.get(`${configData.config.UNO_URL}/api/user/settings/${type}/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`,
                },
            });
            setSettings({ ...initialSettings, ...data.data })
        } catch (error) {
            console.log(error);
        }
    }
    const updateSettings = async (postData) => {
        try {
            const { data } = await axios.post(`${configData.config.UNO_URL}/api/user/settings/${type}/${id}`, {...postData} ,{
                headers: {
                    Authorization: `Bearer ${userData?.token}`,
                },
            });
            setSettings({ ...initialSettings, ...data.data })
        } catch (error) {
            console.log(error);
        }
    }
    const toggleSetting = (field , value) => {
        setSettings({...settings , [field] : value});
        const data = {[field] : value};
        updateSettings(data);
    }
    useEffect(() => {
        setSettings(initialSettings);
        fetchSettings();
    }, [id]);

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 38,
        height: 22,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 18,
          height: 18,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

    return (
        <>
            <div className="card mt-2">
                <div className="card-body">
                    <div className="settings">
                        <div className="row">
                            <div className="col-md-6">
                                <h6 className='mb-0'>{type === 'user' ? "User" : "Group"} Settings</h6>
                            </div>
                        </div>
                        {visibleSettings.includes('handDown') && <div className="row align-items-center">
                            <div className="col-4 col-md-9 col-lg-6">
                                <p className='mb-0'>Allow access to all applications and resources to all direct Sub-Groups / Users</p>
                            </div>
                            <div className="col-8 col-md-3 col-lg-6">
                                {/* <Switch checked={settings.handDown} onChange={()=> toggleSetting('handDown' , !settings.handDown)} color='uno' /> */}
                                <FormControlLabel
                                        className='me-0'
                                        name="active"
                                        control={<IOSSwitch sx={{ m: 1 }} checked={settings.handDown} onChange={()=> toggleSetting('handDown' , !settings.handDown)}/>}
                                />
                            </div>
                        </div>}
                        {visibleSettings.includes('inheritence') && <div className="row align-items-center">
                            <div className="col-4 col-md-9 col-lg-6">
                                <p className='mb-0'>Inherit all applications and resources from Parent Group</p>
                            </div>
                            <div className="col-8 col-md-3 col-lg-6">
                                <Tooltip arrow title={settings.parent.handDown ? "Access already enabled by the parent group" : ""}>
                                    <span>
                                        {/* <Switch disabled={settings.parent.handDown} checked={settings.parent.handDown || settings.inherit} onChange={()=> toggleSetting('inherit' , !settings.inherit)} color='uno' /> */}
                                        <FormControlLabel
                                        className='me-0'
                                        name="active"
                                        control={<IOSSwitch sx={{ m: 1 }} disabled={settings.parent.handDown} checked={settings.parent.handDown|| settings.inherit} onChange={()=> toggleSetting('inherit' , !settings.inherit)}/>}
                                    />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingTeamManagement

import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { useFormik } from 'formik';
import CryptoJS from 'crypto-js';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import jwtEncode from 'jwt-encode';
import { loginValidationSchema } from './validation';
import Iconify from '../../../components/iconify';
import { applicationId, SECRET_KEY } from '../../../env';

const Native = ({ handleSuccess, loading, setLoading, method }) => {
  const recaptchaRef = useRef(null);
  const configData = useSelector((state) => state.configSlice);
  const [showPassword, setShowPassword] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [verified, setVerified] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [failedAttempt, setFailedAttempt] = useState(null);
  const [error, setError] = useState(null);
  const persistedCaptchaShow = localStorage.getItem('show');

  useEffect(() => {
    if (persistedCaptchaShow) {
      setShowCaptcha(true);
    }
  }, []);

  // const checkCaptchaValidation = () => {
  //   if (showCaptcha && persistedCaptchaShow) {
  //     return verified;
  //   }
  //   return true;
  // };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      setError(null);
      setFailedAttempt(null);

      // if (checkCaptchaValidation()) {
        const bodyData = {
          email: values.email.trim(),
          password: CryptoJS.AES.encrypt(values.password, SECRET_KEY).toString(),
        };
        setLoading(true);

        try {
          const { data } = await axios.post(
            `${configData.config.UNO_URL}/api/auth/login/secure/${configData.config.applicationId}`,
            bodyData
          );
          const userData = {
            token: data.data.token,
            user: data.data.user,
          };
          localStorage.removeItem('show');
          setShowCaptcha(false);
          // setVerified(false);
          handleSuccess(userData);

        } catch (error) {
          // if (recaptchaRef.current) {
          //   recaptchaRef.current.reset();
          // }
          console.log(error);
          setError(error.response.data.message);
          if (error.response.data.show) {
            setFailedAttempt(error.response.data.attempts);
            setShowCaptcha(true);
            localStorage.setItem('show', true);
            // setVerified(false);
          }

        } finally {
          setLoading(false);
        }
      // } else {
      //   setError('Resolve captcha');
      // }
    },
  });

  const formatTime = (seconds) => {
    const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const secs = String(seconds % 60).padStart(2, '0');
    return `${hours}:${minutes}:${secs}`;
  };

  useEffect(() => {
    // if(secondsRemaining > 0){
    const intervalId = setInterval(() => {
      setSecondsRemaining((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    // }
    return () => clearInterval(intervalId);
  }, [secondsRemaining]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        formik.handleSubmit();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // const handleRecaptchaClick = () => {
  //   setVerified(true);
  // };

  return (
    <>
      <Stack spacing={3}>
        <div className="form-group">
          <p className="mb-1">{method.data?.useLDAP ?  "ADS ID*" : "Email*"}</p>
          <TextField
            name="email"
            fullWidth
            placeholder={method.data?.useLDAP ?  "ADS ID*" : "Email*"}
            size="sm"
            defaultValue={formik.values.email}
            onChange={(e) => formik.setFieldValue('email', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            autoComplete="off"
          />
        </div>

        <div className="d-flex justify-content-start pt-0 mt-0 px-1">
          <span className="login-error"> {formik.touched.email && formik.errors.email} </span>
        </div>

        <div className="form-group">
          <div className="d-flex justify-content-between">
            <p className="mb-1">Password*</p>
          </div>

          <TextField
            name="password"
            fullWidth
            placeholder="Password"
            size="sm"
            defaultValue={formik.values.password}
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => formik.setFieldValue('password', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
        </div>
      </Stack>

      {method.data?.useLDAP ? null:<div className="d-flex justify-content-between pt-0 mt-0 px-1">
        <span className="login-error"> {formik.touched.password && formik.errors.password} </span>
        <Link to="/forgot-password" className="forgotpassword">
          Forgot Password ?
        </Link>
      </div>}

      {loading ? (
        <>
          <button className="reset-page-btn mt-3" size="large" type="submit" color="secondary">
            <div className="loader-btn">
              <span className="bar" />
              <span className="bar" />
              <span className="bar" />
            </div>
          </button>
        </>
      ) : (
        <button
          onClick={!btnDisable ? formik.handleSubmit : null}
          className={!btnDisable ? 'reset-page-btn mt-3' : 'reset-page-btn mt-3 disableBtn'}
          size="large"
          color="secondary"
        >
          Log in {secondsRemaining > 0 ? ` again in ${formatTime(secondsRemaining)}` : null}
        </button>
      )}

      <span className="login-error">{error}</span>
      <span className="login-error">{failedAttempt}</span>

      {/* {showCaptcha && (
        <div className="mt-3">
          <ReCAPTCHA ref={recaptchaRef} sitekey={configData.config.captcha} onChange={handleRecaptchaClick} />
        </div>
      )} */}

    </>
  );
};

export default Native;

import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import happy from '../../../assets/img/emotions/happy.png'
import ok from '../../../assets/img/emotions/ok.png'
import sad from '../../../assets/img/emotions/sad.png'

const Progress = ({ responses, item }) => {

    const optionIds = item.options.map(e => e.id);
    const questionId = item.id;

    const optionCounts = {};
    optionIds.forEach(optionId => {
        optionCounts[optionId.toString()] = 0;
    });

    responses.forEach(response => {
        const optionId = response.data[questionId.toString()];
        if (optionIds.includes(parseInt(optionId, 10))) {
            optionCounts[optionId] = (optionCounts[optionId] || 0) + 1;
        }
    });

    const optionNames = item?.options.map(option => option.name);

    const optionResponseCounts = optionIds.map(optionId => optionCounts[optionId.toString()] || 0);

    return (
        <>
            {item.options.map((option, index) => (
                optionResponseCounts[index] > 0 && (
                    <>
                        <div className='d-flex align-items-center gap-1 mb-1'>
                            {option.name === 'Happy' &&
                                // <OverlayTrigger key="top" placement="top" overlay={<Tooltip id="tooltip-bottom">Happy</Tooltip>} >
                                <img src={happy} style={{ width: '27px' }} alt="happy" />
                                // </OverlayTrigger>
                            }
                            {option.name === 'Ok' &&
                                // <OverlayTrigger key="top" placement="top" overlay={<Tooltip id="tooltip-bottom">Ok</Tooltip>} >
                                <img src={ok} style={{ width: '27px' }} alt="ok" />
                                // </OverlayTrigger>
                            }
                            {option.name === 'Sad' &&
                                // <OverlayTrigger key="top" placement="top" overlay={<Tooltip id="tooltip-bottom">Sad</Tooltip>} >
                                <img src={sad} style={{ width: '27px' }} alt="sad" />
                                // </OverlayTrigger>
                            }
                            <span className='font-sm'>{`${option.name} (${optionResponseCounts[index]})`}</span>
                        </div>
                    </>
                    // <div key={option.id} className="progress mb-2">
                    //     <div className="progress-bar progress-bar-primary"
                    //         role="progressbar"
                    //         aria-valuenow={optionResponseCounts[index]}
                    //         aria-valuemin="0"
                    //         aria-valuemax={responses.length}
                    //         style={{ width: `${(optionResponseCounts[index] / responses.length) * 100}%` }}>
                    //         {`${option.name} (${optionResponseCounts[index]})`}
                    //     </div>
                    // </div>
                )
            ))}
        </>
    );
};

export default Progress;

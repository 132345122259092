import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { TwitterPicker } from 'react-color';
import { SearchesCIconData } from '../../SvgIconsData'
import { tagColors } from '../../utils/constant';

const CreateGroupType = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchRoles, setSearchRoles] = useState("");
    const [id, setId] = useState(null);
    const [color, setColor] = useState(tagColors[Math.floor(Math.random() * tagColors.length)]);
    const [searchTypes, setSearchTypes] = useState("");
    const [name, setName] = useState("");
    const [error, setError] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [errorDesc, setErrorDesc] = useState(null);
    const [description, setDescription] = useState("");
    const [allowed, setAllowed] = useState([]);
    const [allowedRoles, setAllowedRoles] = useState([]);
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [data, setData] = useState({
        allowed: [],
        allowedRoles: []
    });

    const noHtmlTags = /^(?!.*<.*?>).*$/;

    const checkForHtmlTags = (input) => {
       return noHtmlTags.test(input);
    };


    const fetchGroupType = async () => {
        try {
            const { data } = await axios.get(`${configData.config.UNO_URL}/api/role/grouptype/${userData.user.group.typeId}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            setData(data.data);
        } catch (error) {
            console.log(error);
        }
    };
    const setRoles = (i) => {
        let allowedRolesArray = [];
        allowedRolesArray = [...allowedRoles]
        if (allowedRolesArray.includes(i)) {
            allowedRolesArray = allowedRolesArray.filter(e => e !== i);
        } else {

            allowedRolesArray.push(i)
        };
        setAllowedRoles(allowedRolesArray)
    }
    const setGroupTypes = (i) => {
        let allowedGroupTypesArray = [];
        allowedGroupTypesArray = [...allowed]
        if (allowedGroupTypesArray.includes(i)) {
            allowedGroupTypesArray = allowedGroupTypesArray.filter(e => e !== i);
        } else {

            allowedGroupTypesArray.push(i)
        };
        setAllowed(allowedGroupTypesArray)
    }
    const submit = async () => {


        setErrorName(null);
        setErrorDesc(null);
        if (!checkForHtmlTags(name)) {
            setErrorName("Invalid name")
            setTimeout(() => { setErrorName(null) }, 3000)
            return;
          }

        if (!checkForHtmlTags(description)) {
            setErrorDesc("Invalid description.")
            setTimeout(() => { setErrorDesc(null) }, 3000)
            return;
        }

        try {
            if (name.length < 2) {
                setError("Enter a valid name");
                setTimeout(() => { setError(null) }, 2000)
            } else {
                const data = {
                    name,
                    description,
                    allowed,
                    allowedRoles,
                    color
                }
                if (id) {

                    await axios.post(`${configData.config.UNO_URL}/api/role/grouptype/update/${id}`, data, {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    });
                } else {

                    await axios.post(`${configData.config.UNO_URL}/api/role/grouptype/create/${userData.user.group.typeId}`, data, {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    });
                }
                navigate(-1);
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchGroupType();
    }, []);
    useEffect(() => {
        if (location.state && location.state.id) {
            const { id, allowed, description, name, allowedRoles, color } = location.state;
            setId(id);
            setAllowed(allowed.map((e) => e.id));
            setDescription(description);
            setName(name);
            if (color) setColor(color)
            setAllowedRoles(allowedRoles.map((e) => e.id));
        }
    }, [location.state])
    return (
        <>
            <section className='rolesaccess mt-2 ms-2'>
                <div className="row justify-content-between align-items-center">
                    <div className="col">
                        <div className='pagetitletext'>
                            Create Group Type
                        </div>
                    </div>
                    <div className="col text-end">
                        <button type='button' className='createbtn cancel' onClick={() => navigate(-1)} >Cancel</button>
                        <button type='button' className='createbtn ms-3' onClick={() => submit()}>{id ? "Update" : "Create"}</button>
                    </div>
                </div>

                <div className='row mt-4' >
                    <div className='col-md-4'>
                        <div className="searchfields form-group inputfiled" style={{ maxWidth: "100%" }}>
                            <p className='namehere'>Name</p>
                            <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Enter Group Type Name" className='form-control' />
                            {error ? <p style={{ color: 'red', fontSize: '12px' }}>{error}</p> : null}
                            {errorName ? <p style={{ color: 'red', fontSize: '12px' }}>{errorName}</p> : null}
                        </div>
                        <div className='row colorpicker' >
                            <TwitterPicker  color={color} colors={tagColors} onChangeComplete={(e) =>  setColor(e.hex)}  />
                            <span style={{backgroundColor : color , width : '45px' , height : '45px' , borderRadius : '50%'}} />
                        </div>
                        <div className='row rolemanage mt-4'>
                            <div className='col-12 appscroll'>
                                <h5>Allowed Predefined Roles</h5>
                                <div className="searchfields form-group mt-3">
                                    <span className='searchinconssd'> <SearchesCIconData /> </span>
                                    <input value={searchRoles} onChange={(e) => setSearchRoles(e.target.value)} type="text" placeholder='Search' className='form-control' />
                                </div>
                                <div className='permList withbarscroll'>
                                    {data.allowedRoles.map((item, index) => (searchRoles.length && !item.name.toLowerCase().includes(searchRoles.toLowerCase())) ? null : <div onClick={() => setRoles(item.id)} className='permitem' key={index}>
                                        <h3>{item.name}</h3>
                                        <div className="form-check permissionhere">

                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                <input checked={allowedRoles.includes(item.id)} className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                            </label>
                                        </div>
                                    </div>
                                    )}


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className="searchfields form-group inputfiled" style={{ maxWidth: "100%" }}>
                            <p className='namehere'>Description</p>
                            <input value={description} onChange={e => setDescription(e.target.value)} type="text" placeholder="Enter Description" className='form-control' />
                             {errorDesc ? <p style={{ color: 'red', fontSize: '12px' }}>{errorDesc}</p> : null}
                        </div>
                        <div className='row rolemanage mt-4'>
                            <div className='col-12 appscroll'>
                                <h5>Allowed Group Types</h5>
                                <div className="searchfields form-group mt-3">
                                    <span className='searchinconssd'> <SearchesCIconData /> </span>
                                    <input value={searchTypes} onChange={(e) => setSearchTypes(e.target.value)} type="text" placeholder='Search' className='form-control' />
                                </div>
                                <div className='permList withbarscroll'>
                                    {data.allowed.map((item, index) => (searchTypes.length && !item.name.toLowerCase().includes(searchTypes.toLowerCase())) ? null : <div key={index} onClick={() => setGroupTypes(item.id)} className='permitem'>
                                        <h3>{item.name}</h3>
                                        <div className="form-check permissionhere">

                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                <input checked={allowed.includes(item.id)} className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                            </label>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default CreateGroupType

import React, { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const TrueFalseQue = () => {
    const [rows, setRows] = useState([
        { id: 1, letter: 'a', answer: 'True', removable: false },
        { id: 2, letter: 'b', answer: 'False', removable: false },
    ]);
    const addRow = () => {
        if (rows.length < 4) {
            const nextLetter = String.fromCharCode(rows.length + 97);
            const newRow = { id: rows.length + 1, letter: nextLetter, answer: '' };
            setRows([...rows, newRow]);
        }
    };
    const removeRow = (id) => {
        if (rows.length > 2) {
            setRows(rows.filter(row => row.id !== id));
        }
    };


    return (
        <>
            {rows.map(row => (
                <div className="row mt-2" key={row.id}>
                    <div className="col-1">
                        <p className='text-end'>{row.letter}.</p>
                    </div>
                    <div className="col-11">
                        <div className='d-flex'>
                            <input type="text" value={row.answer} className='form-control' />
                            <div className='d-flex pmbtnf'>
                                <button onClick={addRow}><AddCircleOutlineIcon /></button>
                                <button onClick={() => removeRow(row.id)}><RemoveCircleOutlineIcon /></button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default TrueFalseQue;

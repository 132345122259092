import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { PERMISSION_DEFAULT } from '../../utils/constant';
import { CloseIcon } from '../../SvgIconsData';
import ProPopup from "../../layouts/ProPopup";

const emptyPermission = {
    displayName: "",
    name: "",
    description: null,
    parentId: 0
}

const Permissions = ({userPlan}) => {
    const [permission, setPermission] = useState(PERMISSION_DEFAULT);
    const [show, setShow] = useState(emptyPermission);
    const [error, setError] = useState(null);
    const [openPro, setOpenPro] = useState(false);
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [ loading, setLoading ] = useState(true)
    const [formLoading, setFormLoading] = useState(false)
    const fetchPermissions = async () => {
        const { data } = await axios.post(`${configData.config.UNO_URL}/api/role/permissions/children`, { name: permission.name }, {
            headers: {
                Authorization: `Bearer ${userData.token}`
            }
        });
        setPermission(data.data);
        setLoading(false)
    }
    useEffect(() => {
        fetchPermissions();
    }, []);

    const noHtmlTags = /^(?!.*<.*?>).*$/;

    const checkForHtmlTags = ({ displayName, name, description }) => {
        if (!noHtmlTags.test(displayName)) {
            setError("HTML tags are not allowed");
            setTimeout(() => setError(null), 3000)
          return false;
        }
      
        if (!noHtmlTags.test(name)) {
            setError("HTML tags are not allowed");
            setTimeout(() => setError(null), 3000)
          return false;
        }
      
        if (description && !noHtmlTags.test(description)) {
            setError("HTML tags are not allowed");
            setTimeout(() => setError(null), 3000)
          return false;
        }
      
        return true;
      };


    const submitPermission = async () => {
        try {


            const { displayName, name, description } = show;

            if (!checkForHtmlTags({ displayName, name, description })) {
                console.error("Invalid input: HTML tags are not allowed in one of the fields");
                return;
              }

            if (!(show.displayName.length > 2) || !show.name.length > 2) {
                setError("Invalid Name or ID");
                setTimeout(() => setError(null), 2000)
            } else {
                const { data } = await axios.post(`${configData.config.UNO_URL}/api/role/permission/create`, show, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`
                    }
                });
                fetchPermissions();
                handleClickAddEditChild(emptyPermission);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleClickAddEditChild =(data) =>{
        if(!userPlan.roles.create.permission){
            setOpenPro(true);
            return
        }
        setShow(data)
    }


    return (
        <>
            <div className='container-fluid'>
                <div className=' UserDataHere managemodal'>
                    <h3>{permission.displayName}</h3>
                    <span className='d-flex justify-content-between align-center'><h5>{permission.name}</h5><button onClick={() => handleClickAddEditChild({ ...emptyPermission, parentId: permission.id })} type='button' className='createbtn-sm cancel'>Add Child</button></span>
                    <p>{permission.description}</p>
                </div>
                { loading ?
              <>
              {[...Array(3)].map((_, index) => (
                <div className='row rolePerRow'>
                    <div className='col-3'>
                        <div className='UserDataHere managemodal'>
                            <h3> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </h3>
                            <p> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </p>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='UserDataHere managemodal'>
                            <h3> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </h3>
                            <p> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </p>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='UserDataHere managemodal'>
                            <h3> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </h3>
                            <p> <Skeleton width="50%" highlightColor='#ffffff' baseColor='#f3f3f3'/> </p>
                        </div>
                    </div>
                        </div> ))}
                    </>
                :
                <>
                   {
                    permission.children.map((level1, index) => <div className='row rolePerRow permissionRole' key={index}>
                        <div className='col-3'>
                            <div className='UserDataHere managemodal'>
                                <h3>{level1.displayName}<span className='cursor-pointer' style={{ fontSize: '14px', color: '#ca373c', fontStyle: 'italic' }} onClick={() => handleClickAddEditChild({ ...level1 })}> Edit </span></h3>
                                <span className='d-flex justify-content-between align-center'><h5>{level1.name}</h5><button onClick={() => handleClickAddEditChild({ ...emptyPermission, parentId: level1.id })} type='button' className='createbtn-sm cancel'>Add Child</button></span>
                                <p>{level1.description}</p>
                            </div>
                        </div>
                        <div className='col-9'>
                            {level1.children.map((level2, index) => <div className='row' key={index}>
                                <div className='col-4'>
                                    <div className='UserDataHere managemodal'>
                                        <h3>{level2.displayName}<span className='cursor-pointer' style={{ fontSize: '14px', color: '#ca373c', fontStyle: 'italic' }} onClick={() => handleClickAddEditChild({ ...level2 })}> Edit </span></h3>
                                        <span className='d-flex justify-content-between align-center'><h5>{level2.name}</h5><button onClick={() => handleClickAddEditChild({ ...emptyPermission, parentId: level2.id })} type='button' className='createbtn-sm cancel' >Add Child</button></span>
                                        <p>{level2.description}</p>
                                    </div>
                                </div>
                                <div className='col-8'>
                                    {level2?.children?.map((level3, index) => <div key={index} className='row'>
                                        <div className='col-6'>
                                            <div className='UserDataHere managemodal'>
                                                <h3>{level3.displayName}<span className='cursor-pointer' style={{ fontSize: '14px', color: '#ca373c', fontStyle: 'italic' }} onClick={() => handleClickAddEditChild({ ...level3 })}> Edit </span></h3>
                                                <span className='d-flex justify-content-between align-center'><h5>{level3.name}</h5><button onClick={() => handleClickAddEditChild({ ...emptyPermission, parentId: level3.id })} type='button' className='createbtn-sm cancel'>Add Child</button></span>
                                                <p>{level3.description}</p>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            {level3?.children?.map((level4, index) => <div key={index} className='row'>
                                                <div className='col-7'>
                                                    <div className='UserDataHere managemodal'>
                                                        <h3>{level4.displayName}<span className='cursor-pointer' style={{ fontSize: '14px', color: '#ca373c', fontStyle: 'italic' }} onClick={() => handleClickAddEditChild({ ...level4 })}> Edit </span></h3>
                                                        <span className='d-flex justify-content-between align-center'><h5>{level4.name}</h5><button onClick={() => handleClickAddEditChild({ ...emptyPermission, parentId: level4.id })} type='button' className='createbtn-sm cancel'>Add Child</button></span>
                                                        <p>{level4.description}</p>
                                                    </div>
                                                </div>
                                                <div className='col-5'>
                                                    {level4?.children?.map((level5, index) => <div key={index} className='row'>
                                                        <div className='col-3'>
                                                            <div className='UserDataHere managemodal'>
                                                                <h3>{level5.displayName}<span className='cursor-pointer' style={{ fontSize: '14px', color: '#ca373c', fontStyle: 'italic' }} onClick={() => handleClickAddEditChild({ ...level5 })}> Edit </span></h3>
                                                                <span className='d-flex justify-content-between align-center'><h5>{level5.name}</h5><button onClick={() => handleClickAddEditChild({ ...emptyPermission, parentId: level5.id })} type='button' className='createbtn-sm cancel'>Add Child</button></span>
                                                                <p>{level5.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>)}
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>)}
                                </div>
                            </div>)}
                        </div>
                    </div>
                    )
                }
                </>
                }
             
            </div>
            <Modal size="md" centered show={show.parentId} onHide={() => handleClickAddEditChild(emptyPermission)}>
                    <Modal.Header className="modalheader">
                        {show.id ? "Update" : "Create"} Permission
                        <span onClick={() => handleClickAddEditChild(emptyPermission)}>
                            <CloseIcon/>
                        </span>
                    </Modal.Header>
                <Modal.Body>
                    <div className="modalcont mt-2">
                        <div className='createform'>
                            <div className="form-group">
                                <TextField
                                    name="groupname"
                                    variant="outlined"
                                    fullWidth
                                    className='mt-2'
                                    value={show.displayName}
                                    onChange={(e) => handleClickAddEditChild({ ...show, displayName: e.target.value })}
                                    placeholder="Permission Name"
                                    size="small"
                                />
                                <TextField
                                    name="groupname"
                                    variant="outlined"
                                    fullWidth
                                    className='mt-2'
                                    value={show.name}
                                    disabled={show.id}
                                    onChange={(e) => handleClickAddEditChild({ ...show, name: e.target.value.toUpperCase() })}
                                    placeholder="Permission ID"
                                    size="small"
                                />
                                <TextField
                                    name="groupname"
                                    variant="outlined"
                                    fullWidth
                                    className='mt-2'
                                    value={show.description}
                                    onChange={(e) => handleClickAddEditChild({ ...show, description: e.target.value })}
                                    placeholder="Permission Description"
                                    size={'large'}
                                />
                                <p style={{ color: 'red' }}>{error}</p>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer-btn mt-4 mb-1'>
                            <button className='createbtn cancelbtn' onClick={() => handleClickAddEditChild(emptyPermission)}>
                                Cancel
                            </button>

                            <button className='createbtn ' onClick={() => submitPermission()}>
                                {show.id ? "Update" : "Create"}
                            </button>

                            </div>
                </Modal.Body>
            </Modal>

            <ProPopup openPro={openPro} setOpenPro={setOpenPro} />
        </>
    );
}

export default Permissions;

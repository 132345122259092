const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  user: {},
  token: null,
  plans: {}
}

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    saveUserData: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    saveUserPlan:(state,action)=>{
      state.plans= action.payload
    },
    removeUserData: (state) => {
      localStorage.clear();
      state.token = null;
      state.user = {};
    }
  },
});

export const { saveUserData, removeUserData, saveUserPlan } = userSlice.actions;
export default userSlice.reducer;
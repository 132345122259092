import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../assets/img/sicon.png';

const AllCard = ({ myapps, toggleItem, combinedData,search }) => {
  

  const handletoggle = (e) => {
    toggleItem(e);
  }

  const configData = useSelector((state) => state.configSlice);

  return (
    <>
    
    <div className="row mt-0">
     
      {myapps?.length > 0 ? (
        myapps?.map((e) => {
          if (search.length && !(e.name.toLowerCase().includes(search.toLowerCase()))) return null;
          if (e.uuid === configData.config.applicationId) return null;
          return (
            <div className={`col-md-4 py-3 `} key={e.id}>
              <div
                className={`Appbox ${combinedData?.includes(e) ? 'selected' : ''}`}
                onClick={() => handletoggle(e)}
              >
                <div className="appthumb" >
                  <img
                    src={e?.icon || ''}
                    alt=""
                    onError={(e) => {
                      e.target.src = logo;
                      e.onerror = null;
                    }}
                  />
                  <div className="forselected">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi-check-lg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>
                  </div>
                </div>

                <div className="appdata">
                  <div className="apptitle">
                    <p>{e.name}</p>
                    {/* {e?.access ? <span>Accessed</span> : <span className="try">Try Now</span>} */}
                  </div>

                  <div className="appdetails">{/* {e.info} */}</div>
                </div>

                {/* <div className="appside">
                  <i className="bi-chevron-right" />
                  <i className="bi-chevron-right" />
                </div> */}
              </div>
            </div>
          );
        })
      ) : (
        <p>No Data Found</p>
      )}
    </div>
    </>
  );
};

export default AllCard;
